import React, {  useState } from "react";
import LoginForm from './common/loginForm';
import RegisterForm from "./common/registerForm";
import './style/account.css';
import { motion } from 'framer-motion';
import { AccountContext } from "../utils/accountContext";

const backDropVariants = {
    expanded: {
        width: "150%",
        height: "200%",
        borderRadius: "20%",
        transform: "rotate(60deg)"
    },
    collapsed: {
        width: "150%",
        height: "500px",
        borderRadius: "100%",

    }
}

const expandingTransition = {
    type: "spring",
    duration: 2.3,
    stiffness: 30

}

const Accounts = (props) => {
    const [isExpanded, setExpanded] = useState(false);
    const [active, setActive] = useState("signin");

    const playExpandingAnimation = () => {
        setExpanded(true);
        setTimeout(() =>{
            setExpanded(false);
        }, expandingTransition.duration * 1000 - 1500);
    };

    const switchToSignup = () => {
        playExpandingAnimation();
        setTimeout(() => {
            setActive("signup");
        }, 400);
    }

    const switchToSignin = () => {
        playExpandingAnimation();
        setTimeout(() => {
            setActive("signin");
        }, 400);
    }

    const contextValue = {switchToSignin, switchToSignup};

    return (
        <AccountContext.Provider value={contextValue}>
            <div className="accountBox">
                <div className="topContainer">
                    
                    <motion.div transition={expandingTransition} 
                                initial={false} 
                                animate={isExpanded ? "expanded" : "collapsed"}
                                variants={backDropVariants} 
                                className="backDrop" />

                    {active === "signin" &&
                        <div className="headerContainer">
                            <h2>Welcome</h2>
                            <h2>Back</h2>
                            <h5>Please Sign In</h5>
                        </div>
                    }

                    {active === "signup" &&
                        <div className="headerContainer">
                            <h2>New</h2>
                            <h2>Users</h2>
                            <h5>Please Sign Up</h5>
                        </div>
                    }

                </div>

                <div className="innerContainer">
                    {active === "signin" && <LoginForm />}
                    {active === "signup" && <RegisterForm />}
                </div>
            </div>
        </AccountContext.Provider>
      );
}
 
export default Accounts;