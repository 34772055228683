import React, {useState, useRef} from 'react';
import { Modal, Button} from 'rsuite';
import { useAuth } from '../contexts/AuthContext';


function ForgotPassword () {
    const [show, setShow] = useState(false)

    const emailResetRef = useRef()
    const { resetPassword } = useAuth()
    const [error, setError] = useState("")
    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(false)
  
    // Function to open Modal
    const close = () => {
      setShow(false);
    }
    
    // Function to close Modal
    const open = () => {
      setShow(true);
    }

    async function handleSubmitReset(e) {
        e.preventDefault()
    
        try {
          setMessage("")
          setError("")
          setLoading(true)
          await resetPassword(emailResetRef.current.value)
          setMessage("Check your inbox for further instructions")
        } catch {
          setError("Failed to reset password")
          console.log("error")
        }
    
        setLoading(false)
      }

    return ( 
        <div className="passwordModal">

            <span onClick={open} className="muteLink"> Forgot Password?</span>

            {error ? <div style={{ textAlign: "center"}} className="container alert alert-danger">{error}</div> : null}
            {message && <div style={{ textAlign: "center"}} className="container alert alert-success">{message}</div> }

            <Modal show={show} size='xs' onHide={close}>
                    <Modal.Header>
                        <Modal.Title>Enter Your Email To Reset Password </Modal.Title>
                    </Modal.Header>

                    <form onSubmit={handleSubmitReset}>
                    
                        <input className="emailModal" ref={emailResetRef} name="email" type="email" placeholder="Email" />
                        
                        <Button disabled={loading} className="w-100" color="red" type="submit">
                            Reset Password
                        </Button>

                    </form>
           
            </Modal>
        </div>

        
    );
}
 
export default ForgotPassword;