import React, { useState, useEffect, useRef } from 'react';
import logoURL from './images/logo.jpeg';
import './style/topNav.css';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

function TopNavSmall() {
    const prevScrollY = useRef(0);
    const [goingUp, setGoingUp] = useState(true);
    const [navbarOpen, setNavbarOpen] = useState(false);

    const { currentUser, logout } = useAuth();
    const history = useHistory();


    useEffect(() => {
        const handleScroll = () => {
          const currentScrollY = window.scrollY;
          if (prevScrollY.current >= 90) {
            setGoingUp(false);
          }
          if (prevScrollY.current <90) {
            setGoingUp(true);
          }
    
          prevScrollY.current = currentScrollY;
        };
    
        window.addEventListener("scroll", handleScroll, { passive: true });
    
        return () => window.removeEventListener("scroll", handleScroll);
    }, [goingUp]);

    const handleToggle = () => {
        setNavbarOpen(!navbarOpen)
    }

    async function handleLogout() {    
        try {
          await logout()
          history.push("/accounts")
        } catch {
          console.log("Failed to logout")
        }
    }
    
    return (
        <div className={goingUp ? 'navbar navbar-light fixed-top static-top topNav' : 'navbar fixed-top static-top navbar-light topNav active'}>
            
   
            <Link to="/home" className="d-inline-block align-text-top">
                <img src={logoURL} 
                    width="60" height="40" to="/"  alt="not found">
                </img>
            </Link>

                <div className="dropstart topNavLink" style={{marginRight: "10px"}}>
                    <button   
                            type="button"
                            style={{backgroundColor: "transparent",border: "none"}}
                            className="btn btn-secondary btn-lg menuButton"
                            onClick={handleToggle}           
                    >
                        {navbarOpen ? "Close" : "Menu" }
                    </button>
                
                    <div className={`dropdown-menu  ${navbarOpen ? "show" : "dissapear"}`} >
                
                        {currentUser ? <NavLink className="dropdown-item" to="/cart">
                            Cart
                        </NavLink> : null}
                        
                            
                        <NavLink className="dropdown-item" to="/contact">
                            Contact
                        </NavLink>

                        {currentUser ? null : <NavLink className="dropdown-item" to="/accounts">
                            Account
                        </NavLink>}

                        <NavLink className="dropdown-item" to={{
                                                    pathname: `/catalog`,
                                                    state: {
                                                    genreIn: "0"
                                                    }
                                                }}>
                            Catalog
                        </NavLink>


                        {currentUser ? <div  className="dropdown-item" style={{cursor: "pointer"}} onClick={handleLogout}>
                            Logout
                        </div> : null}

                    </div>

                </div>
   
        </div>
    );
}

export default TopNavSmall;
