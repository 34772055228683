import React from 'react';

const TableHead = () => {
    return ( 
        <div className="row tableHead">
            <div className="col-xs-12 col-sm-2">
                              
            </div>

            <div className="col-xs-12 col-sm-4"> 
                <b><p>Summary</p></b>
            </div>
            
            <div className="col-xs-5 col-sm-2">
                <p className="h3-price">
                    <b>Stock #</b>
                </p>
            </div>

            <div className="col-xs-7 col-sm-2">
                <div >
                    <b>Quantity</b>
                </div>
            </div>

            <div className="col-xs-12 col-sm-2">               
                
            </div>
        </div>
     );
}
 
export default TableHead;