import React, { useContext, useState, useRef } from 'react';
import Joi from 'joi-browser';
import { AccountContext } from '../../utils/accountContext';
import { motion } from 'framer-motion';
import { useAuth } from '../../contexts/AuthContext';
import { useHistory } from 'react-router';

const initialFormData = Object.freeze({
    account: {email: "",
            company: "",
            phone:"",
            password: "",
            confirm:""},
    errors: {}
  });

const RegisterForm = () => {

    const [formData, updateFormData] = useState(initialFormData);

    const emailRef = useRef();
    const companyRef = useRef();
    const phoneRef = useRef();
    const passwordRef = useRef();
    const confirmRef = useRef();
    const { signup, addDetails} = useAuth();
    const [errorAuth, setErrorAuth] = useState("");
    const history = useHistory()

    const schema = {
        email : Joi.string().email().required().label('Email'),
        company: Joi.string().min(3).max(30).required(),
        phone: Joi.number().min(0).label("Phone Number"),
        password: Joi.string().min(3).max(15).required().label('Password'),
        confirm: Joi.any().valid(Joi.ref('password')).required().options({ language: { any: { allowOnly: 'must match password' } } }).label("Confirmation")
    };

    const handleChange = (e) => {
        const errors = formData.errors;
        const errorMessage = validateProperty(e.target);
        if(errorMessage) errors[e.target.name] = errorMessage;
        else delete errors[e.target.name];
        updateFormData(formData => ({
            account: {
                ...formData.account,
                [e.target.name]: e.target.value.trim()
            },errors
        }))
    };

    const validate = () =>{
        const result = Joi.validate(formData.account, schema, {abortEarly: false});
        if(!result.error) return null;

        const errors = {};
        for(let item of result.error.details){
            errors[item.path[0]] = item.message;
        }

        return errors;
    };

    const validateProperty = ({name, value}) => {
        const obj = { [name]: value};
        const schemaIn = { [name]: schema[name]};
        const {error} = Joi.validate(obj, schemaIn);
        return error ? error.details[0].message : null;
    }

    async function handleSubmit(e){
       e.preventDefault();
       e.stopPropagation();

        const errors = validate();
        updateFormData({...formData, errors : errors || {} });
        if (errors) return;
 
       try {
            setErrorAuth("")
            await signup(emailRef.current.value, passwordRef.current.value)
            await addDetails(companyRef.current.value, phoneRef.current.value )
            history.push("/")
        } catch {
            setErrorAuth("Failed To Create Account")
        }
    };

    const {switchToSignin} = useContext(AccountContext);



    return ( 
        <form onSubmit={handleSubmit} className="boxContainer">
            <div className="formContainer">
                {errorAuth && <div style={{ textAlign: "center"}} className="container alert alert-danger">{errorAuth}</div> }
                <input onChange={handleChange} ref={emailRef} name="email" type="email" placeholder="Email" />
                {formData.errors.email && <div className="alert alert-danger">{formData.errors.email}</div>}
                <input onChange={handleChange} ref={companyRef} name="company" type="text" placeholder="Company Name" />
                {formData.errors.company && <div className="alert alert-danger">{formData.errors.company}</div>}
                <input onChange={handleChange} ref={phoneRef} name="phone" type="text" placeholder="Phone Number" />
                {formData.errors.phone && <div className="alert alert-danger">{formData.errors.phone}</div>}
                <input onChange={handleChange} ref={passwordRef} name="password" type="password" placeholder="Password" />
                {formData.errors.password && <div className="alert alert-danger">{formData.errors.password}</div>}
                <input onChange={handleChange} ref={confirmRef} name="confirm" type="password" placeholder="Confirm Password" />
            </div>

            <motion.button
                disabled={validate()}
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 1.5 }}
                type="submit" className="btn btn-primary submitButton"
            >
                Sign Up
            </motion.button>
            

            <span className="muteLink" onClick={switchToSignin}>Already Have An Account? <span className="boldLink">Sign In</span></span>
        </form>
    );
}
 
export default RegisterForm;