import React from 'react';
import 'rsuite/dist/styles/rsuite-default.css';
import { Sidenav, Toggle, Dropdown, Icon, Nav } from 'rsuite';



class ListGroup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          expanded: true,

        };
        this.handleToggle = this.handleToggle.bind(this);
      }
      
      handleToggle() {
        this.setState({
          expanded: !this.state.expanded
        });
      }



    render() { 

        const { expanded } = this.state;
        const { genres, onItemSelect, mainCats } = this.props;
  
        return (        
            <div className="sideNav topSpace">
                <Toggle style={{'backgroundColor': 'black'}} onChange={this.handleToggle} checked={expanded} />
                <hr />
                <Sidenav
                    expanded={expanded}
                    defaultOpenKeys={[]}
                    onSelect={this.handleSelect}
                    appearance="subtle"             
                
                >
                    <Sidenav.Body>
                        <Nav>
                            {mainCats.map(categories => (
                                categories.count === 1 ? (
                                    <React.Fragment key={categories._id}>
                                    {genres.map( genre => (
                                        categories._id === genre.cat._id ? 
                                        <Nav.Item  onClick={() => onItemSelect(genre)} 
                                                    eventKey={genre.cat._id} 
                                                    key={genre.cat._id} 
                                                    icon={<Icon icon={categories.symbol} />}
                                        >
                                            {categories.name}
                                        </Nav.Item> : null
                                    ))}
                                    </React.Fragment>
                                ) : (
                                    <Dropdown   placement="rightStart" 
                                                eventKey={categories._id} 
                                                key={categories._id}
                                                title={categories.name} 
                                                icon={<Icon icon={categories.symbol} />}
                                    >
                                      
                                        {genres.map( genre => (
                                            categories._id === genre.cat._id ?
                                            <Dropdown.Item onClick={() => onItemSelect(genre)} key={genre.name} eventKey={genre._id}>{genre.name}</Dropdown.Item>  : null
                                        ))}

                                    </Dropdown>
                                )
                         
            
                                
                            ))}

                        </Nav>
                    </Sidenav.Body>
                </Sidenav>
            </div> 
        )
    }
}
 
export default ListGroup;