import React from 'react';
import SiteFeatures from './common/siteFeatures'
import './style/contact.css';
import { toast } from 'react-toastify';


class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.formRef = React.createRef(null);
    }

    sendEmail = (e) => {
        e.preventDefault();
        console.log(this.formRef.current.name)

        
        
        window.emailjs.sendForm('service_wipxsdr', 'template_la8m8cq', this.formRef.current, 'user_SLeZrkET0EUvR9Ix1PkDQ')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
        
        this.props.history.push('/home');
    };

    cartMessage = () => {
        toast.success('Your Message Has Been Sent!', {
            position: "top-center",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }


    render() { 
        return (
            <div className="contactContain">
                <div className="row">
                    <div className="col-xs-12 col-sm-6 contactLeft">
                        
                        <div className="contactTitle">
                            <h2>Send Us A Message</h2>
                            <h4>We Are Always Ready To Help!</h4>
                        </div>

                        <div className="contactForm">
                            <form id="contactForm" ref={this.formRef} onSubmit={this.sendEmail}>
                                <input type="text" name="name" className="formControl" placeholder="Your Name" required/>
                                <br/>
                                <input type="email" name="email" className="formControl" placeholder="Your Email" required/>
                                <br/>
                                <textarea name="message" className="formControl" placeholder="Message" rows="6"/>
                                <br />
                                <button onClick={this.cartMessage} type="submit" className="formControl submit">SEND MESSAGE</button>
                            </form>
                        </div>
                        
                    </div>
                    <div className="col-xs-12 col-sm-6 contactRight">
                       
                        <div className="contactTitleRight">
                            <h2>Contact Us</h2>
                            <h4>For Immediate Service:</h4>
                        </div>
                        
                        <div className="contactRightBox">
                            
                            <div className="row">
                                <div className="col-xs-12 col-sm-4">
                                    <i className="fa fa-map-marker"></i>
                                </div>
                                <div className="col-xs-12 col-sm-8">
                                    <p className="boldPart">Address</p>
                                    <p> 1865 Iowa Avenue Ste B. </p>
                                    <p>Riverside, California 92507</p>
                                </div>
                            </div>
                            
                            <div className="row btmRow">
                                <div className="col-xs-4 col-sm-4">
                                    <i className="fa fa-phone"></i>
                                </div>
                                <div className="col-xs-8 col-sm-8">
                                    <p className="boldPart">Phone</p>
                                    <p> (951) 781-3739</p>
                                </div>
                            </div>
                            
                            <div className="row btmRow">
                                <div className="col-xs-4 col-sm-4">
                                    <i className="fa fa-envelope"></i>
                                </div>
                                <div className="col-xs-8 col-sm-8">
                                    <p className="boldPart">Email</p>
                                    <p>Sales@LeftCoastIndustrial.com </p>
                                </div>
                            </div>
                            
                            <div className="row btmRow">
                                <div className="col-xs-4 col-sm-4">
                                    <i className="fa fa-globe"></i>
                                </div>
                                <div className="col-xs-8 col-sm-8">
                                    <p className="boldPart">Website</p>
                                    <p>LeftCoastIndustrial.com</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                
                <SiteFeatures />
            </div>
     
        );
    }
}
 
export default Contact;