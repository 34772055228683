import React from 'react';
import 'rsuite/dist/styles/rsuite-default.css';
import { Sidenav, Toggle, Dropdown, Icon, Nav } from 'rsuite';
import { getGenres} from '../../services/genres';
import { getCats } from '../../services/itemCat';
import { Link } from 'react-router-dom';



class SideNav extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          expanded: false,       
            genres: [],
            cats: [],
            currentPage: 1,
            pageSize: 9
        };
        this.handleToggle = this.handleToggle.bind(this);
    }
       
    componentDidMount() {
        const genres = [{name: 'All Tools',     cat: { _id: "0", } }, ...getGenres()]
        const cats = [{_id: "0", name: "All Tools", symbol: "universal-access", count: 1 }, ...getCats()]
        this.setState({ genres, cats })
    };
     
    handleToggle() {
        this.setState({
          expanded: !this.state.expanded
        });
    }
    
    render() { 

        const { expanded , genres, cats} = this.state;
       
  
        return (        
            <div className="sideNav topSpace">
                <Toggle style={{'backgroundColor': 'black'}} onChange={this.handleToggle} checked={expanded} />
                <hr />
                <Sidenav
                    expanded={expanded}
                    defaultOpenKeys={[]}
                    onSelect={this.handleSelect}
                    appearance="subtle"    
                       
                
                >
                    <Sidenav.Body>
                        <Nav>
                            {cats.map(categories => (
                                categories.count === 1 ? (
                                    <React.Fragment key={categories._id}>
                                    {genres.map( genre => (
                                        categories._id === genre.cat._id ? 
                                  
                                            <Nav.Item  
                                                    eventKey={genre.cat._id} 
                                                    key={genre.cat._id} 
                                                    icon={<Icon icon={categories.symbol} />}
                                                    componentClass={Link} 
                                                    to={{
                                                        pathname: `/catalog`,
                                                        state: {
                                                          genreIn: genre._id
                                                        }
                                                    }}>
                                                {categories.name}
                                            </Nav.Item>
                                    
                                        
                                         : null
                                    ))}
                                    </React.Fragment>
                                ) : (
                                    <Dropdown   placement="rightStart" 
                                                eventKey={categories._id} 
                                                key={categories._id}
                                                title={categories.name} 
                                                icon={<Icon icon={categories.symbol} />}
                                    >
                                      
                                        {genres.map( genre => (
                                            categories._id === genre.cat._id ?
                                       
                                                <Dropdown.Item  key={genre.name} 
                                                                eventKey={genre._id}
                                                                componentClass={Link} 
                                                                to={{
                                                                    pathname: `/catalog`,
                                                                    state: {
                                                                      genreIn: genre._id
                                                                    }
                                                }}>
                                                    {genre.name}                             
                                                </Dropdown.Item>  
                                    
                                            
                                            : null
                                        ))}

                                    </Dropdown>
                                )
                         
            
                                
                            ))}

                        </Nav>
                    </Sidenav.Body>
                </Sidenav>
            </div> 
        )
    }
}
 
export default SideNav;
