import React from 'react';
import Banner from './banner';
import DisplayBox from './displayBox';
import { getItem } from '../../services/itemService';



const NewArrival = () => {
    const item1 = getItem("3908");
    const item2 = getItem("3442");
    const item3 = getItem("1002");

    return (  
        <>
            <div className="row" style={{paddingBottom: "10px"}}>
                <div className="col-xs-12">
                <Banner title="New Arrivals" />
                </div>
            </div>

            <div className="row catMain">
                <DisplayBox item={item1} />  
                <DisplayBox item={item2} />  
                <DisplayBox item={item3} />  
            </div>            
        </>
        
    );
}
 
export default NewArrival;