import React from 'react';
import {Link} from "react-router-dom"
import '../style/catalog.css';

const DisplayBox = props => {

    const { item } = props;

    return ( 
        <div className="col-xs-12 col-sm-4 catCard">        
                <Link to={`/catalog/${item._id}`} className="viewLine"><img alt="imagers" src={item.display}/></Link>
       
                <p className="cardTitle">{item.title}</p>
                <p className="cardStock">{item.availability}</p>
                <p className="cardStock">Stock # {item._id}</p>
                <Link to={`/catalog/${item._id}`} className="viewLine"><button className="button-71">View</button></Link>
  

        </div>
     );
}
 
export default DisplayBox;