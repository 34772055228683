import React from 'react';
import ListGroup from './common/listGroup';
import ItemsTable2 from './itemsTable2';
import SiteFeatures from './common/siteFeatures';
import { getItems } from '../services/itemService';
import { getGenres, getGenre} from '../services/genres';
import { getCats } from '../services/itemCat';
import { paginate } from '../utils/paginate';
import { Pagination } from 'rsuite'

class Catalog extends React.Component {

    state = {
        items: [],
        genres: [],
        cats: [],
        currentPage: 1,
        pageSize: 9
    }

    componentDidMount() {
        window.scrollTo(0, 0)

        const genres = [{name: 'All Tools',     cat: { _id: "0", } }, ...getGenres()];
        const cats = [{_id: "0", name: "All Tools", symbol: "universal-access", count: 1 }, ...getCats()];
        this.setState({items: getItems(), genres, cats });
        
        if(this.props.location.state.genreIn != null){
            const genreNav = getGenre(this.props.location.state.genreIn);
            if(genreNav >= "100" && genreNav != null){
                this.handleGenreSelect(genreNav);
            }
        }
    };

    handlePageChange = page => (
        this.setState({currentPage: page})
    );

    handleGenreSelect = genre => {
        this.setState({selectedGenre: genre, currentPage: 1});
    };

    render() { 
        const filtered = this.state.selectedGenre && this.state.selectedGenre._id ? this.state.items.filter(m => m.genre._id === this.state.selectedGenre._id) : this.state.items;

        const itemsOut = paginate(filtered, this.state.currentPage, this.state.pageSize);

        const pagesCount = Math.ceil(filtered.length / this.state.pageSize);

        return (
            <React.Fragment>
                <div className="topContain">    
                    <ListGroup  genres={this.state.genres} 
                                onItemSelect={this.handleGenreSelect} 
                                selectedItem={this.state.selectedGenre}
                                mainCats={this.state.cats}
                    />
                
                    <div className="rightPanel">
                        <ItemsTable2  itemsOut={itemsOut}/>

                        {pagesCount <= 1 ? null : 
                        <>
                        <div className="pageRow bigPage">
                            <Pagination
                                prev
                                last
                                next
                                first
                                size="lg"
                                pages={pagesCount}
                                maxButtons={5}
                                ellipsis
                                activePage={this.state.currentPage}
                                onSelect={this.handlePageChange}
                            />
                        </div>

                        <div className="pageRow smallPage">
                            <Pagination
                                prev
                                next
                                size="sm"
                                pages={pagesCount}
                                maxButtons={3}
                                ellipsis
                                activePage={this.state.currentPage}
                                onSelect={this.handlePageChange}
                            />
                        </div>
                        </>
                        }

                    </div> 
                </div>

                <SiteFeatures />
            </React.Fragment>

            
        );
    }
}
 
export default Catalog;