import React from 'react';

class NotFound extends React.Component {
    render() { 
        return (
            <div className="container topSpace">
            <h1>Sorry, Page Not Found</h1>
            <h1>Sorry, Page Not Found</h1>
            <h1>Sorry, Page Not Found</h1>
            <h1>Sorry, Page Not Found</h1>
            </div>
        );
    }
}
 
export default NotFound;