import React from 'react';
import Banner from './banner';
import DisplayBox from './displayBox';
import { getItem } from '../../services/itemService';



const OnSale = () => {
    const item1 = getItem("1797");
    const item2 = getItem("3406");
    const item3 = getItem("1752");

    return (  
        <>
            <div className="row" style={{paddingBottom: "10px"}}>
                <div className="col-xs-12">
                <Banner title="On Sale" />
                </div>
            </div>
            
            <div className="row catMain">
                <DisplayBox item={item1} />  
                <DisplayBox item={item2} />  
                <DisplayBox item={item3} />  
            </div>            
        </>
        
    );
}
 
export default OnSale;