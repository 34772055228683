import React from 'react';
import CMT from "../images/cmt.jpg";
import Bosch from "../images/bosch.png";
import Husqy from "../images/husqy.png";

const Featured = () => {
    return ( 
    
        <div className="featured">
            
            <div className="col">
                <img alt="cmt" src={CMT}></img>
            </div>
    
            <div className="col">
                <img alt="bosch" src={Bosch}></img>
            </div>
                
            <div className="col">
                <img alt="husqy" src={Husqy}></img>
            </div>    
    
        </div>
    
    );
}
 
export default Featured;