import React from 'react';
import './style/cart.css';
import { auth, database } from '../firebase'
import CartFill from './common/cartFill';
import TableHead from './common/tableHead';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

class Cart extends React.Component {
    constructor (props) {
        super(props)
        this.state = {
            databaseIn: database,
            isConnected: false,
            userId: '',
            cart: [],
            loaded: false,
            textAreaValue: "",
            companyName: "",
            phoneNum: "",
            messageData: [],
            keyData: []
        }   
        this.handleChange = this.handleChange.bind(this);
        this.formRef = React.createRef(null);
    }

    componentDidMount(){
        if(!this.state.loaded) {
            this.state.databaseIn.ref(`/${auth.currentUser.uid}/cart`).on('value', querySnapShot => {
                let data = querySnapShot.val() ? querySnapShot.val() : {};
                const cartIn2 = Object.entries(data);
                const cartIn = Object.values(data);
                const keysOut = Object.keys(data);
                this.setState({
                    isConnected: true,
                    userId: auth.currentUser.uid,
                    cart: cartIn2,
                    loaded:true,
                    companyName: auth.currentUser.displayName,
                    phoneNum: auth.currentUser.photoURL,
                    messageData: cartIn,
                    keyData: keysOut
                })   
            });
        }
    }

    componentWillUnmount() {
        this.setState = (state,callback)=>{
            return;
        };
        
      }

    handleChange(event) {
        this.setState({ textAreaValue: event.target.value });
    }

    handleDelete =(key)=> {
        const newCart = this.state.cart.filter(c => c[0] !== key)
        this.setState({ cart: newCart });
        database.ref(`/${auth.currentUser.uid}/cart/${key}`).remove()
    }; 

    cartSubmit = (event) =>{
        event.preventDefault();
        event.stopPropagation();
        


        const messageOut = { 
            name: this.state.companyName, 
            email: auth.currentUser.email,
            phoneNumber: this.state.phoneNum,
            cartOut: this.state.messageData,
            notes: this.state.textAreaValue,
        }
        
        
        window.emailjs.send('service_wipxsdr', 'template_yv5iv8d', messageOut, 'user_SLeZrkET0EUvR9Ix1PkDQ')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
         
       
        this.state.keyData.map(item => (
            this.handleDelete(item)
        ))

        this.props.history.push('/home');
         
         
    }

    cartMessage = () => {
        toast.success('Your Cart Has Been Submitted!', {
            position: "top-center",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

 
    render() { 
        return (
            <div className="topContain">
                {this.state.isConnected ? 
  
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xs-12">
                                <div className="page-header">
                                    <h1>Your Shopping Cart <small>Get Your Quote Today!</small></h1>
                                </div>
                                
                                <div className="row mainCart">
                                    <div className="col-xs-12 col-sm-1">

                                    </div>
                                    <div className="col-xs-12 col-sm-7">
                                        <TableHead />
                                        <CartFill carted={this.state.cart} onDelete={this.handleDelete} />
                                    </div>

                                    <div className="col-xs-12 col-sm-4">
                                        <div className="cartBox">
                                            <form onSubmit={this.cartSubmit} className="cartSubmit">
                                                <h3>Quote Summary <hr className="rounded"></hr></h3>
                                                <p><b>Your Email:</b> <span>{auth.currentUser.email}</span></p>
                                                <p><b>Item Total:</b> <span>{this.state.cart.length}</span></p>
                                                <p><b>Additional Notes:</b></p>
                                                <textarea value={this.state.textAreaValue} onChange={this.handleChange} cols="30" rows="5"></textarea>
                                                <button onClick={this.cartMessage} type="submit" className="button-71">Submit Quote</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                : null}

            </div> 
        );
    }
}
 
export default Cart;