
import React from "react";
import { Carousel } from 'rsuite';
import image1 from '../images/intro_image_1.jpg';
import image2 from '../images/intro_image_2.jpg';
import image3 from '../images/intro_image_3.jpg';
import imageLogo from '../images/intro_image_logo.jpeg';


const Carouseled = () => {

    const mainStyle = {
        width: '80%',
        maxWidth: '700px',
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'inline-block',
        justifyContent: 'center',
        textAlign: 'center'  
      };

    return ( 
      <Carousel shape="bar" autoplay style={mainStyle} className="custom-slider carouseled">
      <img alt="imageLogo" src={imageLogo} />
      <img alt="image1" src={image1} />
      <img alt="image2" src={image2}/>
      <img alt="image3" src={image3} />
    </Carousel>
    );
}
 
export default Carouseled;