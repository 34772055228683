import React from 'react';
import { uniqueId } from 'lodash';

const CartFill = (props) => {
    const {carted} = props;

    return ( 
        <div>
            {carted.map(([key,value])=>{
                return (
                   <div key={uniqueId()} className="row align-items-center cart-row">
                       <div className="col-xs-12 col-sm-2">
                           <img src={`../` + value.item.display} className="img-fluid" alt="" />                    
                        </div>

                        <div className="col-xs-12 col-sm-4 cartTitle"> 
                            <h5>{value.item.title}</h5>
                            <p>{value.notes}</p>
                        </div>
                        
                        <div className="col-xs-5 col-sm-2 labelsCart">
                            <p>
                                <span className="cartLabel">Stock #:</span> {value.item._id}
                            </p>
                        </div>

                        <div className="col-xs-7 col-sm-2 labelsCart">
                            <p >
                                <span className="cartLabel">Quantity:</span> {value.quant}
                            </p>
                        </div>

                        <div className="col-xs-12 col-sm-2">               
                            <button onClick={() => props.onDelete(key)} className="product-remove">
                                        <i className="fa fa-trash"></i>                       
                            </button>
                        </div>
                   </div>
                );
            })}
            
        </div>
     );
}
 
export default CartFill;