import * as genresAPI from "./genres";

const items = [
  {
    _id: "1533",
    title: "Black and Gold Bits Up To 1/2 Inch",
    genre: { _id: "100", name: "Metal Bits" },
    availability: "In Stock",
    condition: "New",
    brand: "Minnesota Twist",
    display: "./display/bgsmall.png",
    images: ["../itemPage/bgsmall_1.jpg","../itemPage/bgsmall_2.jpg"],
    description:["These drill bits are made of a special hi-molybdenum tooling steel. They are heat treated at 2185 degrees and then nitro-carburized at 950 degrees to be measurably harder than standard high speed steel.", " These bits feature a 135 degrees split point for faster penetration and accurate starting without a center punch. The body and clearance of this bit are gold surface treated for maximum lubricity. They are designed for drilling in harder steels such as stainless but they are less brittle than a cobalt bit, so you will not have the problem of chipping."],
    sizing: "1/16 to 1/2 inch by the 64th"
  },
  {
    _id: "3648",
    title: "Black and Gold Bits Up To Inch",
    genre: { _id: "100", name: "Metal Bits" },
    availability: "In Stock",
    condition: "New",
    brand: "Minnesota Twist",
    display: "./display/bgbig.png",
    images: ["../itemPage/bgbig_1.jpg","../itemPage/bgbig_2.jpg"],
    description:["These drill bits are made of a special hi-molybdenum tooling steel. They are heat treated at 2185 degrees and then nitro-carburized at 950 degrees to be measurably harder than standard high speed steel.", " These bits feature a 135 degrees split point for faster penetration and accurate starting without a center punch. The body and clearance of this bit are gold surface treated for maximum lubricity. They are designed for drilling in harder steels such as stainless but they are less brittle than a cobalt bit, so you will not have the problem of chipping.", "All of these drill bits have the 1/2 inch shank with the three flats so they are compatiable with any style of drill."],
    sizing: "1/2 to 1 inch by the 16th"
  },
  {
    _id: "1510",
    title: "Left Handed Drill Bits",
    genre: { _id: "100", name: "Metal Bits" },
    availability: "In Stock",
    condition: "New",
    brand: "Minnesota Twist",
    display: "./display/lhbits.png",
    images: ["../itemPage/lhbits_1.jpg","../itemPage/lhbits_2.jpg","../itemPage/lhbits_3.jpg"],
    description:["This is a set of left handed (reverse spiral) drill bits that go hand in hand with either extractor set we sell. They feature a long lasting titanium nitride coating and are made of M-2 high speed steel. They have a 135 split point tip for faster penetration. You operate by putting your drill in reverse and drilling out the broken bolt. Many times this is all the force that is necessary to extract a broken bolt. If not, you have already drilled the hole for your screw extractor."],
    sizing: "1/16 to 1/2 inch by the 32nd"
  },
  {
    _id: "1573",
    title: "Cobalt Step Drill Index",
    genre: { _id: "100", name: "Metal Bits" },
    availability: "In Stock",
    condition: "New",
    brand: "Patriot",
    display: "./display/stepcob.png",
    images: ["../itemPage/stepcob_1.jpeg","../itemPage/stepcob_2.jpg"],
    description: ["This is a 5 piece set of step drills are ideal for electrical contractors, sheet metal workers, auto mechanics, and general contractors. Also known as Unibits, they are ideal for drilling steel, copper, brass, aluminum and other thin metals.", "Specifications:"],
    specs: ["Black & Gold finish for professional look", "High speed steel with cobalt coating for long life and durability", "Two Flutes, which automatically deburrs as you drill", "Tri-Flat shank for sure-grip", "Foam lined aluminum carrying case"],
    sizing: "5 bits that cover all sizing from 3/16 to 1 3/8 inches"
  },
  {
    _id: "1616",
    title: "Titanium Step Drill Index",
    genre: { _id: "100", name: "Metal Bits" },
    availability: "In Stock",
    condition: "New",
    brand: "NTI",
    display: "./display/steptit.png",
    images: ["../itemPage/steptit_1.jpg","../itemPage/steptit_2.jpg"],
    description: ["These step drills are ideal for electrical contractors, sheet metal workers, auto mechanics, and general contractors. Also known as Unibits, they are ideal for drilling steel, copper, brass, aluminum and other thin metals.", "Specifications:"],
    specs: ["Manufactured with heat treated HSS; coated with titanium nitride", "Two flute design for faster, smoother cutting and no de-burring", "Multiple sizes with just one bit", "Self Starting - no center punch needed"],
    sizing: "Varies By Stock"
  },
  {
    _id: "3909",
    title: "Annular Cutting Bits",
    genre: { _id: "100", name: "Metal Bits" },
    availability: "In Stock",
    condition: "New",
    brand: "NTI",
    display: "./display/annular.png",
    images: ["../itemPage/annular_1.jpg","../itemPage/annular_2.jpg"],
    description: ["This is a set of the most popular sizes of annular cutters to be used with a magnetic drill. Using an annular cutter instead of a standard twist drill means you can produce a larger diameter hole more efficiently, quicker, with less energy, and with faster penetration and less thrust.", "Tests show that a solid drill will take almost 4 minutes to drill a 1-1/4” hole in 3/4” plate, whereas an annular cutter will do the same job in just over 30 seconds. Annular cutters are designed similar to core bits so rather than drilling out the entire hole as with a solid bit, you remove only the periphery of the hole, ejecting a slug at the end of the cut. These bits have standard 3/4” shanks and have a 1” cutting depth.", "Specifications:"],
    specs: ["Made of Premium M2 Cobalt HSS For Superior Hardness and Performance", "Reformulated Aluminum Additive Withstands High Temperature and Provides Durability", "Thin Wall Design Enables Faster Cutting due to less Material to be Removed", "Dual Alternating Tooth Geometry Prduces More Aggressive Cutting and Ejects Chips More Quickly", "3⁄4” Weldon Style Shank Fits All Major Brands of Magnetic Drill Presses"],
    sizing: "9/16”, 5/8”, 11/16”, 13/16”, 15/16”, 1-1/16” and 1” pilot pin."
  },
  {
    _id: "1524",
    title: "Bosch Daredevil Spade Bits",
    genre: { _id: "101", name: "Wood Bits" },
    availability: "In Stock",
    condition: "New",
    brand: "Bosch",
    display: "./display/daredevil.png",
    images: ["../itemPage/boschDaredevil_1.jpg","../itemPage/boschDaredevil_3.jpg","../itemPage/boschDaredevil_2.jpg"],
    description: ["This is one of Bosch Power Tools innovative items for wood drilling. They are a high-end spade bit set that all your customers will appreciate. This bit will drill 3 times faster than a standard spade bit and stay sharp much longer.", "At the heart of the design is a screw tip that actually pulls the bit through the wood. There are two scribing points on each cutting surface to give a neat, clean cut. Each bit has a 1⁄4” hex shank to keep it from slipping in the drill chuck and they are warrantied against manufactures defects."],
    sizing: " 1/4”, 3/8”, 1/2”, 9/16”, 5/8”, 11/16”, 3/4”, 7/8”, & 1”"
  },
  {
    _id: "1509",
    title: "Spade Bits",
    genre: { _id: "101", name: "Wood Bits" },
    availability: "In Stock",
    condition: "New",
    brand: "ProTool",
    display: "./display/spade.png",
    images: ["../itemPage/spade_1.jpg","../itemPage/spade_2.jpg"],
    description: ["These are professional quality spade or paddle bits. They are also known as speed bits and are used by woodworkers, electricians, and plumbers, etc. Each bit is individually blister packed, but also sold in dozens.", "Specifications:"],
    specs: ["Cold Forged Bodies", "Fully Heat Treated", "Machine Finish Cutting Groove", "Rust Protection", "Precision Ground Hex Shank"],
    sizing: "Varies By Stock"
  },
  {
    _id: "1525",
    title: "Auger Bits",
    genre: { _id: "101", name: "Wood Bits" },
    availability: "In Stock",
    condition: "New",
    brand: "ProTool",
    display: "./display/auger.png",
    images: ["../itemPage/auger_1.jpg","../itemPage/auger_2.jpg"],
    description: ["These are professional quality auger bits used by woodworkers, electricians, and plumbers when nails will not be encountered. They feature a single spur cutting edge for a smooth, splinter free cut. Each bit is fully heat treated to extend the life of the bit.", "Specifications:"],
    specs: ["7/16” Hex Shanks", "Machine Ground Hollows", "Deep Throat", "Single Twist", "Single and Side Spur Cutter", "Self Feeding Medium Screw Point"],
    sizing: "Varies By Stock"
  },
  {
    _id: "3462",
    title: "Bosch Nail Killer Auger Bits",
    genre: { _id: "101", name: "Wood Bits" },
    availability: "In Stock",
    condition: "New",
    brand: "Bosch",
    display: "./display/nailkiller.png",
    images: ["../itemPage/boschNailkiller_1.jpg","../itemPage/boschNailkiller_2.jpg","../itemPage/boschNailkiller_3.jpg"],
    description: ["These are professional quality auger bits used by woodworkers, electricians, and plumbers when drilling through nail embedded material. They feature a head that is specially designed for busting through nails. The self-feeding screw, cutting lips and shank are induction heat treated to give a tool that is less brittle and will last longer. These bits are back tapered for easier extraction and their special head design makes them a snap to resharpen.", "Specifications:"],
    specs: ["7/16” Hex Shanks", "Machine on True Center", "Deep Land for Strong, Flexible Shaft", "Single Twist", "Induction Heat Treated", "Self Feeding Screw Point"],
    sizing: "Varies By Stock"
  },
  {
    _id: "3621",
    title: "Bellhanger Bits",
    genre: { _id: "101", name: "Wood Bits" },
    availability: "In Stock",
    condition: "New",
    brand: "ProTool",
    display: "./display/bellhanger.png",
    images: ["../itemPage/bellhanger_1.jpg"],
    description: ["These bits are made of high carbon steel and are used for small wire installations in offices, homes, garages, etc. The bits feature a small hole in the flute that is designed for pulling wires through holes in walls. This bit produces a smooth, fast, clean hole in wood, plaster, metal lath and most every other material encountered in drilling through walls. Special fluting assures quick chip removal with minimal clogging and binding. The extra long flute section also facilitates deeper boring."],
    sizing: "1/4” to 1/2” in up to 3 foot length"
  },
  {
    _id: "1511",
    title: "Forstner Bits",
    genre: { _id: "101", name: "Wood Bits" },
    availability: "In Stock",
    condition: "New",
    brand: "ProTool",
    display: "./display/forstner.png",
    images: ["../itemPage/forstner_1.jpg"],
    description: ["This 7-piece Forstner Bit set drills exceptionally smooth, flat bottom holes in all types of wood. The bits are heat-treated steel and extremely durable. They are used to bore side grain, end grain, angles, and even through knots without deflection.", "All bits over 1/2” have a cut down shank so they can be ran in a 1/2” drill. The smallest bit is 2 3⁄4” long and the longest is 3 1⁄2”. The set comes in a wood box for storage and bits carry a 90-day warranty against manufactures defects."],
    sizing: "1/4”, 3/8”, 1/2”, 5/8”, 3/4”, 7/8” and 1”"
  },
  {
    _id: "1620",
    title: "Hammer Drill Bits Jacobs Chuck",
    genre: { _id: "102", name: "Concrete Bits" },
    availability: "In Stock",
    condition: "New",
    brand: "ProTool",
    display: "./display/conbit.png",
    images: ["../itemPage/conbit_1.jpg", "../itemPage/conbit_2.jpg"],
    description: ["These are professional quality carbide tipped masonry bits. They are a “slow” spiral, and are used in 3/8” and 1/2” hammer drills with keyed chucks. They can be used for drilling in concrete, brick, masonry, tile, plaster, cinder block, etc. The carbide tip makes them usable in both hammer drilling and straight drilling. The bits are designed to give a fast, clear hole at minimal cost. They are excellent quality and designed for the professional. They are bulk packed. You must give us the exact description including length on all these bits!"],
    sizing: "3/16” to 1” Diameter in 4” to 18” Lengths"
  },
  {
    _id: "3515",
    title: "Hammer Drill Bits SDS Plus",
    genre: { _id: "102", name: "Concrete Bits" },
    availability: "In Stock",
    condition: "New",
    brand: "ProTool",
    display: "./display/sdsplus.png",
    images: ["../itemPage/sdsplus_1.jpg", "../itemPage/sdsplus_2.jpg"],
    description: ["This is a true percussion bit, not just a standard masonry bit. These bits are designed for “SDS+” chuck system drills and give a slip proof grip. They have a black anodized finish and of course carbide tip. They can be used in all SDS PLUS hammer drills. You must give us the exact description including length on all these bits!"],
    sizing: "3/16” to 1” Diameter in 4” to 18” Lengths"
  },
  {
    _id: "3525",
    title: "Hammer Drill Bits SDS Max",
    genre: { _id: "102", name: "Concrete Bits" },
    availability: "In Stock",
    condition: "New",
    brand: "ProTool",
    display: "./display/sdsmax.png",
    images: ["../itemPage/sdsmax_1.jpg", "../itemPage/sdsmax_2.jpg"],
    description: ["Made in USA. These are heavy-duty industrial masonry drill bits used in the larger drive rotary hammer drills. These bits are designed for “SDS-MAX” chuck system drills and give a slip proof grip.","Attn Customer: We require that you write the model # of your drill in the notes section. If the model # is not on the order we will not process it."],
    sizing: "3/16” to 1 1/2” Diameter in 4” to 18” Lengths"
  },
  {
    _id: "3555",
    title: "Hammer Drill Bits Spline",
    genre: { _id: "102", name: "Concrete Bits" },
    availability: "In Stock",
    condition: "New",
    brand: "ProTool",
    display: "./display/spline.png",
    images: ["../itemPage/spline_1.jpg", "../itemPage/spline_2.jpg"],
    description: ["Made in USA. These are heavy-duty industrial masonry drill bits used in the larger spline drive rotary hammer drills. These bits are designed for “SPLINE” chuck system drills and give a slip proof grip.","Attn Customer: We require that you write the model # of your drill in the notes section. If the model # is not on the order we will not process it."],
    sizing: "3/16” to 1 1/2” Diameter in 4” to 18” Lengths"
  },
  {
    _id: "3602",
    title: "Percussion Core Bits",
    genre: { _id: "102", name: "Concrete Bits" },
    availability: "In Stock",
    condition: "New",
    brand: "ProTool",
    display: "./display/percussion.png",
    images: ["../itemPage/percussion_1.jpg"],
    description: ["Made in USA. These are heavy-duty industrial quality percussion core bits. They are used with the larger size rotary hammer drills. The overall length of core itself is 6” and they have a drilling depth of 4”. Overall length of core bit and shank is 15”. A starter bit is included with each core. ","Attn Customer: We require that you write the model # of your drill in the notes section. If the model # is not on the order we will not process it."],
    sizing: "1” to 5” Diameter"
  },
  {
    _id: "3627",
    title: "Bosch Glass And Tile Bits",
    genre: { _id: "102", name: "Concrete Bits" },
    availability: "In Stock",
    condition: "New",
    brand: "Bosch",
    display: "./display/glassbit.png",
    images: ["../itemPage/glassbit_1.jpg", "../itemPage/glassbit_2.jpg"],
    description: ["Manufactured by Bosch Power Tools. These glass and tile bits are some of the very finest on the market. They are used in kitchen and bathroom installations, and designed for drilling relief holes in glass or ceramic tile, including drilling and fastening mirrors and setting screw anchors in tile.They feature a precision tungsten carbide tip for exact starts with no walking." , "The high-quality carbide tip with reinforced head prevents bit cracking and the innovative geometry allows bit to drill faster. These bits are made for rotary drilling only, not hammer drilling. Tile applications include ceramic tile only, not porcelain tile or natural stone."],
    sizing: "1/8” to 1/4” Diameter Available"
  },
  {
    _id: "3442",
    title: "CMT Teflon Coated Circular Blades",
    genre: { _id: "103", name: "Saw Blades" },
    availability: "In Stock",
    condition: "New",
    brand: "CMT",
    display: "./display/cmtTeflon.png",
    images: ["../itemPage/cmtTeflon_1.jpg", "../itemPage/cmtTeflon_2.jpg"],
    description: ["This is one of the most sought after blades on the market for high end saw blades. You will recognize the bright orange color and the amazing performance this blade is capable of. They are specifically designed for fast cutting and framing, and are rated low noise because of the extra thin kerf and tooth design.", "Specifications:"],
    specs: ["Designed for framing/decking & general construction. It gives faster cuts thanks to the special teeth geometry.", "Designed for cutting lumber, plywood and OBS sheets. Can be used on portable and cordless circular saws.", "0.069 kerf thickness, 0.043 plate thickness, 20° hook angle, 10° ATB+Shear grind.", "Thin kerf reduces material waste, shear angle produces smooth cuts and improves cutting speed.", "Special Carbide prevents material failure, and the Non-Stick Orange Shield protects against corrosion."],
    sizing: "7 1/4” with a 5/8” Diamond Knockout to fit Worm Drive Saws as well"
  },
  {
    _id: "3448",
    title: "CMT Carbide Circular Blades",
    genre: { _id: "103", name: "Saw Blades" },
    availability: "In Stock",
    condition: "New",
    brand: "CMT",
    display: "./display/cmtcarb.png",
    images: ["../itemPage/cmtcarb_1.jpg", "../itemPage/cmtcarb_2.jpg"],
    description: ["This carbide tipped blade is designed for the price conscious contractor or remodeler. The thin kerf design delivers solid performance at a very economical price. They are ideal for any construction project involving the cutting of wood and wood composite materials. They feature a heavy duty tempered steel plate to ensure longer life and accurate cuts. The heat expansion slots prevnt warping as heat builds up, and the remarkably thin- kerf design reduces material waste and demand on the saw, while delivering reliable, top- quality performance."],
    sizing: "6 1/2” to 12” Diameter with a 5/8” Diamond Knockout to fit Worm Drive Saws as well"
  },
  {
    _id: "3406",
    title: "Bosch Bi-Metal Reciprocating Saw Blades",
    genre: { _id: "103", name: "Saw Blades" },
    availability: "In Stock",
    condition: "New",
    brand: "Bosch",
    display: "./display/boschrecip.png",
    images: ["../itemPage/boschRecip_1.jpg", "../itemPage/boschRecip_2.jpg"],
    description: ["Made by Bosch. These are some of the finest blades on the market for nail embedded wood. The 5/8 tooth configuration make for very aggressive, fast cutting. They compare with any major name brands such as Lennox or Milwaukee. They are designed for heavy-duty use in cutting soft or hardwood, and nail embedded woods. They are .050” thick and feature the Matrix 3 teeth that have 8% cobalt in them for extra long life and durability!!!", "They are a bi-metal material, which means the teeth are a tempered HSS and are welded to a flexible alloy steel back. This makes the blade flexible and long lasting, which is an unbeatable combination. The sloped design allows for contour cutting. They have a universal 1/2 inch shank so they will fit almost all reciprocating saws on the market."],
    sizing: "6” to 12” Lengths in 6, 18 or 24 TPI Patterns"
  },
  {
    _id: "9110",
    title: "Lennox Reciprocating Saw Blades",
    genre: { _id: "103", name: "Saw Blades" },
    availability: "In Stock",
    condition: "New",
    brand: "Lennox",
    display: "./display/lennox.png",
    images: ["../itemPage/lennox_1.jpg", "../itemPage/lennox_2.jpg"],
    description: ["Manufactured by Lennox. This is the blade with the brand name that everyone has heard of. Lenox blades set the standard for reciprocating blades years ago, and is considered by many to be the finest blade on the market. When you buy in multiples of 50 you get a slightly lower price since we don’t have to repackage them. "],
    sizing: "6” to 12” Lengths in 6, 18 or 24 TPI Patterns"
  },
  {
    _id: "3395",
    title: "Carbide Grit Reciprocating Saw Blades",
    genre: { _id: "103", name: "Saw Blades" },
    availability: "In Stock",
    condition: "New",
    brand: "Master Cut",
    display: "./display/carbgrit.png",
    images: ["../itemPage/carbgrit_1.jpg"],
    description: ["This is a specialty reciprocating blade designed for cutting cast iron, plaster, cement, composites, masonry, ceramics and fiber reinforced plastics etc.","They feature tungsten carbide grit (not teeth) on the cutting edge of the blade. They are used a lot for cutting stucco and plaster since they won’t catch the k-lathe wire inside the wall."],
    sizing: "6” or 8” Lengths"
  },
  {
    _id: "3430",
    title: "Metal Cutting Carbide Blades",
    genre: { _id: "103", name: "Saw Blades" },
    availability: "In Stock",
    condition: "New",
    brand: "Lifetime",
    display: "./display/metalcarb.png",
    images: ["../itemPage/metalcarb_1.jpg"],
    description: ["This unique carbide-tipped blade is designed for cutting cold rolled mild steel. It features a diamond knockout arbor so it will fit most handheld circular saws, cordless saws, and low RPM dry cut saws. They are much quieter and cleaner that an abrasive blade that is traditionally used in these applications. ","These blades are capable of making cuts in up to 1⁄4” steel 30 times faster than an abrasive chop saw, and last up to 100 times longer than an abrasive wheel. They will cut 26-gauge sheet metal true and flat, without any burrs, or up to 2” threaded rod and put a nut right on with no deburring. There is no heat build-up, so you can handle cut material right away, and there is no dust like you get with an abrasive saw. ","Customer Note: Material that is being cut must be clamped down, not hand held to avoid damage to blade."],
    sizing: "6 1/2”, 7 1/4” or 8” Diameter"
  },
  {
    _id: "1080",
    title: "Bi-Metal Jigsaw Blades",
    genre: { _id: "103", name: "Saw Blades" },
    availability: "In Stock",
    condition: "New",
    brand: "Bosch",
    display: "./display/jigsaw.png",
    images: ["../itemPage/jigsaw_1.jpg"],
    description: ["Made in USA. These are professional quality bimetal jigsaw blades. The 24-Tpi blades are designed for cutting up to 18 gauge metals, and the 6-Tpi blades are used for woodcutting. The bimetal design offers extra long life and durability. They have a universal shank and will fit most jigsaws on the market "],
    sizing: "3 1/2” Length in 6, 18 or 24 TPI Patterns"
  },
  {
    _id: "1094",
    title: "Portable Bandsaw Blades",
    genre: { _id: "103", name: "Saw Blades" },
    availability: "In Stock",
    condition: "New",
    brand: "MK Morse",
    display: "./display/bandsaw.png",
    images: ["../itemPage/bandsaw_1.jpg", "../itemPage/bandsaw_2.jpg"],
    description: ["Made in USA by M.K.Morse. This is a top quality bi-metal blade that contains an 8% cobalt material on its cutting edge. It is a general-purpose blade for cutting things like pipe, angle iron, “I” beams, etc (they are a very popular item with plumbing contractors). They are 1⁄2” tall, .020” thick, and are 44-7/8” in length. They are available in 14, 18 and 24 Tpi depending on your needs. "],
    sizing: "27 3/16”, 28 13/16”, 32 7/8”, 35 3/8”, or 44 7/8” Length in 14, 18 or 24 TPI Patterns"
  },
  {
    _id: "3469",
    title: "Bi-Metal Oscillating Tool Blades",
    genre: { _id: "103", name: "Saw Blades" },
    availability: "In Stock",
    condition: "New",
    brand: "CMT",
    display: "./display/oscbim.png",
    images: ["../itemPage/oscbim_1.jpg"],
    description: ["Manufactured by CMT. It features a construction quality blade that is made of a bimetal material with 8% cobalt teeth. They are designed for rapid cuts in wood, plasterboard, plastic, sheet metal, copper and aluminum pipes and profiles. Applications include but are not limited to flush cutting a nail, cutting copper pipes and aluminum profiles, plunge cutting in plasterboard, etc. They are designed to be used with most brands of multi-tools, but will not work on the StarLoc® system that Bosch and Fein have started using."],
    sizing: "1 1/3” Width"
  },
  {
    _id: "3471",
    title: "HCS Oscillating Tool Blades",
    genre: { _id: "103", name: "Saw Blades" },
    availability: "In Stock",
    condition: "New",
    brand: "Oshlun",
    display: "./display/oschcs.png",
    images: ["../itemPage/oschcs_1.jpg"],
    description: ["The HCS (high carbon steel) blades are designed for plunge and flush cuts in non-metallic materials, including softwood, hardwood, particle board, plywood, fiberglass, and nearly all plastics including Plexiglass."],
    sizing: "1 1/3” or 2 1/3” Width"
  },
  {
    _id: "1925",
    title: "Wrap Around Safety Glasses",
    genre: { _id: "104", name: "Safety Items" },
    availability: "In Stock",
    condition: "New",
    brand: "ProTec",
    display: "./display/wrapGlass.png",
    images: ["../itemPage/wrapGlass_1.jpg"],
    description: ["They feature a polycarbonate lens with a pliable frame for comfort. They are available with either clear (indoor), tinted (outdoor), indoor/outdoor (mirrored) or amber lenses. They are designed to provide protection from light to moderate impact hazards. They meet and exceed the current ANSI Z87.1 standards (this specification number is stamped on the temple piece of each pair of glasses to prove they comply if an OSHA inspector shows up on the job sight)."],
    sizing: "One Size Fits All"
  },
  {
    _id: "1930",
    title: "First Aid Kit - Metal Box",
    genre: { _id: "104", name: "Safety Items" },
    availability: "In Stock",
    condition: "New",
    brand: "White Cross",
    display: "./display/25man.png",
    images: ["../itemPage/25man_1.jpg","../itemPage/25man_2.jpg"],
    description: ["Assembled in USA with both imported and domestic products. This is an industrial first aid kit that comes in a metal case (11” x 8” x 3”). The case features mounting brackets so it can be mounted in the office, at the job site, or on equipment. This kit satisfies the ANSI 2308.1-2003 and the federal OSHA standard 1910.151b for jobsite use. This has been the standard in the construction industry for years."],
    sizing: "Available in 25 or 50 Man"
  },
  {
    _id: "1952",
    title: "Trooper Safety Glasses",
    genre: { _id: "104", name: "Safety Items" },
    availability: "In Stock",
    condition: "New",
    brand: "iNox",
    display: "./display/trooper.png",
    images: ["../itemPage/trooper_1.jpg","../itemPage/trooper_2.jpg"],
    description: ["Made popular by some of your favorite bike builders, these glasses feature a specially coated polycarbonate foam padded lenses to provide maximum protection & scratch resistance. A wide temple makes them comfortable and stylish. They also feature soft nonslip rubber temple tips for added comfort & security. They will filter 99% of harmful uv rays. They are ANSI Z87.1-2010 certified and meet military MIL-PRF-31013 CLAUSE 3.5.1.1 for high velocity impact protection."],
    sizing: "Available in Clear, Tinted, or Mirrored"
  },
  {
    _id: "1933",
    title: "Hard Hat w/ Suspension",
    genre: { _id: "104", name: "Safety Items" },
    availability: "In Stock",
    condition: "New",
    brand: "ProTec",
    display: "./display/hardhat.png",
    images: ["../itemPage/hardhat_1.jpg"],
    description: ["This is a certified model hard hat that meets ANZI Z89.1-1986 regulations. It is injection molded and is resistant to penetration by impact, electricity and chemicals. It features a four point molded plastic ratcheting suspension system with a built in cushioned nape strap. It features a full brim design which many contractors like. Weighs 13 oz."],
    sizing: "Available in White or Carbon Fiber *extra charge*"
  },
  {
    _id: "1975",
    title: "Universal Safety Harness",
    genre: { _id: "104", name: "Safety Items" },
    availability: "In Stock",
    condition: "New",
    brand: "ProTec",
    display: "./display/harness.png",
    images: ["../itemPage/harness_1.jpg"],
    description: ["Made in USA. This product is a full body safety harness used in the construction and industrial fields for fall protection. It includes a lanyard keeper and work belt loops plus an attached 6’ shock-absorbing lanyard. It features a single back D-ring with mating buckle leg closures (universal size).","The harness is made of a polyester web material that has a minimum tensile rating of 6,000 lbs. The snap hook is SAE 4130 ASTM A505 high alloy steel that is plated for corrosion resistance. The hook is rated with a minimum tensile strength of 5,000 lbs. It features stress indicators to allow user to know if a fall has taken place (harnesses are to be replaced every time there is a fall). The harness weighs 2.3 lbs and is rated at 425 lbs and the lanyard is rated at 310 lbs."],
    sizing: "One Size Fits Most"
  },
  {
    _id: "1944",
    title: "PVA Cooling Towel",
    genre: { _id: "104", name: "Safety Items" },
    availability: "In Stock",
    condition: "New",
    brand: "Ice Age",
    display: "./display/cooling.png",
    images: ["../itemPage/cooling_1.jpg"],
    description: ["This “Ice Age” cooling towel or wrap will help you beat the heat whether on a jobsite, at the gym, or just mowing your lawn. They are great for around your customers neck or as a headband, while the towel can be used to wipe down the entire face or used underneath a hardhat for ultimate cooling. It increases efficiency by keeping user cool.", "Specifications:"],
    specs: ["Cool water activated", "PVA coolness that lasts up to 4 hours with each activation", "Safe and reusable", "Washing machine safe"],
    sizing: "4” x 13” Strips or 13” x 30” Towels Available"
  },
  {
    _id: "1969",
    title: "Gel Knee Pad",
    genre: { _id: "104", name: "Safety Items" },
    availability: "In Stock",
    condition: "New",
    brand: "NTI",
    display: "./display/kneepad.png",
    images: ["../itemPage/kneepad_1.jpg"],
    description: ["These are excellant quality professional knee pads for the most demanding customers. They are definitely one of the best choices for ultimate protection in knee wear.", "Specifications:"],
    specs: ["Heavy duty Oxford construction", "EVA core provides maximum comfort and support", "Adjustable elastic strap with Velcro closure fits all sizes"],
    sizing: "One Size Fits All"
  },
  {
    _id: "1942",
    title: "Foam Ear Plugs",
    genre: { _id: "104", name: "Safety Items" },
    availability: "In Stock",
    condition: "New",
    brand: "ProTec",
    display: "./display/ear.png",
    images: ["../itemPage/ear_1.jpg","../itemPage/ear_2.jpg"],
    description: ["These are disposable foam earplugs you squeeze, put in your ear, and retract. Each pair is individually packaged and they are available in either a package of 200 pieces non-corded, or 100 pieces corded. They have a noise reduction rating (NRR) of 32 decibels. They are made of non-irritating foam, dermatological safe, and one size fits all."],
    sizing: "One Size Fits All"
  },
  {
    _id: "1965",
    title: "Rad Band Ear Plugs",
    genre: { _id: "104", name: "Safety Items" },
    availability: "In Stock",
    condition: "New",
    brand: "Rad Band",
    display: "./display/radband.png",
    images: ["../itemPage/radband_1.jpg"],
    description: ["Made in USA. This is the hottest new thing in hearing protection. They feature a plastic Rad BandTM headset that holds the plugs in a perfect position for maximum hearing protection. The angle of the plugs is engineered to align with the ear canal for maximum comfort and attenuation. They are lightweight and very durable, and feature hypoallergenic Jelli-PlugsTM that are replaceable and reusable. They feature a noise reduction rating to 23 decibels."],
    sizing: "One Size Fits All"
  },
  {
    _id: "4260",
    title: "Class 2 Safety Vest",
    genre: { _id: "104", name: "Safety Items" },
    availability: "In Stock",
    condition: "New",
    brand: "ProTec",
    display: "./display/class2.png",
    images: ["../itemPage/class2_1.jpg"],
    description: ["These Class 2 safety vests are just what your customers have been asking for. They are designed for medium visibility at speeds under 50 mph and are available in either orange or lime. Other examples of uses of this vest are railway, utility, logging, road construction workers, ship cargo handling, trash collecting, school crossing guards, high volume parking and gate personel, airport baggage handlers, law enforcement and emergency response personel, accident site investigators and survey crews. Each vest has approximately 775 sq. in. of mesh, and 201 sq. in. or reflective stripping. Rated for up to 50 washings.", "Specifications:"],
    specs: ["100% ANSI certified ultra-lightweight fluorescent polyester mesh front and back", "Non-conductive nylon zipper front closure", "One cell phone velcro closure pocket", "Two large inside pockets", "2” Level II ANSI certified silver reflective stripes", "360° reflectivity provides full body outline"],
    sizing: "Medium to 3XL Available in Orange or Lime Green"
  },
  {
    _id: "8401",
    title: "Heel Grip Ice Cleats",
    genre: { _id: "104", name: "Safety Items" },
    availability: "In Stock",
    condition: "New",
    brand: "ProTec",
    display: "./display/heelgrip.png",
    images: ["../itemPage/heelgrip_1.jpg"],
    description: ["This is an exceptionally convenient winter walking device (ice cleat) to keep you from slipping on icey or snowy surfaces. They feature five studs and an aggressive tread design to provide traction in ice and snow conditions. The studs are made of tungsten carbide for maximum durability in industrial work conditions. The studs are molded to the device to ensure maximum durability and retention in even the harshest climates.","They come with a fully adjustable cross-over strap. They have approximately four inches of adjustability making them perfect for a variety of styles of work shoes from plain shoes to bulky pack boots. The “Large” size fits mens shoe/boot sizes from 9.5 to 11.5, and the “XLarge” size fits mens shoe/boot sizes from 12 – 14.5"],
    sizing: "Large or Extra Large"
  },
  {
    _id: "7910",
    title: "12 Gauge Power Cords",
    genre: { _id: "105", name: "Cords & Lights" },
    availability: "In Stock",
    condition: "New",
    brand: "Prime Cable",
    display: "./display/12gauge.png",
    images: ["../itemPage/12gauge_1.jpg","../itemPage/12gauge_2.jpg"],
    description: ["They feature a “Power On” connector light to help find the end of the cord in the dark and to be able to tell if the power is on. These cords feature a ST type jacket that is ultra-flexible in below freezing temperatures. The cord is safety yellow in color. They have molded on end plugs and are rated 13 amp – 125 volt – 1875 watt, and the wire is pure copper. These cords carry a one year warranty"],
    sizing: "25', 50' or 100' Lengths"
  },
  {
    _id: "7918",
    title: "10 Gauge Power Cords",
    genre: { _id: "105", name: "Cords & Lights" },
    availability: "In Stock",
    condition: "New",
    brand: "Prime Cable",
    display: "./display/10gauge.png",
    images: ["../itemPage/10gauge_1.jpg"],
    description: ["Manufactured by Prime Wire. This is a super heavy-duty UL rated outdoor extension cord for your customers that need 10 gauge capacity. This cord is yellow in color and will be labeled either Bulldog Tough or Hilti. They are great for running power tools that draw higher amperage, such as generators, table saws, air compressors, etc.", "Specifications:"],
    specs: ["Oil Resistant", "Flexible to -58 Degrees", "Water resistant", "Flame Retardant", "Power indication lights"],
    sizing: "50' or 100' Lengths"
  },
  {
    _id: "1032",
    title: "15 Amp GFCI",
    genre: { _id: "105", name: "Cords & Lights" },
    availability: "In Stock",
    condition: "New",
    brand: "Prime Cable",
    display: "./display/gfi.png",
    images: ["../itemPage/gfi_1.jpg"],
    description: ["Made in USA. This is a must have product for most for construction companies using portable power. They are now required by OSHA on most construction job sites.", "The initials GFCI stand for ground fault circuit interrupter. This product, unlike fuses or circuit breakers which protect electrical wiring, helps protect people from electrrical shock due to faulty appliances or ground faults (short circuits to the ground). This unit senses dangerous ground faults and interrupts power instanteously to help prevent serious injuries. 90 day warranty against defects in materials or workmanship.", "Specifications:"],
    specs: ["125 Volt", "60 Hz", "15 Amps", "2 Grounded Outlets.", "Test and Reset Buttons"],
    sizing: "This unit is U.L. listed, and comes with a 6’ SJT 14/3 cord."
  },
  {
    _id: "1036",
    title: "Triple Tap Adapter",
    genre: { _id: "105", name: "Cords & Lights" },
    availability: "In Stock",
    condition: "New",
    brand: "Prime Cable",
    display: "./display/tripletap.png",
    images: ["../itemPage/tripletap_1.jpg"],
    description: ["This is a contractor grade adapter that turns a standard extension cord into a triple tap cord. It is manufactured to demanding specifications and is designed for heavy duty outdoor use.", "This product features a yellow double thick ultra-flexible Nuflex-2 jacket, and is U.L. and OSHA approved. It is rated at 15 amp and 125 volt for standard household current."],
    sizing: "2 Foot"
  },
  {
    _id: "1016",
    title: "25' 2 Gauge Booster Cable",
    genre: { _id: "105", name: "Cords & Lights" },
    availability: "In Stock",
    condition: "New",
    brand: "Prime Cable",
    display: "./display/truckercable.png",
    images: ["../itemPage/truckercable_1.jpg"],
    description: ["These booster cables are truly some of the finest money can buy. They have a super heavy duty vutron rubber jacket which guarantees it's flexibility in sub zero weather. The jacket is tangle proof, and the wire is 2 gauge, making it good for trucks or heavy equipment. The clamps are 600 amp parrot jaw clamps and are shock-proof and color coded. Because of the heavy 2 gauge wire these cables are suitable for either 12 or 24 volt systems. One year warranty against defects in material and workmanship."],
    sizing: "25 Foot"
  },
  {
    _id: "1002",
    title: "3W COB LED Work Light",
    genre: { _id: "105", name: "Cords & Lights" },
    availability: "In Stock",
    condition: "New",
    brand: "Patriot",
    display: "./display/3wcob.png",
    images: ["../itemPage/3wcob_1.jpg"],
    description: ["This is a great combination work light and flashlight. It is rechargeable and emits 200 lumens.", "Specifications:"],
    specs: ["Revolutionary 3-Watt mega bright COB light chips, 200 lumen output", "1-Watt super bright bulb", "3.7 Volt 2,200mAH lithium ion battery", "360 degree swivel hook and magnetic base", "Handle bends up to 180 degrees", "Includes 110V wall charger and 12V car charger"],
    sizing: "One Size"
  },
  {
    _id: "1010",
    title: "Ultra Slim COB Work Light",
    genre: { _id: "105", name: "Cords & Lights" },
    availability: "In Stock",
    condition: "New",
    brand: "Patriot",
    display: "./display/slimcob.png",
    images: ["../itemPage/slimcob_1.jpg"],
    description: ["These cordless, rechargeable COB worklights are the latest and greatest in industrial and automotive markets. The super bright COB lighting system is much brighter than a standard, fluorescent, or even LED lights and they produce no heat. This is a great product for anyone from a cabinet installer to a automotive shop to an over the road trucker that needs a high quality portable light to see into dark areas. One year warranty against manufactures defects.", "Specifications:"],
    specs: ["Micro adjustable lumen switch adjusts from 0-450 lumens", "Swivel base features a strong 20 lb magnet that can be removed to shorten profile", "40 chip COB array allows for light density up to 850% more than LED's while consuming less power", "2200mAh Li-Ion rechargable battery", "2.5 - 10 working hours depending on brightness level used", "Only weighs 7 oz."],
    sizing: "One Size"
  },
  {
    _id: "1004",
    title: "Pivoting LED Headlamp",
    genre: { _id: "105", name: "Cords & Lights" },
    availability: "In Stock",
    condition: "New",
    brand: "Prime Cable",
    display: "./display/headlamp.png",
    images: ["../itemPage/headlamp_1.jpg"],
    description: ["It is manufactured by Prime Wire (same as our power cords), and carries a 90 day warranty against manufactures defects.", "Specifications:"],
    specs: ["3 modes - High & Low Spot Light, plus Flood Light Mode", "Energy-Efficient LEDs are cool to the touch, and up to 100 times the lifespan of incandescent lights", "Shatter Resistant Lens", "Anti-Slip Strap keeps headlamp securely attached to either head or hat", "3 – AAA batteries included"],
    sizing: "One Size"
  },
  {
    _id: "1001",
    title: "LED Pocket Light",
    genre: { _id: "105", name: "Cords & Lights" },
    availability: "In Stock",
    condition: "New",
    brand: "NTI",
    display: "./display/pocketlight.png",
    images: ["../itemPage/pocketlight_1.jpg"],
    description: ["It is small and easy to carry, and will illuminate a wide area. One year warranty against defects.", "Specifications:"],
    specs: ["1.5W COB LED Provides 150 Lumens Brightness", "3 x AAA Batteries Included", "Run Time: 4 hours", "Flood Beam Illuminates a Wide Area", "Impact Resistant ABS Plastic Construction", "About the size of a small cell phone – Fits easily into shirt pocket", "Magnetic Pocket Clip allows attachment to metal surfaces or clips on pocket or belt"],
    sizing: "One Size"
  },
  {
    _id: "1121",
    title: "Plumbers Holesaw Kit",
    genre: { _id: "106", name: "Holesaw & Misc" },
    availability: "In Stock",
    condition: "New",
    brand: "BluMol",
    display: "./display/holesawplumber.png",
    images: ["../itemPage/holesawplumber_1.jpg", "../itemPage/holesawplumber_2.jpg"],
    description: ["This is a bi-metal holesaw kit for cutting larger diameter holes in wood, light metal, and nail embedded wood. It contains specific sizes for the plumbing industry. The saws themselves have a 4/6 variable tooth pattern for faster cuts. They have a 1 1/8” depth of cut and can be used with most brands of mandrels on the market."],
    sizing: "3/4”, 7/8”, 1”, 1-1/8”, 1 1⁄2”, 1 3⁄4”, and 2 1⁄4”. It also contains 2 mandrels, an extension bar and a high density poly case."
  },
  {
    _id: "1122",
    title: "Electrician Holesaw Kit",
    genre: { _id: "106", name: "Holesaw & Misc" },
    availability: "In Stock",
    condition: "New",
    brand: "BluMol",
    display: "./display/holesawelec.png",
    images: ["../itemPage/holesawelec_1.jpg"],
    description: ["This is a bi-metal holesaw kit for cutting larger diameter holes in wood, light metal, and nail embedded wood. It contains specific sizes for the electrical industry. The saws themselves have a 4/6 variable tooth pattern for faster cuts. They have an extra deep 1-7/8” cut and can be used with most brands of mandrels on the market."],
    sizing: "7/8”, 1-1/8”, 1-3/8”, 1-3/4”, 2”, and 2 1⁄2”. It also contains 2 mandrels and a high density poly case."
  },
  {
    _id: "1125",
    title: "Industrial Holesaw Kit",
    genre: { _id: "106", name: "Holesaw & Misc" },
    availability: "In Stock",
    condition: "New",
    brand: "BluMol",
    display: "./display/holesawindustrial.png",
    images: ["../itemPage/holesawindustrial_1.jpg"],
    description: ["This is a bi-metal holesaw kit for cutting larger diameter holes in wood, light metal, and nail embedded wood. This is a complete kit for the master craftsman. The saws themselves have a 4/6 variable tooth pattern for faster cuts. They have an extra deep 1-7/8” cut and can be used with most brands of mandrels on the market."],
    sizing: "3/4”, 7/8”, 1-1/8”, 1-3/8”, 1-1/2”, 1-3/4”, 2”, 2-1/4”, 2-1/2”, 3”, 3-1/4”, 3-5/8”, 3-3/4”, 4-1/8”, and 4-1/2”. It also contains 2 mandrels, mandrel adapter, pilot drill, a 12” extension bar and a steel contractors case."
  },
  {
    _id: "1124",
    title: "Bahco Carbide Holesaw Kit",
    genre: { _id: "106", name: "Holesaw & Misc" },
    availability: "In Stock",
    condition: "New",
    brand: "Bahco",
    display: "./display/holesawbahco.png",
    images: ["../itemPage/holesawbahco_1.jpg"],
    description: ["Made in USA. This is a carbide tipped holesaw kit for cutting larger diameter holes in ceramic tile, wood laminates, fiberglass and other abrasive materials. These carbide tipped holesaws feature greater wear resistance and heat resistance than standard bi-metal holesaws, and they will effeciently cut abrasive materials with up to 10 times the life of standard bi-metal. They are 1 7/8” deep and can be used with most brands of mandrels on the market."],
    sizing: "3/4”, 7/8”, 1-1/8”, 1- 1/2”, 1-3/4”, 2”, 2-1/4”, and 2 1⁄2”. It also includes 2 mandrels, a mandrel adapter, three carbide tipped pilot bits, and a high density poly case."
  },
  {
    _id: "5601",
    title: "CMT Carbide Holesaw Kit",
    genre: { _id: "106", name: "Holesaw & Misc" },
    availability: "In Stock",
    condition: "New",
    brand: "CMT",
    display: "./display/holesawcmtkit.png",
    images: ["../itemPage/holesawcmtkit_1.jpg", "../itemPage/holesawcmtkit_2.jpg"],
    description: ["Manufactured by CMT Tool Corp. This is a very hot new product on the market for customers using holesaws. These holesaws are tungsten carbide tipped, so they cut 5X faster and 10X longer than standard bi-metal holesaws. They feature a patented quick connect mandril (you have to sell the mandril with the holesaw), a patented nesting system, and easy removal of the material inside the saw itself.", "Good for cutting through:"],
    specs: ["Soft, hardwoods and plywood.","Chipboard, MDF, veneered, laminated and table top (pre-drill the laminate/melamine by using left- hand rotation for clean finishing).", "Gypsum, plasterboard, fibercement boards, Eternit", "Plastics (PVC, nylon, Trespa®, polyester).", "Roofing tile (not ceramic tile), solid bricks and bricks with cavities, aerated concrete blocks, lightweight aggregate blocks and breeze blocks, soft density limes and stone building blocks"],
    sizing: "3/4”, 7/8”, 1-1/8”, 1- 1/2”, 1-3/4”, 2”, 2-1/4”, and 2 1⁄2”. It also includes 2 mandrels, a mandrel adapter and a high density poly case."
  },
  {
    _id: "5651",
    title: "MK Morse Holesaw Kit",
    genre: { _id: "106", name: "Holesaw & Misc" },
    availability: "In Stock",
    condition: "New",
    brand: "MK Morse",
    display: "./display/holesawmorse.png",
    images: ["../itemPage/holesawmorse_1.jpg", "../itemPage/holesawmorse_2.jpg"],
    description: ["Made in USA. These handy hole saw kits conveniently organize and store the sizes pros need within easy reach. For cutting in wood, nail embedded wood, ferrous and non-ferrous metals and plastics. Cutting depth is 1-15/16 ̋.", "Specifications:"],
    specs: ["Patent Pending Tooth Design – Optimized to remove material faster", "New cap - Reduces Runout and Vibration", "Premium M42 high speed steel cutting edge, 8% cobalt - Over 2X the life of other models", "Heavy duty .050 side wall - For Greater Stability", "New side slot - Increased Leverage for Faster, Easier Slug Removal", "New exterior red coating - Reduces Side Wall Friction for Efficient Cutting"],
    sizing: "Sizing Based On Availability"
  },
  {
    _id: "3346",
    title: "Wire Nuts",
    genre: { _id: "106", name: "Holesaw & Misc" },
    availability: "In Stock",
    condition: "New",
    brand: "ProTec",
    display: "./display/wirenut.png",
    images: ["../itemPage/wirenut_1.jpg"],
    description: ["These are color coded twist-on standard wire connectors used to splice two or more wires togather without taping. They are mainly used by electrical contractors, but are also used by auto & truck repair shops, industrial maintenance shops etc.", "Each connector is color coded to industry standards for easy size selection. A tight, square- wire spring threads directly onto conductors for fast connections with no slips or misses. They can be tightened without ratcheting yet are removable for re-use. They feature a fluted surface that permits a firm grip for easy finger-tight connections.", "These connectors feature a tough thermoplastic shell with a 600 volt rating. They are flame- retardant, non-porous, non-corrosive, and resistant to surface cracking and chemical action. They comply with Federal Specification W-5-610D, are US 486C listed and CSA certified."],
    sizing: "Available in Gray, Blue, Orange or Yellow"
  },
  {
    _id: "3339",
    title: "Winged Wire Nuts",
    genre: { _id: "106", name: "Holesaw & Misc" },
    availability: "In Stock",
    condition: "New",
    brand: "ProTec",
    display: "./display/wirenutwing.png",
    images: ["../itemPage/wirenutwing_1.jpg"],
    description: ["These are color coded twist-on winged wire connectors used to splice two or more wires togather without taping. They are mainly used by electrical contractors, but are also used by auto & truck repair shops, industrial maintenance shops etc.", "Each connector is color coded to industry standards for easy size selection. The winged design allows for greater leverage when attaching. They back off easily for re-use. With the winged connectors no pre-twisting or tools are necessary.", "These connectors feature a tough thermoplastic shell with a 600 volt rating. They are flame- retardant, non-porous, non-corrosive, and resistant to surface cracking and chemical action. They comply with Federal Specification W-5-610D, are UL 486C listed and CSA certified."],
    sizing: "Available in Tan, Yellow or Red"
  },
  {
    _id: "3355",
    title: "Waterproof Wire Nuts",
    genre: { _id: "106", name: "Holesaw & Misc" },
    availability: "In Stock",
    condition: "New",
    brand: "DryConn",
    display: "./display/wirenutwater.png",
    images: ["../itemPage/wirenutwater_1.jpg"],
    description: ["Made in USA. These DryConn® Aqua Series waterproof connectors are designed for underground or wet conditions. They are primarily for outdoor use and will be used heavily by landscapers and in other damp conditions. They are UL 486D listed and comply with the 2008, 2011 and 2014 National Electric Code.", "Specifications:"],
    specs: ["Waterproof and corrosion proof", "Pre-filled with dielectric silicone sealant that never hardens", "One-step® installation saves time and money", "No pre-twisting required", "Multiple sizes for all applications", "Designed for outdoor/electrical wire splices, especially those exposed to condensation, water vapors or dust"],
    sizing: "Available In Most Sizes, Please Specify In Notes"
  },
  {
    _id: "1131",
    title: "Grinding Wheels",
    genre: { _id: "107", name: "Cutting & Grinding" },
    availability: "In Stock",
    condition: "New",
    brand: "Patriot",
    display: "./display/grind.png",
    images: ["../itemPage/grind_1.jpg"],
    description: ["These are industrial quality, A-24 all purpose medium grit grinding wheels used in all types of metal work. They meet all current safety standards and are fiberglass reinforced. They have a depressed center, are 1⁄4” thick, and are considered type 27. Some have threaded hubs and some do not. Discs without threaded hubs require an adapter to attach wheel to grinder."],
    sizing: "Available in 4 Inch up to 9 Inch Diameters"
  },
  {
    _id: "1159",
    title: "Cutting Wheels",
    genre: { _id: "107", name: "Cutting & Grinding" },
    availability: "In Stock",
    condition: "New",
    brand: "Patriot",
    display: "./display/cut.png",
    images: ["../itemPage/cut_1.jpg"],
    description: ["These are industrial quality fiberglass reinforced abrasive wheels for cutting all types of metals. They meet all current safety standards and are designed to up at up to twice their rpm rating. They can be used in portable or stationary electic cut-off machines. If you customer is using the gas powered portable saws he will need the 5/32” wheels. They are used extensively in the construction and fabrication industries."],
    sizing: "Available in 3 Inch up to 16 Inch Diameters"
  },
  {
    _id: "1155",
    title: "Aluminium Grinding Wheels",
    genre: { _id: "107", name: "Cutting & Grinding" },
    availability: "In Stock",
    condition: "New",
    brand: "Patriot",
    display: "./display/alumgrind.png",
    images: ["../itemPage/alumgrind_1.jpg"],
    description: ["This is an industrial quality grinding disc used for both aluminum and stainless steel. Many customers don’t know that you use the same wheel for aluminum and stainless steel work. They are 1⁄4” thick. These wheels are used by anyone welding or working with these types of metals and are used heavily in the marine industry."],
    sizing: "Available in 4 1/2 and 7 Inch Diameters"
  },
  {
    _id: "1137",
    title: "Zircon Grinding Disc",
    genre: { _id: "107", name: "Cutting & Grinding" },
    availability: "In Stock",
    condition: "New",
    brand: "Patriot",
    display: "./display/zircon.png",
    images: ["../itemPage/zircon_1.jpg"],
    description: ["These are industrial quality, type 27 grinding discs that are designed to compete with the Norton disc called the Norzon III.", "The cutting agent in these wheels is NZ alundum, which is actually very small chips of zirconia diamond. Operator efficiency is improved because these discs will outlast standard discs about 4 to 1 with a 30% greater stock removal rate and almost eliminate bouncing and spalling. While these wheels run 50% more on the original purchase, with a 4 to 1 life expectancy ratio they are much cheaper in the long run to operate."],
    sizing: "Available in 4 1/2 Inch Diameter"
  },
  {
    _id: "1158",
    title: "Metal Pipe Grinding Wheels",
    genre: { _id: "107", name: "Cutting & Grinding" },
    availability: "In Stock",
    condition: "New",
    brand: "Patriot",
    display: "./display/grind.png",
    images: ["../itemPage/grind_1.jpg"],
    description: ["This is a grinding disc that a lot of your customers use and ask for. They are often used in the oilfield industry, but they have applications in grinding or cutting any type of metal pipe. Instead of the standard 1/4” thickness they are only 1/8” in thickness, making them applicable for both grinding and cutting. They have a depressed center, are 1/8” thick, and are considered type 27. Some have threaded hubs and some do not. Discs without threaded hubs require an adapter to attach wheel to grinder."],
    sizing: "Available in 4 1/2 Inch Diameter"
  },
  {
    _id: "3455",
    title: "Slicer Wheels",
    genre: { _id: "107", name: "Cutting & Grinding" },
    availability: "In Stock",
    condition: "New",
    brand: "Patriot",
    display: "./display/slice.png",
    images: ["../itemPage/slice_1.jpg"],
    description: ["This is a very popular abrasive cut-off wheel that is called either a slicer wheel or thin wheel. ","This wheel is 4 one hundreths of an inch thick, making them thinner and will burn up a little faster than 1/16” thick wheels but will cut much faster and cleaner than a standard cutoff wheel. These wheels feature three layers of fiberglass pressed tight so they will not fall apart and hurt customers like the cheaper knockoffs of the same style. "],
    sizing: "Available in 4 1/2 and 6 Inch Diameters"
  },
  {
    _id: "2052",
    title: "Ductile Cutoff Wheels",
    genre: { _id: "107", name: "Cutting & Grinding" },
    availability: "In Stock",
    condition: "New",
    brand: "Patriot",
    display: "./display/ductile.png",
    images: ["../itemPage/ductile_1.jpg"],
    description: ["These are industrial quality double reinforced heavy duty abrasive wheels. While they are designed for high speed gas powered portable saws, they can also be used in any electric saw. They meet and exceed all current safety standards and are used in cutting ductile iron and cast iron pipe and fittings. They are used extensively in the construction and fabrication industries."],
    sizing: "Available in 14 Inch Diameter But Please Specify Arbor Size"
  },
  {
    _id: "1145",
    title: "Masonary Cutoff Wheels",
    genre: { _id: "107", name: "Cutting & Grinding" },
    availability: "In Stock",
    condition: "New",
    brand: "Patriot",
    display: "./display/masoncut.png",
    images: ["../itemPage/masoncut_1.jpg"],
    description: ["Industrial quality fiberglass reinforced abrasive cut-off wheels for use in masonry or concrete. They meet all current safety standards and can be used in portable or stationary electric saws. Ideal for a quick, inexpensive cut. They are a standard 1/8 inch thick."],
    sizing: "Available in 7, 12, and 14 Inch Diameters But Please Specify Arbor Size"
  },
  {
    _id: "2001",
    title: "Sanding Belts",
    genre: { _id: "108", name: "Sanding Products" },
    availability: "In Stock",
    condition: "New",
    brand: "Continental",
    display: "./display/belts.png",
    images: ["../itemPage/belts_1.jpg"],
    description: ["These are industrial quality aluminum oxide sanding belts for electric belt sanders. They feature a resin grit that is electrostaticly bonded to a heavy duty cloth backing. The seams are butt-spliced and taped instead of overlapped to make them multi-directional for longer belt life where you can run the belt one way, tap it out, and then run it the other."],
    sizing: "Available in 3x21, 3x24 and 4x24 Sizes as well as 24 up to 240 Grit"
  },
  {
    _id: "2065",
    title: "Peel and Stick (PSA) Discs",
    genre: { _id: "108", name: "Sanding Products" },
    availability: "In Stock",
    condition: "New",
    brand: "Continental",
    display: "./display/psa.png",
    images: ["../itemPage/psa_1.jpg"],
    description: ["These are pressure sensitive adhesive sandpaper discs that self stick to a sanding pad. They are used on both air and electric random orbital sanders. They feature a heavy duty anti-clog coating for non-loading high performance and a resin mineral bond for longer life. They are on “C/D” weight paper that is much stronger and tear resistant than standard “A” weight paper. These discs are used for both wood and metal finishing, and are used by painters, plasterers, drywall contractors etc. These discs are priced and sold in rolls of 100."],
    sizing: "Available in 5 and 6 Inch Diameters in 60 to 400 Grits"
  },
  {
    _id: "2286",
    title: "Shop Roll",
    genre: { _id: "108", name: "Sanding Products" },
    availability: "In Stock",
    condition: "New",
    brand: "Continental",
    display: "./display/shoproll.png",
    images: ["../itemPage/shoproll_1.jpg"],
    description: ["They have many uses around the workshop or production plant. The material is an flexible J weight cotton cloth with an aluminum oxide abrasive. Each roll is 50 yards long, and the abrasive strips can be easily torn off the roll. A strip can also be torn in the long direction so you can make a narrow strip for sanding hard to reach places. The rolls can be mounted on dowels or similar spindles for easy dispensing. They are heavily used by plumbing contractors for cleaning copper pipe before sweating it together."],
    sizing: "Available in 1 and 1 1/2 Inch Widths in 60 to 400 Grits"
  },
  {
    _id: "2381",
    title: "Hook and Loop Discs",
    genre: { _id: "108", name: "Sanding Products" },
    availability: "In Stock",
    condition: "New",
    brand: "Continental",
    display: "./display/hookloop.png",
    images: ["../itemPage/hookloop_1.jpg"],
    description: ["Imported from Europe. These discs are designed to work on any sander with the given hole dust extraction system. They are gold in color and are pre-punched for dust extraction from the work surface in a the hole pattern.", "This Hook & Loop Disc is designed for long life and quick change ability, and they can be removed and reinstalled many times. Its superior flexibility allows sanding on uneven surfaces, and its superior gripping features will not shift position once on sanding pad."],
    sizing: "Available in 5 and 6 Inch Diameters in either 6 or 8 Hole Patterns with 60 to 400 Grits"
  },
  {
    _id: "2325",
    title: "Scuff Pads",
    genre: { _id: "108", name: "Sanding Products" },
    availability: "In Stock",
    condition: "New",
    brand: "Continental",
    display: "./display/scuff.png",
    images: ["../itemPage/scuff_1.jpg"],
    description: ["These are 6” x 9” abrasive hand pads (also known as Scotch-Brite pads). They are made of a non-woven synthetic based fleece impregnated with a high quality grit.", "They are used for surface preparation of metal, wood, and fiberglass. They can also be used to strip paint and varnish, “de-fuzz” wood, and for janitorial applications. They are flexible, waterproof, self-dressing, and are extremely versatile."],
    specs: ["Maroon very fine pad: Most popular of the three, it features VF grade “prism & fist” shaped grains which give a uniform scratch finish. Cross reference to 3M© #7447 and Norton # 747.", "Grey ultra fine pad: This pad is ideally suited to metal finsihing due to the sharp knife-like edges that give a clean cutting action. Cross reference with the 3M© #7448 and Norton #635.", "White non-abrasive pad: This pad features a non-abrasive material for use in cleaning delicate and non scratch surfaces. Cross reference with the 3M© #7445/98 and Norton # 456."],
    sizing: "Available in Maroon, Grey and White given the application"
  },
  {
    _id: "2409",
    title: "Resin Fiber Discs",
    genre: { _id: "108", name: "Sanding Products" },
    availability: "In Stock",
    condition: "New",
    brand: "Continental",
    display: "./display/resfiber.png",
    images: ["../itemPage/resfiber_1.jpg"],
    description: ["This is a professional quality resin fibre disc also known as a sanding disc. They come in a variety of sizes and grits. They are designed for use on both electric and pneumatic angle sanders and grinders, and they must be used in conjuction with a rubber backing pad. These discs must be sold in increments of 25 with no exceptions."],
    sizing: "Available in 4 1/2 and 5 Inch Diameters in 16 to 120 Grits"
  },
  {
    _id: "2276",
    title: "Flap Discs",
    genre: { _id: "108", name: "Sanding Products" },
    availability: "In Stock",
    condition: "New",
    brand: "Patriot",
    display: "./display/flap.png",
    images: ["../itemPage/flap_1.jpg"],
    description: ["This is a super hot product in welding and fabrication shops. It is a Zirconia type 29 disc that combines the work of a depressed center grinding wheel and a resin fiber disc in one wheel. It provides a combination of grinding and finishing through the use of thick, high quality abrasive cloth flaps bound to a fiberglass backing. They allow for a rapid rate of stock removal and will outlast a resin fiber disc 20 to 1. They can be used on carbon and alloyed steel, high speed steel, and nonferrous metals and plastics."],
    sizing: "Available in 4 1/2 Inch Diameter in 40 to 120 Grits"
  },
  {
    _id: "2276",
    title: "Surface Prep Discs",
    genre: { _id: "108", name: "Sanding Products" },
    availability: "In Stock",
    condition: "New",
    brand: "Patriot",
    display: "./display/prep.png",
    images: ["../itemPage/prep_1.jpg"],
    description: ["These surface conditioning discs are used primarily to remove rust, oxides and coatings on metal surfaces. They can also be used for deburring, cleaning and finishing applications. They are normally used in a 1⁄4” die grinder and require a ROLOCTM style holder pad. All holders feature a 1⁄4” shaft."],
    specs: ["Abrasive coated non-woven fibers", "Nylon threaded male hub", "High surface and toning coverage", "Easy mounting to a ROLOCTM style holder pad", "Reduced undercutting, flattening or gouging"],
    sizing: "Available in 2 and 3 Inch Diameter in Coarse, Medium or Fine"
  },
  {
    _id: "2274",
    title: "Clean & Strip Cup Wheel",
    genre: { _id: "108", name: "Sanding Products" },
    availability: "In Stock",
    condition: "New",
    brand: "Patriot",
    display: "./display/cleanstrip.png",
    images: ["../itemPage/cleanstrip_1.jpg"],
    description: ["This is a disc that will do almost everything for you. It is made of nylon that is impregnated with extra-course silicon carbide grain. It is a highly flexible alternative to wire brush wheels, hand scrapers or abrasive discs. They can be easily cleaned with pressurized air, or they can be rinsed clean with water and allowed to dry. You can actually strip the paint from a soda can with this wheel without damaging the aluminum of the can. 3M sells a similar product called a “clean & strip” disc, and Pearl Abrasive version is called a “Strip 45”.", "Specifications:"],
    specs: ["Cleans large surfaces quickly & quietly", "Grinds in corners, on contours and hard-to-reach areas", "Faster cutting and safer than wire brushes and wheels", "Cleans any surface without damaging or leaving contaminants", "Product contains no metal components, no rust for stainless steel industry", "Suitable for Industrial, Automotive, Marine, Stainless Steel fabrication and household DIY"],
    sizing: "Available in 4 1/2 Inch Diameter"
  },
  {
    _id: "5951",
    title: "Fire And Rescue Diamond Wheels",
    genre: { _id: "109", name: "Diamond Products" },
    availability: "In Stock",
    condition: "New",
    brand: "Patriot",
    display: "./display/firerescue.png",
    images: ["../itemPage/firerescue_1.jpg", "../itemPage/firerescue_2.jpg"],
    description: ["This “Extractor” diamond rescue blade is used by fire departments for all types of cutting. They are engineered with a new generation vacuum brazing making this blade capable of cutting through steel, rebar, ductile iron, angle iron, concrete, brick, PVC pipe, roof decking, wood and sheet metal. They feature 5 mm segments and can be used wet or dry."],
    sizing: "The 4”, 4 1⁄2” and 5” have 7/8” – 5/8” arbors, the 7” and 10” have diamond 7/8” - 5/8” arbors, and the 12”, 14” and 16” blades have the 1” – 20mm arbors."
  },
  {
    _id: "6009",
    title: "Segmented Diamond Blades",
    genre: { _id: "109", name: "Diamond Products" },
    availability: "In Stock",
    condition: "New",
    brand: "Patriot",
    display: "./display/segmented.png",
    images: ["../itemPage/segmented_1.jpg"],
    description: ["These are general purpose segmented diamond blades that are ideal for fast cuts in masonry or concrete. They are considered dry blades but they can be used wet if desired. They have just over 1/4” depth of diamond concentrate. These blades can be used in stationary or hand held saws. 90 day warranty against manufactures defects."],
    sizing: "Available in 4” to 14” Diameters"
  },
  {
    _id: "6007",
    title: "Continuous Rim Diamond Blades",
    genre: { _id: "109", name: "Diamond Products" },
    availability: "In Stock",
    condition: "New",
    brand: "Patriot",
    display: "./display/turbo.png",
    images: ["../itemPage/turbo_1.jpg"],
    description: ["This is a general purpose blade with a serrated continuous rim known in the industry as a turbo blade. They are ideal for wet or dry cuts in concrete brick, block tile, and stone. They are smoother cutting than a segmented blade and have less of a chance of being ruined if they happen to hit rebar. This is a top of the line blade known in the industry as premium quality. 90 day warranty against manufactures defects."],
    sizing: "Available in 4” to 14” Diameters"
  },
  {
    _id: "6020",
    title: "Ceramic Tile Blade",
    genre: { _id: "109", name: "Diamond Products" },
    availability: "In Stock",
    condition: "New",
    brand: "LA Diamond",
    display: "./display/tileblade.png",
    images: ["../itemPage/tileblade_1.jpg"],
    description: ["These blades are premium quality continuous rim blades for use in wet saws only. They are designed for smooth, chip free cuts in tile, ceramics, glass, and porcelain. They cannot be used for dry cuts. 90 day warranty against manufactures defects."],
    sizing: "Available in 4”, 8” and 10” Diameters"
  },
  {
    _id: "2435",
    title: "Metal Cutting Diamond Wheels",
    genre: { _id: "109", name: "Diamond Products" },
    availability: "In Stock",
    condition: "New",
    brand: "Patriot",
    display: "./display/cat.png",
    images: ["../itemPage/cat_1.jpg"],
    description: ["This is the hottest new item on the market for ferrous metals, iron, steel, stainless steel, rebar, copper, and other high tensile alloys. It is a revolutionary diamond wheel for metal applications. It is designed to cut metal, stainless steel and a variety of ferrous metals with less odor and debris compared to conventional abrasives. It is engineered with a high concentration of diamonds and an extremely strong bond."],
    specs: ["Ultra thin kerf – Cuts extremely fast", "Lasts up to 65 times longer than a standard bonded abrasive wheel", "Vacuum Brazed technology – For extra long life", "Maintains blade diameter – For consistent cutting depth", "Fewer blade changes – Less downtime", "Less dust and debris than conventional abrasives", "Cuts 1/2 inch rebar in 4 seconds, cuts 5/8 inch rebar in 10 seconds, and 1/8 inch angle iron in 6 seconds"],
    sizing: "Available in 4”, 8” and 10” Diameters"
  },
  {
    _id: "5701",
    title: "Diamond Core Bits",
    genre: { _id: "109", name: "Diamond Products" },
    availability: "In Stock",
    condition: "New",
    brand: "LA Diamond",
    display: "./display/diamondcore.png",
    images: ["../itemPage/diamondcore_1.jpg","../itemPage/diamondcore_2.jpg"],
    description: ["These are top quality premium coring bits. They are designed to be used wet only(water for lubrication). Your customers will use this bit to drill through cured concrete, concrete with light steel reinforcement, concrete brick and block. They are the industry standard 17” overall length with a maximum drilling capacity of 14”. They feature a very high diamond concentration for faster coring and longer life. They will be used in hand held core drills or core drill rigs. "],
    sizing: "The sizes from 1” to 1-1/2” have a standard 5/8”-11 arbor and from 1-3/4” and up they have the standard 1-1/4”-7 arbor."
  },
  {
    _id: "5702",
    title: "Husqvarna Diamond Blades",
    genre: { _id: "109", name: "Diamond Products" },
    availability: "In Stock",
    condition: "New",
    brand: "Husqvarna",
    display: "./display/husqyblade.png",
    images: ["../itemPage/husqyblade_1.jpg"],
    description: ["The Husqvarna Vari-Cut Wet/Dry Turbo Diamond Blade can be used for a comprehensive assortment of uses, making it a precise combination blade. It is a general-purpose and coarse material blade for power-cutters, flat-saws or brickwork saws. | Husqvarna 14 Inch Vari-Cut High Speed Diamond Blade"],
    sizing: "Available in 14” Diameter."
  },
  {
    _id: "5903",
    title: "MK Diamond Blades",
    genre: { _id: "109", name: "Diamond Products" },
    availability: "In Stock",
    condition: "New",
    brand: "MK",
    display: "./display/mkblade.png",
    images: ["../itemPage/mkblade_1.jpg"],
    description: ["Manufactured by MK Diamond Products. These blades are long life, contractor quality and are manufactured by MK Diamond, one of the best known names in the industry. They feature an 8mm rim height and are ideal for concrete, brick/pavers, sandstone, stucco, and roofing tiles. These are dry cut blades, not for walk behind saws."],
    sizing: "Available in 4 1/2” to 14” Diameters."
  },
  {
    _id: "6060",
    title: "Diamond Cup Wheels",
    genre: { _id: "109", name: "Diamond Products" },
    availability: "In Stock",
    condition: "New",
    brand: "LA Diamond",
    display: "./display/diamondcup.png",
    images: ["../itemPage/diamondcup_1.jpg"],
    description: ["They are used by concrete or masonry contractors for quick removal of form marks and irregularities in the surface of concrete or masonry. They can also be used for surface preperation of granite countertops etc. They are designed to be used wet or dry. They feature a single row of diamond and are designed for long life and quick removal of product."],
    sizing: "These cup wheels are designed to be used on 4” and 7” hand held grinders."
  },
  {
    _id: "1797",
    title: "Free All Penetrating Oil",
    genre: { _id: "110", name: "Chemicals" },
    availability: "In Stock",
    condition: "New",
    brand: "Federal Process",
    display: "./display/freeall.png",
    images: ["../itemPage/freeall_1.jpg", "../itemPage/freeall_2.jpg", "../itemPage/freeall_3.jpg"],
    description: ["Made in USA. This product is an industrial grade lubricant manufactured by Federal Process Corp. It penetrates, lubricates, and displaces moisture. It is used to loosen rusted nuts and bolts, and protects against rusting and corrosion. It is considered by many to be the better answer to WD-40. Recommended on nuts, bolts, locks, studs, shears, joints, snips, garden and shop tools, muffler clamps, machine screws, and linkages"],
    sizing: "12 Ounce Cans"
  },
  {
    _id: "1798",
    title: "Grease In A Can",
    genre: { _id: "110", name: "Chemicals" },
    availability: "In Stock",
    condition: "New",
    brand: "Federal Process",
    display: "./display/greasecan.png",
    images: ["../itemPage/greasecan_1.jpg", "../itemPage/greasecan_2.jpg"],
    description: ["Made in USA. This product is a remarkable premium white lithium grease lubricant manufactured by Federal Process that comes in a 14 oz. aerosol can. It comes out of the can as a thin, penetrating liquid and quickly becomes a thick white grease with exceptional adhesive and cohesive properties. It lubricates, prevents oxidation, prevents rust, and provides excellent pressure and cushioning properties. It can also withstand temperatures of 500 Degrees F."],
    sizing: "14 Ounce Cans"
  },
  {
    _id: "1796",
    title: "Soft Touch Hand Cleaner",
    genre: { _id: "110", name: "Chemicals" },
    availability: "In Stock",
    condition: "New",
    brand: "Powerline Chemicals",
    display: "./display/soft.png",
    images: ["../itemPage/soft_1.jpg"],
    description: ["Made in USA. This is an industrial strength non-abrasive hand cleaner made by Powerline Products. It can be used with or without water. It is easy on your hands and it contains lanolin and a bacteriostatic ingredient so it conditions and disinfects as it cleans. It comes in a 16 oz. squeezeable plastic tube with a convenient flip top cap. This cleaner is great at removing tar, grease, putty, graphite, dye, carbon, adhesives, paint, ink, asphalt, grime, rubber cement, mastic and other difficult materials."],
    sizing: "16 Ounce Tubes"
  },
  {
    _id: "1794",
    title: "Tough Touch Hand Cleaner",
    genre: { _id: "110", name: "Chemicals" },
    availability: "In Stock",
    condition: "New",
    brand: "Powerline Chemicals",
    display: "./display/tough.png",
    images: ["../itemPage/tough_1.jpg"],
    description: ["Made in USA. This is an industrial strength hand cleaner made by Powerline Products. It can be used with or without water. It is easy on your hands and it contains lanolin and a bacteriostatic ingredient so it conditions and disinfects as it cleans. It also contains a fine pumice for those extra tough jobs. It comes in a 16 oz. squeezeable plastic tube with a convenient flip top cap. This cleaner is great at removing tar, grease, putty, graphite, dye, carbon, adhesives, paint, ink, asphalt, grime, rubber cement, mastic and other difficult materials."],
    sizing: "16 Ounce Tubes"
  },
  {
    _id: "1787",
    title: "Lithium Grease Cartidges",
    genre: { _id: "110", name: "Chemicals" },
    availability: "In Stock",
    condition: "New",
    brand: "Lubrimatic",
    display: "./display/greasetube.png",
    images: ["../itemPage/greasetube_1.jpg"],
    description: ["Made in USA. This is a top quality multi-purpose lithium based grease your customers can depend on. It is manufactured under the Ultra-Lube name by Lubrimatic Corporation, a name your customers know and trust. This long-lasting, hard-working formula delivers high performance in a wide range of heavy-duty applications. It is recommended for chassis, universal joints and other general-purpose applications around the home, farm or shop. It is designed for and works best on rolling parts."],
    sizing: "Each package contains ten 14 oz cartridges of grease"
  },
  {
    _id: "1789",
    title: "Pistol Grease Gun",
    genre: { _id: "110", name: "Chemicals" },
    availability: "In Stock",
    condition: "New",
    brand: "Lubrimatic",
    display: "./display/pistol.png",
    images: ["../itemPage/pistol_1.jpg"],
    description: ["This is one of the finest and best known hand operated grease guns on the market. It is the popular pistol grip style, making one hand operation possible. It features an electrostatic finish with a durable zinc die cast head, check valves to prevent leaking, and a 6,000 PSI rating. It comes complete with a hydraulic coupler and a 6 inch extension pipe. This unit uses our standard 14 oz grease cartridges, but can also be bulk or filler fitting loaded. One year warranty against manufactures defects."],
    sizing: "One Size"
  },
  {
    _id: "1790",
    title: "Lever Grease Gun",
    genre: { _id: "110", name: "Chemicals" },
    availability: "In Stock",
    condition: "New",
    brand: "Lubrimatic",
    display: "./display/lever.png",
    images: ["../itemPage/lever_1.jpg"],
    description: ["Imported by Plews/Lubrimatic, this is one of the finest and best known hand operated grease guns on the market. It features an electrostatic finish with a durable zinc die cast head, check valves to prevent leaking, and a 6,000 PSI rating. It comes complete with a hy- draulic coupler and a 6 inch extension pipe. This unit uses our standard 14 oz grease cartridges, but can also be bulk or filler fitting loaded. One year warranty against manufactures defects."],
    sizing: "One Size"
  },
  {
    _id: "6650",
    title: "Tub O Towel Wipes",
    genre: { _id: "110", name: "Chemicals" },
    availability: "In Stock",
    condition: "New",
    brand: "Federal Process",
    display: "./display/tub.png",
    images: ["../itemPage/tub_1.jpg"],
    description: ["They come in a 90 count package and the towels are extra large (10” x 12” size). They are great for cleaning tools, tool boxes, equipment, work surfaces, painted surfaces, vinyl’s, plastics and metals. These towels also contain aloe and vitamin E to soften and condition hands.", "This product quickly removes most tough to clean substances from your hands including:"],
    specs: ["Lubricants", "Oils", "Ink", "Tar", "Wax", "Caulks", "Sealants", "Paints", "Epoxies", "Adhesives"],
    sizing: "One Size"
  },
  {
    _id: "6701",
    title: "Industrial Absorbent Pads",
    genre: { _id: "110", name: "Chemicals" },
    availability: "In Stock",
    condition: "New",
    brand: "Federal Process",
    display: "./display/sorbentpad.png",
    images: ["../itemPage/sorbentpad_1.jpg"],
    description: ["Made in USA. This is a hot new expendable item that customers love and buy over and over again. They are specifically designed for clean-up of industrial spills and leaks. They are constructed of medium weight twin-bonded layers of meltblown polypropylene with ultra sonic (dimpled) weld points for increased strength and durability. The pads are 15” x 17” and come in a plastic bag of 100 pads. They would be used by oil-field contractors, mechanics, heavy equipment operators, marine repair shops, auto repair shops, plumbing contractors etc.", "These pads will absorb up to 25 times their weight in liquids. A pack of 100 will absorb up to 24 lbs of fluids. These pads are not reusable. The absorbed liquid may be squeezed out for recycling purposes, but the pad should be disposed of properly. "],
    sizing: "One Size"
  },
  {
    _id: "6707",
    title: "Industrial Absorbent Socks",
    genre: { _id: "110", name: "Chemicals" },
    availability: "In Stock",
    condition: "New",
    brand: "Federal Process",
    display: "./display/sorbentsock.png",
    images: ["../itemPage/sorbentsock_1.jpg"],
    description: ["Made in USA. This is a hot new expendable item that your customers will love and buy over and over again. They are specifically designed for clean-up of industrial spills and leaks. They are constructed of medium weight twin-bonded layers of meltblown polypropylene. Included in this package are 10 absorbant socks (3” x 4’ in length). Each sock will absorb up to one gallon of liquid and will easily mold to almost any shape. They are great for use around drums or machinery or to wrap around floor and ground spills for containment."],
    sizing: "Packs of 10"
  },
  {
    _id: "1792",
    title: "Enforcer Wasp & Hornet Spray",
    genre: { _id: "110", name: "Chemicals" },
    availability: "In Stock",
    condition: "New",
    brand: "Federal Process",
    display: "./display/wasp.png",
    images: ["../itemPage/wasp_1.jpg"],
    description: ["Made in USA. This is a must have product for every customer you have that have to get into areas where wasp and hornets nest, especially in the spring and early summer. It is an aerosol that will deliver a jet blast up to 22 feet so nests can be sprayed from a safe distance. It kills the entire nest of wasps, hornets, or yellow jackets on contact and it has a residual action that kills any stray insects returning to the nest. It is non-conductive up to 47,300 volts and comes in a jumbo 16oz aerosol can. Active ingredients are tetramethrin, permethrin, and piperonyl butoxide."],
    sizing: "16 Ounce Cans"
  },
  {
    _id: "1772",
    title: "Red Shop Towels",
    genre: { _id: "110", name: "Chemicals" },
    availability: "In Stock",
    condition: "New",
    brand: "Federal Process",
    display: "./display/redTowel.png",
    images: ["../itemPage/redTowel_1.jpg"],
    description: ["Made in USA. This is a must have product for every customer you have that have to get into areas where wasp and hornets nest, especially in the spring and early summer. It is an aerosol that will deliver a jet blast up to 22 feet so nests can be sprayed from a safe distance. It kills the entire nest of wasps, hornets, or yellow jackets on contact and it has a residual action that kills any stray insects returning to the nest. It is non-conductive up to 47,300 volts and comes in a jumbo 16oz aerosol can. Active ingredients are tetramethrin, permethrin, and piperonyl butoxide."],
    sizing: "50 Pack"
  },
  {
    _id: "4190",
    title: "Metabo 4-1/2” Angle Grinder",
    genre: { _id: "111", name: "Power Tools" },
    availability: "In Stock",
    condition: "New",
    brand: "Metabo",
    display: "./display/metabo4.png",
    images: ["../itemPage/metabo4_1.jpg", "../itemPage/metabo4_2.jpg"],
    description: ["Manufactured by Metabo. Metabo is a leading international manufacturer of professional grade portable electric power tools and abrasives for industrial, construction and welding applications. This grinder is ideal for cutting, grinding and deburring metal. One year warranty against manufactures defects.", "Specifications:"],
    specs: ["Slim motor housing for ideal handling", "Steel housing head and steel wheel guard", "Robust and wear-resistant 4-pole motor", "750-Watt / 7.0 amp motor", "11,000 / RPM"],
    sizing: "One Size"
  },
  {
    _id: "4190",
    title: "Bosch 4-1/2” Angle Grinder",
    genre: { _id: "111", name: "Power Tools" },
    availability: "In Stock",
    condition: "New",
    brand: "Bosch",
    display: "./display/boschgrinder.png",
    images: ["../itemPage/boschgrinder_1.jpg", "../itemPage/boschgrinder_2.jpg"],
    description: ["Manufactured by Bosch Power Tools. This is Bosch’s most popular 4 1⁄2” hand held grinder. One year warranty through Bosch.", "Specifications:"],
    specs: ["6 Amp, 11,000 RPM, 10.5” in length, weighs 3.75 lbs", "Lock-on/off switch for extended use applications", "Service Minder brushes eliminates guesswork, stops the tool when preventative maintenance is required", "Epoxy coated field windings provides ultimate protection against abrasive dust and metal debris", "Spindle lock for quick wheel changes", "Double insulated, UL listed, complies to OSHA", "2-position auxiliary handle specifically designed and angled to a natural position for maximum comfort and control", "Includes grinder, guard, auxiliary handle, spanner wrench and 1 abrasive wheel"],
    sizing: "One Size"
  },
  {
    _id: "4030",
    title: "Bosch 18V Angle Grinder",
    genre: { _id: "111", name: "Power Tools" },
    availability: "In Stock",
    condition: "New",
    brand: "Bosch",
    display: "./display/boschgrindercordless.png",
    images: ["../itemPage/boschgrindercordless_1.jpg", "../itemPage/boschgrindercordless_2.jpg"],
    description: ["Manufactured by Bosch Power Tools. Here is one of the most versitle and powerful battery operated 4 1⁄2” grinders on the market and at a great price. They carry a one year warranty through Bosch.", "This Bosch 18V 4-1/2” angle grinder with CORE18V batteries delivers the ultimate combination of performance, control and power in a cordless grinder kit. The brushless motor has the capacity to transfer up to 35% more power and up to 110% more runtime using the 18V batteries. It has advanced electronics for enhanced user protection, such as drop control to shut the tool off if it’s accidentally dropped, kickback control to reduce the hazard of sudden tool reactions to binding situations, restart protection and soft-start technology. It also features a burst-resistant wheel guard. This tool features a two-position Vibration Control side handle, slim soft-grip design and a large spindle lock. The kit includes:"],
    specs: ["18V 4-1/2 In. Angle Grinder", "18V-4.0 Ah Battery", "18V Fast Charger - BC1880", "Vibration Control Handle", "Wheel Guard", "Carrying Bag"],
    sizing: "One Size"
  },
  {
    _id: "4025",
    title: "Bosch 18V Reciprocating Saw",
    genre: { _id: "111", name: "Power Tools" },
    availability: "In Stock",
    condition: "New",
    brand: "Bosch",
    display: "./display/boschsawzallcordless.png",
    images: ["../itemPage/boschsawzallcordless_1.jpg", "../itemPage/boschsawzallcordless_2.jpg"],
    description: ["Manufactured by Bosch Power Tools. Here is one of the most versitle and powerful battery operated 4 1⁄2” grinders on the market and at a great price. They carry a one year warranty through Bosch.", "This Bosch 18V 1-1/8 In. D-Handle Reciprocating Saw Kit comes with a powerful, compact saw and one (1) 4.0 Ah lithium batteries. The saw is only 17.7” long and 6.35 lbs. (tool only, not including battery), and it delivers an efficient 1-1/8” stroke, to remove more material per stroke. The saw's Lockjaw system allows for fast one-handed blade changes. It has two speed ranges to match the speed of the cut to the material. The Bosch CORE18V Compact Batteries are the next-gen Lithium-ion battery that keeps the power and runtime, while losing the weight. The kit includes:"],
    specs: ["CRS180 18V 1-1/8” D-Handle Reciprocating Saw", "18V-4.0 Ah Battery", "18V Fast Charger - BC1880", "Carrying Bag"],
    sizing: "One Size"
  },
  {
    _id: "3985",
    title: "Bosch Reciprocating Saw",
    genre: { _id: "111", name: "Power Tools" },
    availability: "In Stock",
    condition: "New",
    brand: "Bosch",
    display: "./display/boschsawzall.png",
    images: ["../itemPage/boschsawzall_1.jpg", "../itemPage/boschsawzall_2.jpg"],
    description: ["Manufactured by Bosch. This 12 amp Bosch reciprocating saw was designed to be the authority in all things cutting, sawing, and demolition. The combination of a hearty 12-amp motor with a compact and lightweight design result in a best in class power to weight ratio. This unit is loaded with power, precision, and design features that allow even the most extreme users to take on their biggest and most difficult projects. It is ideal for any user who can benefit from a high level of cutting and sawing power.", "Specifications:"],
    specs: ["Highest power-to-weight ratio - 12 Amp of power at only 7 lbs, the power-to-weight ratio is at the top of it’s class", "Ultra compact size - only 17.8 In. long allows easy cutting in challenging positions, especially overhead for less fatigue with light weight design", "Constant orbital action - fast cutting in all types of materials with orbital action feature", "Variable speed trigger - improves cutting control in various materials", "Fixed foot - provides robust and solid design for the toughest applications; no loose foot issues during use", "Soft ergonomic handle - maximum comfort and gripping during cutting", "Blade change system - most robust blade holder system for reliable and durable expectations"],
    sizing: "One Size"
  },
  {
    _id: "3939",
    title: "Husqvarna K770 Gas Powered Saw",
    genre: { _id: "111", name: "Power Tools" },
    availability: "In Stock",
    condition: "New",
    brand: "Husqvarna",
    display: "./display/husqy_saw.png",
    images: ["../itemPage/husqySaw_1.jpg", "../itemPage/husqySaw_2.jpg", "../itemPage/husqySaw_3.jpg"],
    description: ["Manufactured by Husqvarna. This saw is the perfect choice for construction workers and landscapers. The new K 770 is well-suited for cutting concrete and stone in construction work, and for making smaller openings for windows and doors. Also, it works well for cutting paving stones in park and garden work. One year warranty against manufactures defects.", "Specifications:"],
    specs: ["New, light magnesium blade guard", "New, stepless blade guard adjustment", "New progressive water valve", "New starter handle", "New digital ignition system", "X-Torq® (patented engine technology)", "New wet cutting system", " New stepless blade guard adjustment"],
    sizing: "One Size"
  },
  {
    _id: "3908",
    title: "2” Magnetic Core Drill System",
    genre: { _id: "111", name: "Power Tools" },
    availability: "In Stock",
    condition: "New",
    brand: "ProTool",
    display: "./display/coreDrill.png",
    images: ["../itemPage/coreDrill_1.jpg", "../itemPage/coreDrill_2.jpg"],
    description: ["This low profile magnetic core drill system is a product that anyone with any type of metal shop can use. It allows your customer to drill larger holes quickly and accurately in very thick steel beams or steel plates (3/8” to 2”). The unit features a reversible crank lever, a LED worklight to illuminate the work-surface, and a low profile design to allow access to tight spots not normally accessible with a magnetic drill. It also has a quick release chuck to make bit changing a snap. You do not use regular drill bits in this but a type of bit called an annular cutter. It comes in a plastic carrying case, includes shoulder strap for carrying, and carries a 90 day warranty against manufactures defects.", "Specifications:"],
    specs: ["Power – 1700 watts", "Voltage – 110v – 60 hz", "No Load/Full Load RPM – 660/100", "Magnetic Adhesion – 3500 lbs", "Diminsions – 14.25”H x 4”W x 9.25”D", "Net Weight – 28.6 Lbs"],
    sizing: "One Size"
  },
  {
    _id: "3980",
    title: "Dremel MM40 Multi-Max Osc. Tool",
    genre: { _id: "111", name: "Power Tools" },
    availability: "In Stock",
    condition: "New",
    brand: "Dremel",
    display: "./display/dremelmulti.png",
    images: ["../itemPage/dremelmulti_1.jpg"],
    description: ["Manufactured by Dremel through the Bosch Tool Group. Included with the tool a semi- circular wood and drywall blade, plus the sanding attachment with 11 sheets of velcro attachable sandpaper. Tool and accessories only, does not include case. One year warranty against manufactures defects.", "Specifications:"],
    specs: ["Powerful 3.8 amp motor—to help cut through tougher materials", "Quick Release - Integrated wrench for convenient accessory changes", "Variable speeds from 10,000 to 21,000 OPM—the widest speed range of any oscillating tool", "Quick release on-tool integrated wrench: Dremel MM40 users no longer have to worry about losing their wrench", "Fast speed of cut: the extra-wide 3.2 degree oscillation angle provides faster cutting and performance", "Wide variable speed range – from 10,000-21,000 OPM ", "7 Ft. durable rubber cord for a wide working range"],
    sizing: "One Size"
  },
  {
    _id: "4040",
    title: "Bosch 14” Abrasive Cut-Off Saw",
    genre: { _id: "111", name: "Power Tools" },
    availability: "In Stock",
    condition: "New",
    brand: "Bosch",
    display: "./display/boschchop.png",
    images: ["../itemPage/boschchop_1.jpg"],
    description: ["Manufactured by Bosch Power Tools. This 14” cut-off saw is pound for pound one of the best saws on the market. With a full 15 amps at 3900 rpm’s it will do even the toughest jobs with the greatest of ease.", "Specifications:"],
    specs: ["15 Amps, 3,900 RPM", "Superior motor insulation - extra motor over-load protection during tough applications", "Locking vise with quick release - holds awkward material stable for fast and efficient cutting", "Adjustable fence - adjusts for bevel cuts ranging from 0-45°", "Trigger w/lock-on"],
    sizing: "One Size"
  },
  {
    _id: "4032",
    title: "Bosch 18V PortaBand Saw",
    genre: { _id: "111", name: "Power Tools" },
    availability: "In Stock",
    condition: "New",
    brand: "Bosch",
    display: "./display/boschPortaband.png",
    images: ["../itemPage/boschPortaband_1.jpg", "../itemPage/boschPortaband_2.jpg"],
    description: ["Manufactured by Bosch Power Tools. Here is one of the most versitle and powerful battery operated porta-band saws on the market and at a great price. The kit includes the saw itself, one (1) battery, one (1) quick charger, and one (1) carrying bag. They carry a one year warranty through Bosch."],
    specs: ["This Bosch best-in-class sub-compact size porta-band saw is up to 60-percent lighter than deep cut saws and is up to 40-percent more compact.", "2-1/2-inch by 2-1/2-inch cutting capacity.", "Ideal for working in tight spaces or overhead cutting.", "Integrated LED Work Light: Illuminates the cut line in dark areas", "It has two speed ranges to match the speed of the cut to the material."],
    sizing: "One Size"
  },
  {
    _id: "4041",
    title: "Bosch 1-1/8” Breaker Hammer",
    genre: { _id: "111", name: "Power Tools" },
    availability: "In Stock",
    condition: "New",
    brand: "Bosch",
    display: "./display/boschHammer.png",
    images: ["../itemPage/boschHammer_1.jpg", "../itemPage/boschHammer_2.jpg"],
    description: ["Manufactured by Bosch Power Tools. This is what is commonly known as a 35 lb hammer. The nice thing about this model is that it runs full power on a normal 120V 15 amp circuit. It takes the hex shank chisels (our stock #’s 4010 and 4011), and will do just about any job asked of it. One year warranty by Bosch against manufactures defects.", "Specifications:"],
    specs: ["Impact force of 34 ft-lbs. and powerful 1,750 Watt motor", "Fastest removal rate in its class - up to 35% faster", "Exclusive active vibration control - shock absorbing top handle reduces vibration by up to 40%", "In-line design - ultimate tool balance with multiple grip positions for maximum performance in angled, horizontal, and vertical applications", "Large main handle - increased control and handling", "360 degree auxiliary handle - provides a wide range of movement along with operator comfort", "Well positioned lock on switch - eliminates unintentional shut off", "2-way tool retainer - accepts standard 1-1/8” (28mm) hex chisels", "All metal housing - jobsite durability", "Carrying case with wheels - convenient storage and easy transportation"],
    sizing: "One Size"
  },
  {
    _id: "4035",
    title: "Bosch 18V 6 1/2” Circular Saw Kit",
    genre: { _id: "111", name: "Power Tools" },
    availability: "In Stock",
    condition: "New",
    brand: "Bosch",
    display: "./display/boschCircular.png",
    images: ["../itemPage/boschCircular_1.jpg", "../itemPage/boschCircular_2.jpg"],
    description: ["Manufactured by Bosch Power Tools. The Bosch 18 V 6 1⁄2” circular saw can easily cut through 2x (“two by”) material across entire bevel range. This cordless saw provides a convenient 50 degree bevel range, which is handy for times when quick bevel cuts are called for in a difficult situations. The easy-to-read depth-of-cut gauge and bevel scale help make accurate cuts simple. Its ergonomic main handle that is angled for ease in pushing the saw forward. The saw includes a simple depth gauge and bevel scale, a dust blower to help keep the cut line visible, and an anti-snag lower guard to ease into the cut. This balanced saw weighs only 6.6 Lbs. (tool only, not including battery). This is a complete kit; saw, 4.0 ah battery, quick charger and carrying bag."],
    specs: ["6 1⁄2” blade – completely cuts through 2” at 90° and cuts through 2x (“two by”) material across entire bevel range", "Blade-left design – provides best view of cut line when main handle is held in right hand", "Heavy-duty aluminum foot and aluminum upper guard on saw – for enhanced tool durability", "Electronic saw brake – stops blade quickly for greater productivity", "Easy-to-read depth-of-cut gauge and bevel scale – for quick and accurate cuts", "Ambidextrous lock-off release button on saw – helps prevent unintended starts", "Cutting-edge battery cell design – engineered to improve performance in tools used on the jobsite all day"],
    sizing: "One Size"
  },
  {
    _id: "4023",
    title: "Bosch 18V 1/2” Hammer Drill/Driver Kit",
    genre: { _id: "111", name: "Power Tools" },
    availability: "In Stock",
    condition: "New",
    brand: "Bosch",
    display: "./display/boschDrill.png",
    images: ["../itemPage/boschDrill_1.jpg"],
    description: ["Manufactured by Bosch Power Tools. This is Bosch’s hottest new 18V hammer drill/driver with their new brushless technology. It is made to tackle demanding materials and applications (such as metal or masonry) anywhere on a worksite. It is a powerful 18V hammer drill/driver, which features Rapid Mode Selection, to switch among hammer mode, drilling mode and driving mode, engaging a clutch with 20 precision torque settings, for reduced fastener damage. Its efficient EC Brushless motor delivers outstanding runtime and longer motor life. One year warranty against manufactures defects."],
    specs: ["Drill features variable speed trigger with a two-speed gearbox", "It is designed for high-torque driving (0-600 rpm or high speed drilling (0-1900 rpm)", "Upgraded all-metal chuck for great bit hold and torque transfer", "It features Bosch’s Electronic Motor Protection system", "LED light for work illumination and a belt clip", "Includes drill/driver, 2.0 Ah Slim Pack battery and an 18V charger"],
    sizing: "One Size"
  },
  {
    _id: "4100",
    title: "20vlt Cordless Impact Wrench",
    genre: { _id: "111", name: "Power Tools" },
    availability: "In Stock",
    condition: "New",
    brand: "NTI",
    display: "./display/neikoimpact.png",
    images: ["../itemPage/neikoimpact_1.jpg"],
    description: ["This is a great tool for anyone needing portable power to run lag bolts or lug nuts. It produces a 300 ft/lbs of torque which is great for an electric impact wrench, much less a battery operated one. The tool includes charger, one lithion-ion battery, 4 of the most popular sockets, belt hook, and a carrying case. 90 day warranty against manufactures defects."],
    specs: ["1⁄2” Drive with 20V Powerful Motor", "Battery: 4.0 Li-Ion Battery", "Max. Torqure: 300 ft.lb max torque", "No-Load Speed:0~3,000rpm", "Impact Times:0~3,500bpm", "Reversible"],
    sizing: "One Size"
  },
  {
    _id: "3850",
    title: "Black Oxide Combination Wrenches",
    genre: { _id: "112", name: "Wrenches" },
    availability: "In Stock",
    condition: "New",
    brand: "NTI",
    display: "./display/blackoxide.png",
    images: ["../itemPage/blackoxide_1.jpg", "../itemPage/blackoxide_2.jpg"],
    description: ["This is truly a professional quality or mechanics quality set of combination wrenches. They feature the black oxide finish for longer durability and corrosion resistance. Any mechanic will be proud to have these tools in his tool box. Each set comes in a heavy pouch and carry a life time warranty against manufacters defects."],
    sizing: "Standard sets run from 1/4” to 1 1/4” and Metric from 6 to 32 MM"
  },
  {
    _id: "3718",
    title: "Long Pattern Combination Wrenches",
    genre: { _id: "112", name: "Wrenches" },
    availability: "In Stock",
    condition: "New",
    brand: "NTI",
    display: "./display/longpattern.png",
    images: ["../itemPage/longpattern_1.jpg"],
    description: ["This is as professional quality set of wrenches as anything you can buy that is American made. They carry a one year warranty against manufactures defects.", "Specifications:"],
    specs: ["Drop Forged, Heat Treated Steel", "Fully Polished and Chrome Plated", "High Polish Finish Resists Corrosion", "Precision Machined Open and Boxed End", "Stored in Heavy Duty Pouch"],
    sizing: "Standard sets run from 3/8” to 1 1/4” and Metric from 10 to 32 MM"
  },
  {
    _id: "1402",
    title: "Chrome Combination Wrenches",
    genre: { _id: "112", name: "Wrenches" },
    availability: "In Stock",
    condition: "New",
    brand: "NTI",
    display: "./display/chromewrench.png",
    images: ["../itemPage/chromewrench_1.jpg"],
    description: ["This is a professional quality set of combination (open and box end) wrenches. They are made of chrome vanadium steel and carry a full polish on the handle. They come in a vinyl roll up pouch. The smallest wrench is approximately 5 1/2 inches long and the big wrench is 16 inches in length. Lifetime replacement warranty."],
    sizing: "Standard sets run from 3/8” to 1 1/4” and Metric from 9 to 32 MM"
  },
  {
    _id: "1403",
    title: "Jumbo Combination Wrenches",
    genre: { _id: "112", name: "Wrenches" },
    availability: "In Stock",
    condition: "New",
    brand: "NTI",
    display: "./display/wrenchJumbo.png",
    images: ["../itemPage/wrenchJumbo_1.jpg"],
    description: ["This is a professional quality set of combination (open and box end) wrenches. They are made of chrome vanadium steel and carry a full polish on the handle. They come in a vinyl roll up pouch. The smallest wrench is approximately 5 1/2 inches long and the big wrench is 16 inches in length. Lifetime replacement warranty."],
    sizing: "Standard sets run from 1 3/8” to 2”"
  },
  {
    _id: "1730",
    title: "Stubby Combination Wrenches",
    genre: { _id: "112", name: "Wrenches" },
    availability: "In Stock",
    condition: "New",
    brand: "NTI",
    display: "./display/wrenchStubby.png",
    images: ["../itemPage/wrenchStubby_1.jpg"],
    description: ["This is a top quality import set of stubby combination wrenches. They are extremely useful for getting into tight places that a normal length combination wrench will not. These wrenches are made of a forged alloy steel, heat treated, and chrome plated with a velvet finish. The smallest wrench is 4” in length and the biggest wrench is 5 3⁄4” in length. All wrenches have a lifetime warranty."],
    sizing: "Standard sets run from 7/16” to 1” and Metric from 8 to 19 MM"
  },
  {
    _id: "3858",
    title: "Flex-Head Gear Wrenches",
    genre: { _id: "112", name: "Wrenches" },
    availability: "In Stock",
    condition: "New",
    brand: "Tooluxe",
    display: "./display/ratchetwrench.png",
    images: ["../itemPage/ratchetwrench_1.jpg"],
    description: ["This is a mechanics quality set of flex-head gear wrenches. Any mechanic will be proud to have this product in his tool box. The pivoting flex head allows for working in super tight clearance situations and allows the mechanic to do jobs quickly and efficiently that otherwise would be almost impossible. Sets come in vinyl pouches and carry a one year warranty against manufactures defects.", "Specifications:"],
    specs: ["Heat treated Chrome-vanadium steel with polished mirror finish", "72 tooth ratchet gear end", "Pivoting Head that locks into place for getting around those tight corners"],
    sizing: "Standard sets run from 3/8” to 1” and Metric from 10 to 25 MM"
  },
  {
    _id: "1447",
    title: "Hex Key Sets",
    genre: { _id: "112", name: "Wrenches" },
    availability: "In Stock",
    condition: "New",
    brand: "Tooluxe",
    display: "./display/allen.png",
    images: ["../itemPage/allen_1.jpg"],
    description: ["This is a professional quality set of hex keys (Allen wrenches). Half of the set is Sae and the other half is Metric. They are chrome vanadium steel with a black pentrate finish. The sae sizes have an extra long handle and the metric sizes have a standard length handle so they are easily differentiated. They come in a plastic pouch for storage purposes with each size labeled. Lifetime guarantee."],
    sizing: "One Set that Contains Standard Sizes from 1/16” to 3/8” and Metric from .05 to 10 MM"
  },
  {
    _id: "1449",
    title: "T-Handle Hex Key Set",
    genre: { _id: "112", name: "Wrenches" },
    availability: "In Stock",
    condition: "New",
    brand: "Tooluxe",
    display: "./display/tHandle.png",
    images: ["../itemPage/tHandle_1.jpg"],
    description: ["This is a professional quality set of hex keys (Allen wrenches) with a “T” type of handle. The “T” handle is designed to be user friendly and will give greater turning power with greater torque than a standard “L” shaped hex key. This set features chrome vanadium steel shanks with a plastic unbreakable handle.", "The shortest wrench is 5” long and the biggest one is 7 1⁄4” long. The set comes in a metal rack for organizational and storage purposes. One year warranty against manufactures defects."],
    sizing: "There are ten wrenches in the set (3/32”, 7/64”, 1/8”, 9/64”, 5/32”, 3/16”, 7/32”, 1/4”, 5/16”, and 3/8”). "
  },
  {
    _id: "1423",
    title: "Adjustable Wrench Set",
    genre: { _id: "112", name: "Wrenches" },
    availability: "In Stock",
    condition: "New",
    brand: "NTI",
    display: "./display/adjustable.png",
    images: ["../itemPage/adjustable_1.jpg"],
    description: ["These are professional quality adjustable wrenches. They are made of chrome vanadium steel and are triple chrome plated. The jaws and adjusting track are machined and milled for close tolerances. This keeps the wrench from turning into a knuckle buster. They are economically priced for the customer that loses wrenches and doesn't want to spend a fortune replacing them. Lifetime guarantee"],
    sizing: "7 pc set: 6”, 8”, 10”, 12”, 15”, 18” & 24” "
  },
  {
    _id: "1455",
    title: "Aluminum Pipe Wrenches",
    genre: { _id: "112", name: "Wrenches" },
    availability: "In Stock",
    condition: "New",
    brand: "NTI",
    display: "./display/aluminumpipe.png",
    images: ["../itemPage/aluminumpipe_1.jpg"],
    description: ["This is a professional quality aluminum alloy pipe wrench preferred by industrial users. They are 1/3 the weight of a similar size steel wrench, which makes them easy to use. They are silver in color with steel jaws that are hardened and tempered. The aluminum alloy does not conduct heat, cold, or electricity like a steel wrench will. Each wrench carries a lifetime warranty except with the use of a cheater pipe. "],
    sizing: "Available in 14”, 18”, 24”, 36” & 48” "
  },
  {
    _id: "1738",
    title: "Ratchet Chain Wrench",
    genre: { _id: "112", name: "Wrenches" },
    availability: "In Stock",
    condition: "New",
    brand: "NTI",
    display: "./display/chainwrench.png",
    images: ["../itemPage/chainwrench_1.jpg"],
    description: ["This ratchet chain wrench is used by most plumbers, heavy equipment operators and oil field contractors. It is designed to grip the entire circumference of a pipe or hydraulic line. Lifetime warranty against manufactures defects only.", "Specifications:"],
    specs: ["Chrome Vanadium steel handle for strength & durability", "Tempered heel jaw for a slip-proof grip", "Cushioned handle for operator comfort", "Velvet finish"],
    sizing: "Available in 20” Handle w/ 24” Chain"
  },
  {
    _id: "1733",
    title: "1⁄2” Drive Crowfoot Wrench Sets",
    genre: { _id: "112", name: "Wrenches" },
    availability: "In Stock",
    condition: "New",
    brand: "NTI",
    display: "./display/crowsfoot.png",
    images: ["../itemPage/crowsfoot_1.jpg"],
    description: ["This set of jumbo crowfoot wrenches are made of heat treated drop forged chrome vanadium steel with a black oxide pentrate finish. They are used for getting into tight spots, especially around tubing such as hydraulic lines etc. It is an fourteen piece set and is used with a 1/2 inch drive ratchet. Lifetime warranty against manufactures defects."],
    sizing: "Standard sets run from 1 1/16” to 2” and Metric from 27 to 50 MM"
  },
  {
    _id: "1369",
    title: "Duometric Impact Set",
    genre: { _id: "113", name: "Sockets" },
    availability: "In Stock",
    condition: "New",
    brand: "Patriot",
    display: "./display/duometric.png",
    images: ["../itemPage/duometric_1.jpg", "../itemPage/duometric_2.jpg"],
    description: ["This ratchet chain wrench is used by most plumbers, heavy equipment operators and oil field contractors. It is designed to grip the entire circumference of a pipe or hydraulic line. Lifetime warranty against manufactures defects only.This product features a “wall drive” system, which is the hottest thing in years in impact socket wrench design. This unique “wall drive” design of the socket grips on the side (wall) of the fastener head instead of on the corners.","This offers 3 major benefits:"],
    specs: ["Up to 30% more torque can be applied.", "Slippage is prevented and damage to corners of fastener is avoided.", "Wall drive sockets will fit fasteners that have been “burred-over” at the corners. Duometric socket selection means that no imperial size socket is included where there is a metric equivalent. This method gives maximum coverage of size with the minumum of sockets & therefore more fastener size coverage for your money."],
    sizing: "Covers Both Standard from 3/8” to 1 1/4” and Metric from 8 to 32 MM in Deep and Shallow"
  },
  {
    _id: "3847",
    title: "1” Drive Impact Socket - Complete Set",
    genre: { _id: "113", name: "Sockets" },
    availability: "In Stock",
    condition: "New",
    brand: "NTI",
    display: "./display/oneinchsockets.png",
    images: ["../itemPage/oneinchsockets_1.jpg"],
    description: ["This is a professional quality set for customers who use the bigger size of sockets. All pieces are black impact steel and and are designed for the toughest of jobs. Included in the set are 16 - 6 point sockets, a 25 inch long reversible ratchet, 20 inch sliding T breaker bar, 8 inch and 18 inch extension bars, and a heavy duty carrying case. Lifetime guarantee."],
    sizing: "Available in Standard from 1 5/8” to 3 1/8” "
  },
  {
    _id: "1350",
    title: "3/4” Drive Impact Socket - Complete Set",
    genre: { _id: "113", name: "Sockets" },
    availability: "In Stock",
    condition: "New",
    brand: "NTI",
    display: "./display/threequarterimpact.png",
    images: ["../itemPage/threequarterimpact_1.jpg"],
    description: ["This is a professional quality set for customers who use the bigger size of sockets. Since the sockets are impact rated they can be used manually with a ratchet or with an impact wrench. They are chrome vanadium steel with the black pentrate finish. Included in the set are 16 - 6 point sockets, a 20 inch long reversible ratchet, 18 inch sliding T breaker bar, 4 inch and 8 inch extension bars and a heavy duty carrying case. Lifetime guarantee."],
    sizing: "Available in Standard from 7/8” to 2” in either Deep or Shallow "
  },
  {
    _id: "1364",
    title: "1/2” Drive Universal Joint Socket",
    genre: { _id: "113", name: "Sockets" },
    availability: "In Stock",
    condition: "New",
    brand: "NTI",
    display: "./display/ujoint.png",
    images: ["../itemPage/ujoint_1.jpg"],
    description: ["Each set consists of 7 sockets and a carrying case. These are impact sockets, also known as universal power sockets. Each socket sets on it own universal joint, the sockets are professional quality black finish, heat treated chrome vanadium steel. Lifetime replacement warranty."],
    sizing: "Available in Standard from 1/2” to 7/8” or Metric from 13 to 22 MM"
  },
  {
    _id: "3705",
    title: "Multi-Drive Imp. Adapter Set",
    genre: { _id: "113", name: "Sockets" },
    availability: "In Stock",
    condition: "New",
    brand: "Stark",
    display: "./display/impactadapt.png",
    images: ["../itemPage/impactadapt_1.jpg"],
    description: ["This is a master set of impact adapters/reducers. It has everything in it a mechanic could possibly need. They are made of hardened chrome-vanadium steel for superior strength, and have the black oxide finish for corrosion resistance."],
    sizing: "Gives All Reducers and Adapters to Switch Between All Drives"
  },
  {
    _id: "1344",
    title: "Hex Bit Socket Set",
    genre: { _id: "113", name: "Sockets" },
    availability: "In Stock",
    condition: "New",
    brand: "NTI",
    display: "./display/hexkey.png",
    images: ["../itemPage/hexkey_1.jpg"],
    description: ["This is an industrial quality set of hex wrenches that attach to a ratchet to be used. They are much faster to use than the standard “L” handle or “T” handle hex bit wrench sets. The set features hex bits in both the 3/8” and 1/2” drives. They are made of chrome vanadium steel and feature a lifetime replacement guarantee. Each set is packaged in a metal box for storage purposes."],
    sizing: "Available in Standard from 1/8” to 5/8” or Metric from 3 to 16 MM"
  },
  {
    _id: "3699",
    title: "E-Star Socket Set",
    genre: { _id: "113", name: "Sockets" },
    availability: "In Stock",
    condition: "New",
    brand: "NTI",
    display: "./display/estar.png",
    images: ["../itemPage/estar_1.jpg"],
    description: ["This is a master E-Star socket set that you don’t use every day but when you need this type of product it is nice to have a set that includes every possible option. It is a 60 pc master set and comes in a plastic blowmold case. All pieces are made of chrome-vanadium steel for strength and durability. One year warranty against manufactures defects."],
    sizing: "Covers All Internal and External E and T sizes from 4 to 70"
  },
  {
    _id: "1385",
    title: "3/4” Drive Chrome Socket Sets",
    genre: { _id: "113", name: "Sockets" },
    availability: "In Stock",
    condition: "New",
    brand: "NTI",
    display: "./display/threequarterchrome.png",
    images: ["../itemPage/threequarterchrome_1.jpg"],
    description: ["This is a professional quality set for customers who use the bigger size of sockets. All pieces are chrome vanandium steel and are triple chrome plated. Included in the set are 16 - 6 point sockets, a 19 1⁄2 inch long reversible ratchet, 20 inch breaker bar, 4 inch and 8 inch extension bars and a heavy duty carrying case. Lifetime guarantee."],
    sizing: "Available in Standard from 7/8” to 2” or Metric from 19 to 50 MM"
  },
  {
    _id: "1399",
    title: "1” Lug Nut Torque Multiplier",
    genre: { _id: "113", name: "Sockets" },
    availability: "In Stock",
    condition: "New",
    brand: "NTI",
    display: "./display/nutbuster.png",
    images: ["../itemPage/nutbuster_1.jpg"],
    description: ["This is an awesome item for any trucker, farmer, heavy equipment operator etc. It allows them to easily break the lug nuts on the wheels of large trucks, heavy equipment, buses, RV’s etc. It is smaller and more efficient than a 1” impact wrench and doesn’t need an air compressor to power it. Included in the kit is the torque multiplier itself, the crank handle, an extension bar and four 1” drive sockets (21mm, 33mm(1-3/8), 38mm(1-1/2”), and 41mm). This unit will put out a whopping 3540 ft/lbs of torque that will break any frozen, rusted, or overtightened lug nut with ease. One year warranty against manufactures defects."],
    sizing: "One Size"
  },
  {
    _id: "1506",
    title: "Spiral Screw Extractor",
    genre: { _id: "114", name: "Extractors & Threaders" },
    availability: "In Stock",
    condition: "New",
    brand: "Desco",
    display: "./display/extractorspiral.png",
    images: ["../itemPage/extractorspiral_1.jpg"],
    description: ["Made in USA. Used for removing broken screws, bolts, & pipes. They are left handed spiral tapered flute with the correct drill size stamped on each extractor. They come in a hand finished, stained, hardwood block the set is designed for using on bolts and screws up to 2 inch, and pipe up to 1 inch."],
    sizing: "Number 1 through Number 9 Extractors"
  },
  {
    _id: "1507",
    title: "Square Flute Screw Extractor",
    genre: { _id: "114", name: "Extractors & Threaders" },
    availability: "In Stock",
    condition: "New",
    brand: "Desco",
    display: "./display/extractorsquare.png",
    images: ["../itemPage/extractorsquare_1.jpg"],
    description: ["Made in USA. Used for removing broken screws, bolts, & pipes. They have a straight tapered flute with the correct drill size stamped on each extractor. They come in a hand finished, stained, hardwood block the set is designed for using on bolts and screws up to 1 1/8 inch, and pipe up to 1/2 inch."],
    sizing: "Number 1 through Number 7 Extractors"
  },
  {
    _id: "1656",
    title: "Hex Titanium Tap & Die Set",
    genre: { _id: "114", name: "Extractors & Threaders" },
    availability: "In Stock",
    condition: "New",
    brand: "NTI",
    display: "./display/hexdie.png",
    images: ["../itemPage/hexdie_1.jpg"],
    description: ["These sets include both fine & coarse threaded taps & dies in SAE and Metric sizing. They are made from a professional grade set in special high-carbon alloy steel for excellent strength and durability. The sets include: 17 taps and 17 dies, 1-die handle, 1-tap wrench, 1-screw-pitch gauge, and 1-screwdriver. They feature titanium cutting edges which will hold sharpness up to 20 times longer than standard steel"],
    sizing: "Available in Standard from 1/8” to 1/2” and Metric from 3 to 12 MM"
  },
  {
    _id: "1650",
    title: "Tap & Die Sets",
    genre: { _id: "114", name: "Extractors & Threaders" },
    availability: "In Stock",
    condition: "New",
    brand: "NTI",
    display: "./display/tapdie.png",
    images: ["../itemPage/tapdie_1.jpg"],
    description: ["This set contains both Sae national coarse and national fine taps and dies. It is made of industrial quality high-speed tungsten steel. Besides 20 taps and 20 dies, this set includes a large screw type tap wrench, a T type tap wrench, a large die wrench with a 1/2 inch adapter (for smaller dies), a screwdriver and a either a plastic or metal case. All dies are round in design and are adjustable. The set carries a lifetime warranty against manufactures defects. Sizes include one tap and one die of each size."],
    sizing: "Available in Standard from 1/4” to 1” and Metric from 6 to 24 MM"
  },
  {
    _id: "1649",
    title: "Titanium Tap & Die Set",
    genre: { _id: "114", name: "Extractors & Threaders" },
    availability: "In Stock",
    condition: "New",
    brand: "NTI",
    display: "./display/tapdietitanium.png",
    images: ["../itemPage/tapdietitanium_1.jpg"],
    description: ["This is a professional set of tungsten steel taps & dies that have the gold titanium coating on them. The titanium coating will last 50 to 100 times longer than standard cutting edges.", "The set includes 20 taps and 20 dies, a large screw type tap wrench, a t-type tap wrench, a large die wrench with a 1⁄2” adapter (for smaller dies), a screwdriver and a plastic blow molded carrying case. All dies are round in design and are adjustable. The set carries a lifetime warranty against manufactures defects."],
    sizing: "Available in Standard from 1/4” to 1” and Metric from 6 to 24 MM"
  },
  {
    _id: "1655",
    title: "Master Screw Extractor/Drill & Guide Set",
    genre: { _id: "114", name: "Extractors & Threaders" },
    availability: "In Stock",
    condition: "New",
    brand: "NTI",
    display: "./display/extractorsetbig.png",
    images: ["../itemPage/extractorsetbig_1.jpg"],
    description: ["This is a master set of screw extractors and drill guides. It contains absolutely everything a person can need for removing broken screws, bolts, studs, pipes, set screws, alemite fittings and lugs without breaking or damaging. It can be used on screws and bolts from approximately 3/16” to 7/8”. The set comes in an aluminum carrying case.", "Set Includes:"],
    specs: ["16 pcs – Left-Handed Drill Bits (5/64” to 1/2”)", "6 pcs – Extractor Pins (#1 thru #6)", "13 pcs – Multi-Spline Screw Extractors (1/8” to 1/2”)", "10 pcs – Drill Guides (#1 thru #10)", "5 pcs – Extractor Nuts (#1 thru #5)"],
    sizing: "Everything Included Above"
  },
  {
    _id: "1459",
    title: "Drop Head Pipe Threader",
    genre: { _id: "114", name: "Extractors & Threaders" },
    availability: "In Stock",
    condition: "New",
    brand: "NTI",
    display: "./display/pipethread.png",
    images: ["../itemPage/pipethread_1.jpg"],
    description: ["This is a professional quality kit that includes 5 of the most popular sizes of pipe dies from 1/4” to 1”. It is used for threading pipes and is used by plumbers, contractors, farmers, maintenance people etc. It carries a one-year warranty against manufactures defects. Included in the set are the following pieces:", "Set Includes:"],
    specs: ["1 – Enclosed ratchet head (reversible)", "1 – 19” Extension bar or handle (attaches to ratchet head)", "Heavy duty NPT dies (1/4”, 3/8”, 1/2”, 3/4” and 1”)", "1 – Heavy duty metal case"],
    sizing: "Everything Included Above"
  },
  {
    _id: "1567",
    title: "Drill & Tap Set",
    genre: { _id: "114", name: "Extractors & Threaders" },
    availability: "In Stock",
    condition: "New",
    brand: "NTI",
    display: "./display/tapdrill.png",
    images: ["../itemPage/tapdrill_1.jpg"],
    description: ["Made in USA. This is a super handy combination HSS tap and drill set. It organizes taps with the recommended twist drill sizes. They are ideal for tool cribs, machine shops and maintenance departments. Also, any farmer, logger, trucker, excavating contractor that runs heavy equipment will use something like this. The set comes in a Huot Index Box for storage purposes. One year warranty against defects only."],
    specs: ["Taps: 6-32, 8-32, 10-24, 10-32, 1/4-20, 5/16-18, 3/8-16, 7/16-14, 1/2-13", "Drills: #36, #29, #25, #21, #7, F, 5/16, U, 27/64"],
    sizing: "Everything Included Above "
  },
  {
    _id: "3878",
    title: "Long Reach Pliers",
    genre: { _id: "115", name: "Pliers & Clamps" },
    availability: "In Stock",
    condition: "New",
    brand: "NTI",
    display: "./display/longreach.png",
    images: ["../itemPage/longreach_1.jpg"],
    description: ["This is a must have plier set for any farmer, trucker, mechanic etc. They are especially useful retrieving small items (nuts, bolts, washers etc) from hard to reach places. The set gives a wide assortment for almost any situation. They come in a canvas pouch for storage and carry a lifetime warranty against manufactures defects.", "Specifications:"],
    specs: ["Drop forged, heat treated", "Fully polished", "Super long reach design for work in confined areas", "Non-slip PVC dipped cushion grip handles", "11 inches long reach pliers - Big circle nose, Middle circle nose, Small circle nose, 90 degree needle nose, 45 degree needle nose, Straight needle nose, Flat nose, Power diagonal cutting nose, S shape nose"],
    sizing: "Everything Included Above "
  },
  {
    _id: "1645",
    title: "Heavy Duty C-Clamp Set",
    genre: { _id: "115", name: "Pliers & Clamps" },
    availability: "In Stock",
    condition: "New",
    brand: "NTI",
    display: "./display/cclamp.png",
    images: ["../itemPage/cclamp_1.jpg"],
    description: ["This is a heavy-duty set of “C” clamps used in all metal fabrication, welding shops, and many other industrial applications. The clamps feature a malleable body with a steel screw. The body is reinforced to prevent bending when used in heavy-duty applications. Clamps feature a limited lifetime warranty against defects in materials."],
    sizing: "Includes 6”, 8”,10” and 12” Clamps "
  },
  {
    _id: "1432",
    title: "Punch and Chisel Set",
    genre: { _id: "115", name: "Pliers & Clamps" },
    availability: "In Stock",
    condition: "New",
    brand: "NTI",
    display: "./display/punchchisel.png",
    images: ["../itemPage/punchchisel_1.jpg"],
    description: ["This 12-piece punch and chisel set is designed to withstand the pressures of professional, constant use. It is made of the same type of heavy-duty steel as impact sockets. They come in a heavy duty canvas pouch and carry a one year warranty against manufactures defects.", "Included in the set:"],
    specs: [" 1 – Pin Punch – 3/8” x 8”", "2 – Centre Punches: 5/32” x 6”, 7/32” x 8”", "3 – Taper Punches: 1/8” x 8”, 7/32” x 10”, 3/8” x 10”", "6 – Cold Chisels: 1⁄2” x 6”, 5/8” x 7”, 3⁄4” x 8”, 7/8” x 8”, 7/8” x 10”, 1” x 10”"],
    sizing: "Everything Included Above  "
  },
  {
    _id: "1683",
    title: "Channellock Cable Cutters",
    genre: { _id: "115", name: "Pliers & Clamps" },
    availability: "In Stock",
    condition: "New",
    brand: "Channellock",
    display: "./display/channellockcut.png",
    images: ["../itemPage/channellockcut_1.jpg"],
    description: ["Made in USA. This is a professional quality cable cutter designed for either copper or aluminum cable (this unit not for steel cable). It will handle up to 4/0 aluminum and up to 2/0 soft copper. This unit is 9 1⁄2” long and designed for maximum cutting force with minimum leverage. Jaws are made of a special grade of high carbon steel with induction-hardened cutting edges for accurate cuts with minimal cable distortion. Lifetime guarantee against defects in materials or workmanship."],
    sizing: "One Size "
  },
  {
    _id: "1678",
    title: "Channellock Water Pump Pliers",
    genre: { _id: "115", name: "Pliers & Clamps" },
    availability: "In Stock",
    condition: "New",
    brand: "Channellock",
    display: "./display/channellockpump.png",
    images: ["../itemPage/channellockpump_1.jpg"],
    description: ["Made in the USA. These are the Channellock brand tongue and groove pliers. They have the patented undercut tongue and groove design that assures the strongest, can't slip jaw action as well as the patented reinforcing edge that minimizes stress breakage. They are built with Channellock’s “Perma-Lock” fastener providing stronger, safer, and smoother workings than the old time bolt and nut. Each pair of pliers are supplied with a distinctive dipped blue plastic comfort grip handle. Lifetime warranty against defects in material and workmanship!"],
    sizing: "Available in 6” to 20” Sizing"
  },
  {
    _id: "1688",
    title: "Channellock Oil Filter Pliers",
    genre: { _id: "115", name: "Pliers & Clamps" },
    availability: "In Stock",
    condition: "New",
    brand: "Channellock",
    display: "./display/channellockoil.png",
    images: ["../itemPage/channellockoil_1.jpg"],
    description: ["Made in USA. These are professional quality Channellock pliers designed to remove oil filters. They are made of a high carbon hot drop forged steel, and have right angle teeth to grip in all directions. They feature Channellocks PermaLock Fastener to eliminate nut and bolt failure, and of course have the Channellock Blue comfort grips. Lifetime guarantee against defects in materials or workmanship."],
    sizing: "Available in 12” or 15” Sizing"
  },
  {
    _id: "1692",
    title: "Channellock Snap Ring Pliers",
    genre: { _id: "115", name: "Pliers & Clamps" },
    availability: "In Stock",
    condition: "New",
    brand: "Channellock",
    display: "./display/channellocksnap.png",
    images: ["../itemPage/channellocksnap_1.jpg"],
    description: ["Made in USA. These are professional quality Channellock retaining ring or snap ring pliers and are designed for internal or external rings. They are made of a high carbon hot drop forged steel, are approximately 6 1⁄2” long, and of course have the Channellock Blue comfort grips. Lifetime guarantee against defects in materials or workmanship.", "Features include:"],
    specs: ["Capacity 1/8-inch to 1-inch external, 1/4-inch to 1-inch internal", "Includes tips: .023, .036, .047 straight tips and .036 and .047 ninety degree offset tips", "Converts from internal to external rings with a simple switch of the red tab", "Heavy duty return spring", "Heat treated for longer life"],
    sizing: "One Size"
  },
  {
    _id: "1707",
    title: "Channellock Long Nose Pliers",
    genre: { _id: "115", name: "Pliers & Clamps" },
    availability: "In Stock",
    condition: "New",
    brand: "Channellock",
    display: "./display/channellocklong.png",
    images: ["../itemPage/channellocklong_1.jpg"],
    description: ["Made in USA. They are made of a high carbon hot drop forged steel, are approximately 8” long, and of course have the Channellock Blue comfort grips. Lifetime guarantee against defects in materials or workmanship.", "Features include:"],
    specs: ["Side Cutter", "Crosshatch pattern on jaws grips work tightly", "CHANNELOCK's knife and anvil style cutting edge ensures perfect mating and superior cutting edge life", "High-carbon C1080 steel for superior performance on the job and electronic coating for ultimate rust prevention"],
    sizing: "One Size"
  },
  {
    _id: "1708",
    title: "Channellock Slip Joint Pliers",
    genre: { _id: "115", name: "Pliers & Clamps" },
    availability: "In Stock",
    condition: "New",
    brand: "Channellock",
    display: "./display/channellockslip.png",
    images: ["../itemPage/channellockslip_1.jpg"],
    description: ["Made in USA. They are made of a high carbon hot drop forged steel, are approximately 8” long, and of course have the Channellock Blue comfort grips. Lifetime guarantee against defects in materials or workmanship.", "Features include:"],
    specs: ["Wire cutting shear", "Serrated Jaw for a firm grip", "PermaLock fastener eliminates nut and bolt failure", "High-carbon C1080 steel for superior performance on the job and electronic coating for ultimate rust prevention"],
    sizing: "One Size"
  },
  {
    _id: "1709",
    title: "Channellock Concrete Nippers",
    genre: { _id: "115", name: "Pliers & Clamps" },
    availability: "In Stock",
    condition: "New",
    brand: "Channellock",
    display: "./display/channellocknip.png",
    images: ["../itemPage/channellocknip_1.jpg"],
    description: ["Made in USA. They are made of a high carbon hot drop forged steel, are approximately 10” long, and of course have the Channellock Blue comfort grips. Lifetime guarantee against defects in materials or workmanship.", "Features include:"],
    specs: ["Cutting capabilities include hard wire (0.047”  - 0.070”), medium hard wire (0.047” - 0.091” ) and soft wire (0.162”  max diameter)", "Site Hardened Edges for Durability", "CHANNELOCK's knife and anvil style cutting edge ensures perfect mating and superior cutting edge life", "High-carbon C1080 steel for superior performance on the job and specially coated for ultimate rust prevention"],
    sizing: "One Size"
  },
  {
    _id: "1670",
    title: "Channellock Welders Plier",
    genre: { _id: "115", name: "Pliers & Clamps" },
    availability: "In Stock",
    condition: "New",
    brand: "Channellock",
    display: "./display/channellockweld.png",
    images: ["../itemPage/channellockweld_1.jpg"],
    description: ["Made in USA. They are made of a high carbon hot drop forged steel, are approximately 9” long, and of course have the Channellock Blue comfort grips. Lifetime guarantee against defects in materials or workmanship.", "Features include:"],
    specs: ["Spring loaded", "Groove Nose design for superior splatter removal", "Jaws designed to grip and draw out wire", "Precision machined laster heat treated cutting edge", "Flats on either side for hammmering"],
    sizing: "One Size"
  },
  {
    _id: "3801",
    title: "Williams 95 pc Socket Set - Sae",
    genre: { _id: "116", name: "Williams Tools" },
    availability: "In Stock",
    condition: "New",
    brand: "Williams",
    display: "./display/williams95deluxe.png",
    images: ["../itemPage/williams95deluxe_1.jpg"],
    description: ["Manufactured by J.H. Williams of the Snap-On Tool Group and are professional quality, made of high-grade chrome vanadium steel. Sockets feature SUPERTORQUE® lobular openings for greater turning power without deforming the fastener. All pieces carry a lifetime warranty through Williams.", "Includes:"],
    specs: ["1/4” and 3/8” drive accessories such as 36 tooth ratchets, extension bars, flex handle, spinner handle, universal joint and adapters.", "10 – 6 pt SAE shallow sockets from 3/16” to 9/16” in 1/4” Drive", "10 – 6 pt SAE deep sockets from 3/16” to 9/16” in 1/4” Drive", "11 – 6 pt shallow sockets from 3/8” to 1” in 3/8” Drive", "11 – 12 pt deep sockets from 3/8” to 1” in 3/8” Drive", "6 – Hex bit sockets from 1/8” to 5/16”", "6 – Hex bit sockets from 3mm to 8 mm", "6 – 1/4” screwdriver bits – 3 slotted & 3 phillips", "6 – 1/4” Torx Bits from T10 to T30", "9 – Combination Wrenches from 1/4” to 3/4”", "4 – Comfort Grip Screwdrivers – 2 – Slotted and 2 – Phillips"],
    sizing: "One Size"
  },
  {
    _id: "3758",
    title: "Williams 89 pc Socket Set - Metric",
    genre: { _id: "116", name: "Williams Tools" },
    availability: "In Stock",
    condition: "New",
    brand: "Williams",
    display: "./display/williams58deluxe.png",
    images: ["../itemPage/williams58deluxe_1.jpg"],
    description: ["Manufactured by William’s Tool Group. This is a professional quality tool set made of high- grade chrome vanadium steel. The set includes an assortment of sockets, pliers, adjustable wrenches and screwdriver bits that everyone will love. Lifetime warranty through William’s Tool Group.", "Contents include:"],
    specs: ["13 – Sae sockets in the 3/8” drive – 1⁄4” to 1”", "14 – Metric sockets in the 3/8 drive – 6 to 19 mm", "6 – 1⁄4” Hex Screwdriver Bits", "1 each – Spinner handle, universal joint, and 1⁄4” hex bit holder", "1 each – Quick release ratchet, 3,6 & 10” extension bar & 10” flex handle", "1 - Sliding T-handle", "1 – 3/8”F to 1/4”M Adapter", "1 – 3/8”F to 1/2”M Adapter", "1 – 8” Adjustable Wrench", "1 – 8” Slip Joint Pliers", "1 – 9 3⁄4” Groove Joint Pliers", "1 – 7” Diagonal Cutting Pliers", "1 – 6 1⁄4” Long Nose Pliers"],
    sizing: "One Size"
  },
  {
    _id: "3785",
    title: "82pc Williams Socket Set Combo",
    genre: { _id: "116", name: "Williams Tools" },
    availability: "In Stock",
    condition: "New",
    brand: "Williams",
    display: "./display/williamskit.png",
    images: ["../itemPage/williamskit_1.jpg"],
    description: ["Manufactured by Williams® Tool Group. This is an awesome value on a quality starter set of Williams® Hand Tools. The set includes one each of our stock# 3786 – 3790, so you get all the popular sizes Sae and Metric in 1/4”, 3/8” and 1/2” drive. Each set still comes in the individual box from Williams® so they can be left individual, or placed in a larger tool box at your customers disgression. This is a price promotional item from Williams® so sell them while you can. By buying the 5 sets as one you save about $70 under the price of the individual sets."],
    sizing: "Betweem the 5 Sets You will Reieve Every Standard Size From 3/16” to 1 1/4” amd Every Metric Size From 4 to 24 MM With Each Set Including Associated Accessories"
  },
  {
    _id: "3759",
    title: "Williams Deluxe 1/2” Socket Set",
    genre: { _id: "116", name: "Williams Tools" },
    availability: "In Stock",
    condition: "New",
    brand: "Williams",
    display: "./display/williamshalfchrome.png",
    images: ["../itemPage/williamshalfchrome_1.jpg"],
    description: ["Manufactured by William’s Tool Group. These sets are professional quality tools made of high-grade chrome vanadium steel. They include an assortment of 1⁄2” drive sockets and accessories that everyone will love. Lifetime warranty through William’s Tool Group.", "Each set includes:"],
    specs: ["9-7/8” Quick-Release Ratchet", "1 each – 3”, 6”, and 10” extension", "15” Flex Handle Breaker Bar", "Universal Joint", "1/2” F to 3/8” M Adapter", "1/2” F to 3/4” M Adapter", "Heavy-duty plastic tool kit with steel latches and hinges"],
    sizing: "The Sae set includes 15 sockets from 3/8” to 1-1/4”. The Metric Set includes 17 sockets from 10 to 32 mm"
  },
  {
    _id: "1653",
    title: "Williams 21 pc 3⁄4” Socket Set – Sae",
    genre: { _id: "116", name: "Williams Tools" },
    availability: "In Stock",
    condition: "New",
    brand: "Williams",
    display: "./display/williamsthreechrome.png",
    images: ["../itemPage/williamsthreechrome_1.jpg"],
    description: ["This is a professional quality 3⁄4” socket set manufactured by Williams® Tool Company. Unique Socket-Lok® Safety Retention System features a spring-loaded locking button for maximum socket retention and quick disassembly when used with our Williams locking-button square drive tools and sockets. May also be used with all standard 3⁄4” drive tools.", "High-polished chrome finish tools clean easily and resist rust. Ratchet features a knurled handle to provide a firm, non-slip grip, and extra turning power. Sockets feature a deep chamfer on the drive end to speed insertion onto the drive tool. Chamfered ends provide easy engagement onto the drive tool. Chamfered ends provide easy engagement of the drive tool and fastener. Recessed corners in socket end provide extra turning power."],
    sizing: "The set includes 16 – 12-point sockets from 7/8” to 2”, a ratchet and breaker bar, and a 4” and 8” extension bar. The set comes in a sturdy metal box for storage. "
  },
  {
    _id: "3811",
    title: "Supertorque Deep Impact Sockets - 1/2” Drive",
    genre: { _id: "116", name: "Williams Tools" },
    availability: "In Stock",
    condition: "New",
    brand: "Williams",
    display: "./display/williamshalfimpact.png",
    images: ["../itemPage/williamshalfimpact_1.jpg"],
    description: ["Manufactured by J.H. Williams of the Snap-On Tool Group and carry a lifetime warranty through Williams. Supertorque® lobular openings for greater turning power without deforming the fastener. Designed for maximum engagement on 6 point fasteners. Suitable for use with pneumatic and electric impact wrenches. Specially heat treated for impact use to provide long life, safety, and durability", "Drive end features pin retention hole and groove"],
    sizing: "The Sae set includes 15 sockets from 3/8” to 1-1/4”. The Metric Set includes 14 sockets from 10 to 27 mm"
  },
  {
    _id: "3813",
    title: "Supertorque Impact Sockets - 3/4” Drive",
    genre: { _id: "116", name: "Williams Tools" },
    availability: "In Stock",
    condition: "New",
    brand: "Williams",
    display: "./display/williamsthreeimpact.png",
    images: ["../itemPage/williamsthreeimpact_1.jpg"],
    description: ["Manufactured by J.H. Williams of the Snap-On Tool Group and carry a lifetime warranty through Williams. Supertorque® lobular openings for greater turning power without deforming the fastener. Designed for maximum engagement on 6 point fasteners. Suitable for use with pneumatic and electric impact wrenches. Specially heat treated for impact use to provide long life, safety, and durability", "Drive end features pin retention hole and groove"],
    sizing: "The set includes 16 sockets from 7/8” to 2” "
  },
  {
    _id: "3750",
    title: "Williams Wrench Sets",
    genre: { _id: "116", name: "Williams Tools" },
    availability: "In Stock",
    condition: "New",
    brand: "Williams",
    display: "./display/williamswrench.png",
    images: ["../itemPage/williamswrench_1.jpg"],
    description: ["Manufactured by William’s Tool Group. This is a heavy-duty professional mechanics set of combination wrenches. Lifetime warranty through William’s Tool Group. Satin chrome textured finish provides great feel and extra gripping power. 12 point box end provides easy engagement and maximum versatility", "Recessed corners in box end provide extra turning power", "Sets are packed in vinyl pouch for easy inventory and storage"],
    sizing: "The Sae set includes 11 wrenches from 3/8” to 1”. The Metric Set includes 14 wrenches from 6 to 19 mm"
  },
  {
    _id: "3805",
    title: "Williams Stubby Wrenches",
    genre: { _id: "116", name: "Williams Tools" },
    availability: "In Stock",
    condition: "New",
    brand: "Williams",
    display: "./display/williamsstubby.png",
    images: ["../itemPage/williamsstubby_1.jpg"],
    description: ["Manufactured by William’s Tool Group. This is a heavy-duty professional mechanics set of stubby wrenches. Satin chrome textured finish provides great feel and extra gripping power. 12 point box end provides easy engagement and maximum versatility", "Recessed corners in box end provide extra turning power", "Sets are packed in vinyl pouch for easy inventory and storage"],
    sizing: "The Sae set includes 7 wrenches from 3/8” to 3/4”. The Metric Set includes 7 wrenches from 10 to 18 mm"
  },
  {
    _id: "3803",
    title: "Williams Flare Nut Wrenches",
    genre: { _id: "116", name: "Williams Tools" },
    availability: "In Stock",
    condition: "New",
    brand: "Williams",
    display: "./display/williamsflare.png",
    images: ["../itemPage/williamsflare_1.jpg"],
    description: ["Manufactured by William’s Tool Group. This is a heavy-duty professional mechanics set of flare nut wrenches. Satin chrome textured finish provides great feel and extra gripping power. 12 point box end provides easy engagement and maximum versatility", "Recessed corners in box end provide extra turning power", "Sets are packed in vinyl pouch for easy inventory and storage"],
    sizing: "The Sae set includes 4 wrenches in  3/8” x 7/8”, 1/2” x 9/16”, 5/8” x 11/16” & 3/4” x 7/8”. The Metric Set includes 5 wrenches in  9 x 11mm, 10 x 12mm, 13 x 14mm, 15 x 17mm & 19 x 21mm"
  },
  {
    _id: "3770",
    title: "Williams Aluminum Pipe Wrenches",
    genre: { _id: "116", name: "Williams Tools" },
    availability: "In Stock",
    condition: "New",
    brand: "Williams",
    display: "./display/williamspipe.png",
    images: ["../itemPage/williamspipe_1.jpg"],
    description: ["Manufactured by Williams Tool Group. This is a top quality set of pipe wrenches that all of your industrial users will love. They are approximately 40% lighter than standard cast-iron pipe wrenches and will not conduct heat or electricity. The jaws on Williams pipe wrenches are interchangeable with the Rigid® brand of pipe wrenches, which your customers will appredciate. Lifetime warranty against manufactures defects only."],
    sizing: "Available in 10” to 24” "
  },
  {
    _id: "3753",
    title: "Williams Pry Bars",
    genre: { _id: "116", name: "Williams Tools" },
    availability: "In Stock",
    condition: "New",
    brand: "Williams",
    display: "./display/williamspry.png",
    images: ["../itemPage/williamspry_1.jpg"],
    description: ["Manufactured by William’s Tool Group. This is a heavy-duty mechanics set of pry bars whose quality is as good as it gets. It indludes an 8” and an 18” pry bar. Lifetime warranty through William’s Tool Group."],
    specs: ["Bi-mold handles provide comfortable grip, reducing hand fatigue", "Thumb stop on handle provides control during use", "Fulcrum end provides extra prying power", "Heat treated alloy steel blades will withstand heavy duty use"],
    sizing: "Set Includes 8” and 18” "
  },
  {
    _id: "3780",
    title: "Williams Tin Snips",
    genre: { _id: "116", name: "Williams Tools" },
    availability: "In Stock",
    condition: "New",
    brand: "Williams",
    display: "./display/williamstinsnip.png",
    images: ["../itemPage/williamstinsnip_1.jpg"],
    description: ["Manufactured by Williams Tool Group. These are top quality aviation style tin snips that any contractor will love the quality of. They feature bi-mold comfort grip handles that are color-coded to allow quick identification of cutting direction. This product meets and exceeds ANSI and DIN standards."],
    sizing: "Available in Left, Right or Straight Cut"
  },
  {
    _id: "3756",
    title: "Williams Locking Plier Set",
    genre: { _id: "116", name: "Williams Tools" },
    availability: "In Stock",
    condition: "New",
    brand: "Williams",
    display: "./display/williamsvice.png",
    images: ["../itemPage/williamsvice_1.jpg"],
    description: ["Manufactured by William’s Tool Group. This is Williams’s answer to the Vise-Grip brand locking pliers. They are extremely high quality and carry a lifetime warranty through Williams Tool Group. The set includes a 7” straight jaw plier with cutter, a 10” curved jaw plier with cutter, and a 6” long nose plier with cutter.", "Features Include:"],
    specs: ["Bi-Mold handles for comfortable grip, reducing hand fatigue", "Convenient one-hand release lever", "Heavy-duty jaw surfaces provide extra gripping power", "Packed in a vinyl pouch"],
    sizing: "Available in Left, Right or Straight Cut"
  },
  {
    _id: "1477",
    title: "Air Operated Floor Jack",
    genre: { _id: "117", name: "Hydraulics" },
    availability: "In Stock",
    condition: "New",
    brand: "TruLift",
    display: "./display/airfloorjack.png",
    images: ["../itemPage/airfloorjack_1.jpg"],
    description: ["This is an industrial quality air operated hydraulic jack. They will be used in any shop that repairs trucks, cars or heavy equipment. This jack is operated only on air pressure supplied by an air compressor. It is compact and portable, and features large 8” rubber tires for ease in maneuvering. It has heavy-duty springs for retraction of hydraulic ram to eliminate manual retraction. The release valve is mounted at the top of the 50” handle and can be activated with the jack handle in any position."],
    sizing: "Available in 22 Ton with a Range from 9 to 22 Inches or 32 Ton with a Range from 11 to 24 Inches"
  },
  {
    _id: "1464",
    title: "50 Ton Air/Hydraulic Bottle Jack",
    genre: { _id: "117", name: "Hydraulics" },
    availability: "In Stock",
    condition: "New",
    brand: "TruLift",
    display: "./display/50tonairjack.png",
    images: ["../itemPage/50tonairjack_1.jpg"],
    description: ["This is a very aggressive pricing on a very large jack. Customers will not find anything of this capacity at normal outlets. It features heavy duty steel construction and comes with a one year warranty against manufactures defects. ", "Specifications:"],
    specs:["Minimum Lift Height - 11-5/8”","Maximum Lift Height - 17-15/16”", "Lifting Height: 6-5/16”", "Rubber Hose: 48”", "Connector Size: 1/4”", "Shipping weight – 71 lbs" ],
    sizing: "One Size"
  },
  {
    _id: "1466",
    title: "Air/Hydraulic Bottle Jack",
    genre: { _id: "117", name: "Hydraulics" },
    availability: "In Stock",
    condition: "New",
    brand: "TruLift",
    display: "./display/airbottlejack.png",
    images: ["../itemPage/airbottlejack_1.jpg"],
    description: ["This is a unique hydraulic jack that can be used manually (pumped up) or it can be plugged into an airline and raised with compressed air. It is perfect for any trucker, farmer, heavy equipment operator etc.", "Specifications:"],
    specs:["Low Consumption - only 90 PSI to operate", "Rubber Hose: 48”", "Connector Size: 1/4”" ],
    sizing: "Available in 12, 20 or 32 Ton"
  },
  {
    _id: "1470",
    title: "Hydraulic Bottle Jack",
    genre: { _id: "117", name: "Hydraulics" },
    availability: "In Stock",
    condition: "New",
    brand: "TruLift",
    display: "./display/bottlejack.png",
    images: ["../itemPage/bottlejack_1.jpg"],
    description: ["This is your standard portable jack used in a large variety of applications it features a precision ground pump piston, extra wide base for stability, a hardened ram, and a polished handle. This jack can only be used upright or slightly tilted. Hydraulic fluid can be added through valve on side if jack loses fluid. They also feature a one-year warranty against defects in materials or workmanship."],
    sizing: "Available in 12, 20 or 32 Ton"
  },
  {
    _id: "1478",
    title: "12 Ton Hydraulic Pipe Bender",
    genre: { _id: "117", name: "Hydraulics" },
    availability: "In Stock",
    condition: "New",
    brand: "TruLift",
    display: "./display/pipebender.png",
    images: ["../itemPage/pipebender_1.jpg"],
    description: ["This is a great product for any plumbing, electrical, or oil field type of customers who are working with pipe and conduit on a regular basis. It is relatively simple in design and very simple to operate. Included in the set is a frame assembly, a 12 ton bottle jack and six bending dies (1/2”, 3/4”, 1”, 1-1/4”, 1-1/2” and 2”).", "The unit requires no major assembly, however, some setup is needed before you use the machine. Setup and operating instructions are included with the bender. One year warranty against manufactures defects."],
    sizing: "Includes Everything Mentioned"
  },
  {
    _id: "1698",
    title: "10 Ton Hydraulic Gear Puller Kit",
    genre: { _id: "117", name: "Hydraulics" },
    availability: "In Stock",
    condition: "New",
    brand: "TruLift",
    display: "./display/hydrogearpuller.png",
    images: ["../itemPage/hydrogearpuller_1.jpg"],
    description: ["This is a shop quality hydraulic puller that is used for gears, bearings, pulleys, and flanges. This unit will be used by anyone running or operating heavy equipment or machinery, plus any industrial maintenance or commercial maintenance applications.", "This unit can be adjusted for either a twin jaw or triple jaw application, and coupled with the pulling force of up to 10 tons it is guaranteed to remove even the toughest gear or bearing. The jaws on this unit can be adjusted for length to 4”, 6” or 8”. The reach ranges from 0” to 10”, and the spread ranges from 2” to 10”. The set also includes two extension rods, a beam and a separator. One-year warranty against defects in materials or workmanship."],
    sizing: "Includes Everything Mentioned"
  },
  {
    _id: "1697",
    title: "10 Ton Portapower Kit",
    genre: { _id: "117", name: "Hydraulics" },
    availability: "In Stock",
    condition: "New",
    brand: "TruLift",
    display: "./display/portapower.png",
    images: ["../itemPage/portapower_1.jpg"],
    description: ["This is a professional quality body and frame repair kit. It is a push unit only, not a push/pull unit. Users of this unit include truck repair shops, welders, metal fabricators, farmers, ranchers, oilfield workers, equipment dealers, contractors and trash collection companies. This unit features a pump unit with rubber flame resistant hose (approx. 6' long) with a 5000-psi working pressure and double base feet to protect valves and provide pumping stability. Also included is a hydraulic ram that works either horizontally or vertically as long as the hose is at the lower end of the pump. This new type unit features slip on fitting attachments (just push on the tubing or fittings and you are ready to go). This unit comes in either a steel box or a plastic case and carries a 1-year warranty against defects in materials or workmanship.", "Includes:"],
    specs:["4 - Extension Tubes - 5”, 10”, 18”, And 27”", "1 - Rubber Head - 3 1/4”", "1 - Spreader Ram Toe", "1 - Wedge Head", "1 - 1,000 Lb Hydraulic Spreader (Duck Bill)", "1 - Serrated Saddle", "1 - Flat Base", "1 - Spreader Plunger Toe", "1 - 90 Degree V-Base"],
    sizing: "Includes Everything Mentioned"
  },
  {
    _id: "1467",
    title: "Jack Stands",
    genre: { _id: "117", name: "Hydraulics" },
    availability: "In Stock",
    condition: "New",
    brand: "TruLift",
    display: "./display/jackstands.png",
    images: ["../itemPage/jackstands_1.jpg"],
    description: ["These are industrial quality ratchet type jack stands. They feature a heavy gauge pressed steel frame construction and a sturdy ratchet bar for strength and durability. The saddle is precision designed with deep groves for positive contact and stable support. The frame and base are designed for maximum stability and painted with durable baked enamel paint for resistance to corrosion. These units are tested to 150% of rated capacity. They are both sold in and used in pairs. They also feature a one-year warranty against defects in materials or workmanship."],
    sizing: "Available in 6 or 12 Ton"
  },
  {
    _id: "1475",
    title: "3 Ton Floor Jack",
    genre: { _id: "117", name: "Hydraulics" },
    availability: "In Stock",
    condition: "New",
    brand: "TruLift",
    display: "./display/3tonFloor.png",
    images: ["../itemPage/3tonFloor_1.jpg"],
    description: ["This is a full size 3 ton floor jack, just like the type you see in gas stations and garages. The jack itself weighs 70 lbs and is not to be confused with the smaller so-called mini floor jacks. This jack features all steel casters on ball bearings for easy positioning. It is outstanding quality at an affordable price. This jack meets American National Safety standards. This unit carries a one year warranty against manufactures defects."],
    specs:["Working height: 5-5/16” - 20-1/2”", "Handle length: 36-3/4”", "Front wheel: 3.5” fixed", "Rear wheel: 2.5” swivel", "Saddle diameter: 4.7”"],
    sizing: "One Size"
  },
  {
    _id: "1491",
    title: "1” Impact Wrench - 2000 ft/lb",
    genre: { _id: "118", name: "Pneumatic Tools" },
    availability: "In Stock",
    condition: "New",
    brand: "Tooluxe",
    display: "./display/inchimpact.png",
    images: ["../itemPage/inchimpact_1.jpg","../itemPage/inchimpact_2.jpg"],
    description: ["This is a professional quality 1” drive air impact wrench. It is rugged, heavy duty, and ideal for heavy equipment maintenance, and the construction industry. This unit is available only in a long shank (6” anvil) version. It has an adjustable built in power regulator, easy access forward/reverse lever, a built in rear grip D-handle and trigger, and an adjustable auxiliary side handle. This unit comes with a one year parts and labor guarantee."],
    specs:["Bolt Capacity: 1 1⁄4”"," Maximum Torque: 0 - 1,900 Ft/Lbs","Operating Pressure: 120 PSI","Air Consumption: 12 CFM","Air Inlet: 1/2” NPT","Minimum Hose Size: 1/2” ID","Length: 20” for Long Shank","Weight: 29 lbs. for Long Shank"],
    sizing: "One Size"
  },
  {
    _id: "3896",
    title: "1” Impact Wrench - 4000 ft/lb",
    genre: { _id: "118", name: "Pneumatic Tools" },
    availability: "In Stock",
    condition: "New",
    brand: "Tooluxe",
    display: "./display/inchimpact2.png",
    images: ["../itemPage/inchimpact2_1.jpg","../itemPage/inchimpact2_2.jpg"],
    description: ["This is a professional quality 1” drive air impact wrench that is absolutely a beast!!! It is rugged, heavy duty, and ideal for heavy equipment maintenance, and the construction industry. It puts out an incredible 4000 ft/lbs of torque. It has an adjustable built in power regulator, easy access forward/reverse lever, a built in rear grip D-handle and trigger, and an adjustable auxiliary side handle. This unit comes with a one year parts and labor guarantee against manufactures defects."],
    specs:["Bolt Capacity: 1 1⁄4”"," Maximum Torque: 0 - 4,000 Ft/Lbs","Operating Pressure: 115 - 175 PSI","Air Inlet: 1/2” NPT","Minimum Hose Size: 1/2” ID","Length: 20” for Long Shank","Weight: 48 lbs. for Long Shank"],
    sizing: "One Size"
  },
  {
    _id: "3892",
    title: "700 Ft/Lb 1/2” Air Wrench",
    genre: { _id: "118", name: "Pneumatic Tools" },
    availability: "In Stock",
    condition: "New",
    brand: "Tooluxe",
    display: "./display/halfinch700.png",
    images: ["../itemPage/halfinch700_1.jpg"],
    description: ["This is an industrial quality 1⁄2” air wrench that has 700 ft/lbs of torque. It features a twin hammer design that provides up to 700 ft/lbs of torque. This unit features an aluminum alloy body to absorb vibration and increase operator comfort. The ergonomic handle allows for single-hand operation. It has an easy to service two piece construction and side exhaust for convenience. This tool compares very well with your top of the line name brand wrenches such as Ingersoll-Rand and Mac. It carries a one year warranty against manufactures defects."],
    specs:["Bolt Capacity: 5/8”"," Maximum Torque: 0 - 700 Ft/Lbs","Operating Pressure: 90 PSI","Air Inlet: 3/8” NPT","Length: 8”","Weight: 4.5 lbs."],
    sizing: "One Size"
  },
  {
    _id: "1486",
    title: "3/8” Air Wrench",
    genre: { _id: "118", name: "Pneumatic Tools" },
    availability: "In Stock",
    condition: "New",
    brand: "Tooluxe",
    display: "./display/threeimpact.png",
    images: ["../itemPage/threeimpact_1.jpg"],
    description: ["This is a heavy-duty industrial rated 3/8 inch air impact tool. As the description implies it is a pistol grip and it can be used for all types of general-purpose work where an air impact is required. The tool is available in short shank only and carries a one year parts and labor guarantee."],
    specs:["Maximum Torque: 120 Ft/Lbs", "Maximum Speed: 9000 RPM", "Air Pressure: 90 PSI", "Air Consumption: 3 CFM"],
    sizing: "One Size"
  },
  {
    _id: "1482",
    title: "3” Utility Cut-Off Tool",
    genre: { _id: "118", name: "Pneumatic Tools" },
    availability: "In Stock",
    condition: "New",
    brand: "Tooluxe",
    display: "./display/diegrind.png",
    images: ["../itemPage/diegrind_1.jpg"],
    description: ["This is a high-speed air cut off tool also known as a panel cutter. It uses a 3 Inch abrasive wheel and is excellent for cutting sheet metal, bumper bolts, or steel rods. Body shops, sheet metal fabricators, auto garages and muffler shops use it. It carries a 90 day parts and labor warranty."],
    specs:[" Maximum Speed: 18,000 RPM","Average Air Consumption: 4 CFM","Overall Length: 8”","Air Inlet: 1/4” NPT"],
    sizing: "One Size"
  },
  {
    _id: "1493",
    title: "Air Grease Gun",
    genre: { _id: "118", name: "Pneumatic Tools" },
    availability: "In Stock",
    condition: "New",
    brand: "Tooluxe",
    display: "./display/airgrease.png",
    images: ["../itemPage/airgrease_1.jpg"],
    description: ["This air grease gun features an all-metal design with chrome plating and baked enamel surfaces. It operates to 6,000 PSI with 30 to 150 PSI air supply. The unit includes a 6 Inch rigid steel nozzle and a 9 Inch flexible nozzle. It is lightweight at 3.3 lbs and uses a 1/4” NPT air fitting. It features three ways of loading, using a 14 0z. Cartridge, suction filling, or dump loading. It comes with a 90 day parts and labor guarantee."],
    sizing: "One Size"
  },
  {
    _id: "1485",
    title: "Air Ratchet",
    genre: { _id: "118", name: "Pneumatic Tools" },
    availability: "In Stock",
    condition: "New",
    brand: "Tooluxe",
    display: "./display/airratchet.png",
    images: ["../itemPage/airratchet_1.jpg"],
    description: ["These are professional quality air ratchets used in automotive and industrial workshops. They feature a steel head with an aluminum alloy housing. Each ratchet is 10 1⁄2” long and is operated by a butterfly trigger. They have a high-speed rundown feature to speed up tightening operations. It carries a 1 year parts and labor warranty."],
    sizing: "Available in 3/8” or 1/2”"
  },
  {
    _id: "3729",
    title: "1/2” Composite Air Drill",
    genre: { _id: "118", name: "Pneumatic Tools" },
    availability: "In Stock",
    condition: "New",
    brand: "NTI",
    display: "./display/airdrill.png",
    images: ["../itemPage/airdrill_1.jpg"],
    description: [" This is the air drill asked for by the professional. It features heavy-duty composite construction, a 1/2 inch keyless chuck, double reduction gears, and is rated 1/2” capacity in steel. It has a side assist hande for stability in heavy-duty applications. It is fully reversible and carries a 90 day parts and labor guarantee."],
    specs:["Free Speed: 450 Rpm", "Inlet Size: 1⁄4” Npt", "Hose Size: 3/8”", "Air Consumption: 4 Cpm", "Air Pressure: 90 PSI"],
    sizing: "One Size"
  },
  {
    _id: "1481",
    title: "Air Hammer w/Chisels",
    genre: { _id: "118", name: "Pneumatic Tools" },
    availability: "In Stock",
    condition: "New",
    brand: "NTI",
    display: "./display/airhammer.png",
    images: ["../itemPage/airhammer_1.jpg"],
    description: ["This is a professional quality air chisel. It is used for chiseling, cutting, hammering, and ripping metal. The tool comes with 4 chisels, including a rivet chisel, a cold chisel, a panel chisel and a bull point chisel. The tool is all aluminum and features a push button trigger. The chisels are held in place by a spring retainer device. The tool is approximately 6” long and weighs 2 1/2 lbs. 90 day parts and labor guarantee."],
    sizing: "One Size"
  },
  {
    _id: "1752",
    title: "60 Amp Inverter Plasma Cutter ",
    genre: { _id: "119", name: "Miscellaneous" },
    availability: "In Stock",
    condition: "New",
    brand: "NTI",
    display: "./display/plasma.png",
    images: ["../itemPage/plasma_1.jpg"],
    description: ["This 60 Amp Inverter Plasma Cutter is an outstanding value, and is designed for the bigger jobs. It is used for heavy duty steel cutting applications. A plasma cutter works by super-heating a beam of electrons to 40,000 degrees (vs. a cutting torch at 2000 degrees) that will make a very smooth cut in steel. It comes with all the tools needed to start your project; torch assembly, grounding clamp, air regulator, brush/hammer, and consumables (ceramic tips and electrodes) are included. This unit does require an air compressor that can supply 3.5 CFM @ 65 PSI.","Specifications:"],
    specs:["No Load Voltage – 200 amp", "Current Adj Range – 20 – 60 amp, Stepless, Digital Display", "60% duty cycle, 60 amp full power", "Single phase 230 volt +/- 10%, 50/60 Hertz, 1-ph", "Air requirement: 3.5 CFM @ 65 psi", "6.5’ 10 gauge cord * 9.8’ ground lead * 9.8’ ground lead"],
    sizing: "One Size"
  },
  {
    _id: "1059",
    title: "Auto Rewind Hose Reels",
    genre: { _id: "119", name: "Miscellaneous" },
    availability: "In Stock",
    condition: "New",
    brand: "NTI",
    display: "./display/hosereel.png",
    images: ["../itemPage/hosereel_1.jpg"],
    description: ["These are the air hose reels that all your customers have been wanting!!! They are totally industrial and very heavy duty (they weigh approximately 50 lbs). A comparable product made in the usa would cost between 500 and 600 dollars. They feature a 19 1⁄2” diameter reel and a 6” x 6” heavy steel mounting bracket made of 1/4” steel plate. The hose is a usa made non-conductive hose to eliminate the possibility of combustion (due to static electricity) during use. The hose is rated at 250 psi and features 1/4” NPT solid brass fittings on the 3/8” hose and 3/8” npt solid brass fittings on the 1/2” hose."],
    sizing: "Available in 3/8”, 1/2” or Welding Hose"
  },
  {
    _id: "1755",
    title: "Steel Bench Vise",
    genre: { _id: "119", name: "Miscellaneous" },
    availability: "In Stock",
    condition: "New",
    brand: "NTI",
    display: "./display/benchvice.png",
    images: ["../itemPage/benchvice_1.jpg"],
    description: ["This is a professional quality machined steel vise. It is not a standard cast iron vise that will break when pounded on. It features replaceable steel jaws, built in pipe jaws and anvil, a 360 degree swivel base with dual lock down, chrome plated steel handles, and a baked enamel finish. 10 year warranty!"],
    sizing: "Available in 6” or 8” Capacities"
  },
  {
    _id: "1765",
    title: "20 Gallon Parts Washer",
    genre: { _id: "119", name: "Miscellaneous" },
    availability: "In Stock",
    condition: "New",
    brand: "NTI",
    display: "./display/partswasher.png",
    images: ["../itemPage/partswasher_1.jpg"],
    description: ["This unit is a 20 gallon tub with 12 gallon solvent capacity. The solvent pump is a standard 115 volt/ 60 hz, and delivers up to 50 gallons/hour. It features a fusible linked lid arm that closes the lid automatically in the event of a fire. The tank itself is a one-piece 18 gauge seamless steel unit with rounded corners for easy cleaning. It has a heavy duty removable work shelf and parts basket, a built-in continuous solvent filtration system, triple baked blue and red enamel finish, and heavy duty metal legs. It comes with a flexible chrome spigot hose that adjusts to any position, and an on/off switch with indicator light. Relatively easy to assemble! The overall dimensions are 31 inches length - 21 inches width - 36.5 inches height, and it weighs approximately 65lbs. One year warranty against defects in material."],
    sizing: "One Size"
  },
  {
    _id: "1762",
    title: "Tire Bead Breaker",
    genre: { _id: "119", name: "Miscellaneous" },
    availability: "In Stock",
    condition: "New",
    brand: "NTI",
    display: "./display/tirebeadbreaker.png",
    images: ["../itemPage/tirebeadbreaker_1.jpg"],
    description: ["This is the perfect item to pair with the tire tool mount/dismount set. This bead breaker is step one in changing a tire. The second step is the mount/dismount tool, and the third step is the bead seater. The wedge foot of this unit is forced between the bead & rim flange, and the exclusive spring action is designed to reduce user fatigue associated with slide hammer kickback, plus this unit will never cut the bead of the tire. This bar is 50 inches in length and 80 inches when fully extended. One year warranty against manufactures defects."],
    sizing: "One Size"
  },
  {
    _id: "1763",
    title: "5 Gallon Air Bead Seater",
    genre: { _id: "119", name: "Miscellaneous" },
    availability: "In Stock",
    condition: "New",
    brand: "NTI",
    display: "./display/tirebeadseater.png",
    images: ["../itemPage/tirebeadseater_1.jpg"],
    description: ["This tools is designed for seating tires on the bead of a wheel. It is the simplest, safest and quickest way to accomplish this task and all customers love them. 90 day warranty against manufactures defects."],
    specs:["Operating pressure: 90 - 120 psi", "Max pressure: 145 psi", "5 gal steel tank that is CE approved", "Automatic function tocontrol barometric pressure", "On/Off valve allows controled filling of tank", "Large air gauge monitors air volume"],
    sizing: "One Size"
  },
  {
    _id: "1764",
    title: "3 pc Tire Mount / Dismount Tool",
    genre: { _id: "119", name: "Miscellaneous" },
    availability: "In Stock",
    condition: "New",
    brand: "NTI",
    display: "./display/tiremount.png",
    images: ["../itemPage/tiremount_1.jpg"],
    description: ["This is a tool for anyone that needs to dismount and remount a tire must have. It allows the user to dismount a tire in as little as 8 seconds and remount in less than 20 seconds. The set includes 3 pieces – a bead holder, a forked dismounting tool, and the spoon/iron remount tool. One year warranty against manufactures defects."],
    specs:["Only one tool needed to dismount tires", "Will not damage beads, saving valuable casings for successful retreading", "Eliminates the need to lift tire & rim to remove the bottom bead, preventing possible strain or even serious injury", "Will dismount tire with wheel size between 17.5” to 24.5”", "Super singles up to 315 size ", "Will handle most radial & bias ply tires"],
    sizing: "One Size"
  },
  {
    _id: "1770",
    title: "Oxy-Acetylene Welding Kit",
    genre: { _id: "119", name: "Miscellaneous" },
    availability: "In Stock",
    condition: "New",
    brand: "NTI",
    display: "./display/oxykit.png",
    images: ["../itemPage/oxykit_1.jpg"],
    description: ["This is a professional quality oxygen/ acetylene welding kit. Included in the kit is everything needed for gas welding except the tanks. It is a Victor type welding and cutting kit. The regulators are precision manufactured to the highest standards and feature a safety relief valve. The tips in this kit are designed to weld up to 1/4” and cut up to 1/2”. All attachments in the set have special threads so that hand pressure is all that is required to attach or detach them. All pieces have a 1 year over the counter replacement warranty.","Includes:"],
    specs:["High Capacity Oxygen Regulator", "High Capacity Acetylene Regulator", "Torch Handle", "Cutting Attachment (8 Inches Long)", "Heating Tip (Rose Bud)", "Welding Tips (#0, #2, & #4)", "Cutting Tip (#0)", "Mixer", "1/4 inch X 15 foot Twin Hose", "Pair Welding Goggles", "Spark Lighter", "Spanner Wrench"],
    sizing: "One Size"
  },
  {
    _id: "1768",
    title: "Solar Welding Helmet",
    genre: { _id: "119", name: "Miscellaneous" },
    availability: "In Stock",
    condition: "New",
    brand: "Tooluxe",
    display: "./display/weldinghelmet.png",
    images: ["../itemPage/weldinghelmet_1.jpg"],
    description: ["This is a fully automatic solar panel welding helmet with battery back-up. It features a German lens, 2 speeds (10/25 thousand milliseconds), and variable shade adjustment (9 – 13 DIN). It gives full protection to the face and front of the neck. This helmet also has a 2 1⁄4” x 4 1⁄2” observation window for viewing your work."],
    sizing: "One Size"
  },
  {
    _id: "1758",
    title: "55 Amp Monster Battery Charger",
    genre: { _id: "119", name: "Miscellaneous" },
    availability: "In Stock",
    condition: "New",
    brand: "NTI",
    display: "./display/batterycharger.png",
    images: ["../itemPage/batterycharger_1.jpg"],
    description: ["This is the “Big Daddy” of all batter chargers. It is an awesome product for any auto or truck shop, farm shop, construction shop, etc. It features 12 or 24 volt capability, and is designed for all lead-acid batteries. It is perfect for cars, boats, trucks, RV’s, trailers, etc. It even includes a built in cooling fan, and wheels to roll it around on. It weights 60 lbs and carries a 90 day warranty against manufactures defects. Available in the continental United States only. ","Specifications:"],
    specs:["110V - 60Hz", "AC Input: 12V = 1400W , 24V= 2600W", "Charger Voltage: 12V = 56A , 24V = 55A", "Max Charger Current: 90A", "Battery Capacities 60-600Ah", "Starter Current: 600A", "Built In Fan for Cooling With Automatic Thermistor Protection", "Adjustable Timer For Quick Charge, so you can set it and forget it"],
    sizing: "One Size"
  },
  {
    _id: "1270",
    title: "Blue Poly Tarp",
    genre: { _id: "120", name: "Tarps, Straps, & Ties" },
    availability: "In Stock",
    condition: "New",
    brand: "ProTec",
    display: "./display/tarpblue.png",
    images: ["../itemPage/tarpblue_1.jpg"],
    description: ["This is a blue polyethelene fabric that is fully reinforced with a 10 x 10 nylon mesh (10 strands per inch x 10 strands per inch). It is 1/3 the weight of canvas and comes complete with gromets every three feet. This tarp is waterproof and mildewproof, and will not crack in cold weather. They are not to be sold for use on moving vehicles or on hot materials such as asphalt. One year warranty against manufactures defects only."],
    sizing: "Available in Almost Every Size From 6' x 8' to 30' x 60'"
  },
  {
    _id: "1322",
    title: "Silver Poly Tarp",
    genre: { _id: "120", name: "Tarps, Straps, & Ties" },
    availability: "In Stock",
    condition: "New",
    brand: "ProTec",
    display: "./display/tarpsilver.png",
    images: ["../itemPage/tarpsilver_1.jpg"],
    description: ["These are heavy duty, fibre reinforced polyethylene tarps that are twice the weight of our blue ones. This is the perfect item for the customer who feels our blue tarps are too light and doesn’t want to spend the money on the canvas ones. The silver color reflects rather than absorbs the suns rays which makes this tarp excellent to use as an awning. The material is also treated to reflect ultra violet rays which normally causes polyethylene material to deteriorate. "],
    sizing: "Available in Almost Every Size From 8' x 10' to 20' x 40'"
  },
  {
    _id: "1291",
    title: "Black Mesh Tarp",
    genre: { _id: "120", name: "Tarps, Straps, & Ties" },
    availability: "In Stock",
    condition: "New",
    brand: "ProTec",
    display: "./display/tarpblack.png",
    images: ["../itemPage/tarpblack_1.jpg"],
    description: ["Made in USA. This is a nice heavy duty nylon mesh tarp. Most states require these tarps to cover dump trucks hauling trash, landscape trailers, etc. Another common use is for windscreens and sunscreens. They are a whopping 8 oz per square yard in weight and black in color. All seams are double locked stitched with grommets every 3 feet around all four sides. The sizes listed are cut size, and finish size will be slightly smaller (approx 2” less on each side)."],
    sizing: "Available in 9' x 12' and 12' x 16'"
  },
  {
    _id: "1309",
    title: "Moving Blanket",
    genre: { _id: "120", name: "Tarps, Straps, & Ties" },
    availability: "In Stock",
    condition: "New",
    brand: "ProTec",
    display: "./display/movingblanket.png",
    images: ["../itemPage/movingblanket_1.jpg"],
    description: ["This is a super popular size of blanket designed to pad furniture when it is being moved. Most trucking companies use and go through large amounts of these. They are also used by contractors to protect furniture in rooms they are remodeling. They are made of a spun-bond non-woven material and blue and black in color."],
    sizing: "Available in 72” x 80”"
  },
  {
    _id: "1301",
    title: "Rubber Tarp Snubbers",
    genre: { _id: "120", name: "Tarps, Straps, & Ties" },
    availability: "In Stock",
    condition: "New",
    brand: "ProTec",
    display: "./display/tarpstrap.png",
    images: ["../itemPage/tarpstrap_1.jpg"],
    description: ["This is the “Cadillac” of the line in rubber tarp straps. Any of your customers who are unhappy with cheap imported straps will love these. They are an exact copy of our USA rubber tarp strap we used to carry. They are heavy duty and will not dry rot in the sun or go bad in cold weather. They feature heavy “S” type hooks on each end and can be used in many applications."],
    sizing: "Available in 10” to 41”"
  },
  {
    _id: "1296",
    title: "Bungi Cord",
    genre: { _id: "120", name: "Tarps, Straps, & Ties" },
    availability: "In Stock",
    condition: "New",
    brand: "ProTec",
    display: "./display/bungee.png",
    images: ["../itemPage/bungee_1.jpg"],
    description: ["This product is a nice alternative for the customer who doesn't like black rubber straps. They are made of an elastic band that is covered with a cloth type material. They also feature a hook on each end that is designed to wrap around the end of the elastic to protect it from wear and tear. These are priced in packs of 10 and must be sold in those increments."],
    sizing: "Available in 24” or 36”"
  },
  {
    _id: "1295",
    title: "Motorcycle Ratchet Straps",
    genre: { _id: "120", name: "Tarps, Straps, & Ties" },
    availability: "In Stock",
    condition: "New",
    brand: "ProTec",
    display: "./display/ratchetblack.png",
    images: ["../itemPage/ratchetblack_1.jpg"],
    description: ["This is an excellent item for light duty tie down. They were designed for motorcycles but have many other uses, such as lumber, ladders, PVC pipe, etc. The nylon webbing is test rated at 2000 lbs, and they are adjustable from one foot to six feet in length. The clamps are the cinch type (not ratcheting) and the hooks are coated to prevent damage to material."],
    sizing: "Available in 1” x 6'"
  },
  {
    _id: "1285",
    title: "Ratchet Straps - Flat Hook",
    genre: { _id: "120", name: "Tarps, Straps, & Ties" },
    availability: "In Stock",
    condition: "New",
    brand: "ProTec",
    display: "./display/ratchetflathook.png",
    images: ["../itemPage/ratchetflathook_1.jpg"],
    description: ["These are excellent quality and easy to use. They feature a ratchet action with a quick release trigger. They are a nylon mesh strap with a flat hook and a 4500 lb capacity. Guaranteed against manufactures defects in for 90 days."],
    sizing: "Available in 2” x 20'"
  },
  {
    _id: "1299",
    title: "Ratchet Straps - J Hook",
    genre: { _id: "120", name: "Tarps, Straps, & Ties" },
    availability: "In Stock",
    condition: "New",
    brand: "ProTec",
    display: "./display/ratchetjhook.png",
    images: ["../itemPage/ratchetjhook_1.jpg"],
    description: ["These are excellent quality and easy to use. They feature a ratchet action with a quick release trigger. They are a nylon mesh strap with a J hook and a 10000 lb capacity. Guaranteed against manufactures defects in for 90 days."],
    sizing: "Available in 2” x 27'"
  },
  {
    _id: "1286",
    title: "Cargo Loop w/Flat Hook",
    genre: { _id: "120", name: "Tarps, Straps, & Ties" },
    availability: "In Stock",
    condition: "New",
    brand: "ProTec",
    display: "./display/cargoloop.png",
    images: ["../itemPage/cargoloop_1.jpg"],
    description: ["Made in USA. This is a product that all your trucking accounts are looking for. They do not include a ratchet mechanism, as this product attaches to the ratchet mechanism on the trailer itself. They are made of an industrial grade, weather resistant polyster with a 15,000 lb capacity. A single flat hook is attached through double reinforced twisted ends."],
    sizing: "Available in 4” x 30'"
  },
  {
    _id: "1302",
    title: "Ratcheting Load Binder",
    genre: { _id: "120", name: "Tarps, Straps, & Ties" },
    availability: "In Stock",
    condition: "New",
    brand: "ProTec",
    display: "./display/loadbinder.png",
    images: ["../itemPage/loadbinder_1.jpg"],
    description: ["These load binders are a heavy duty steel construction and rated at 5,400 lbs. They have a large 16 inch handle that ratchets to cinch a load down. They are designed for use on either 5/16” or 3/8” chain. They are used for clamping, anchoring, and binding large loads. They are great for anyone chaining heavy loads to flatbed trailers. Lifetime replacement warranty!"],
    sizing: "One Size"
  },
  {
    _id: "1310",
    title: "Black/Orange Poly Rope",
    genre: { _id: "120", name: "Tarps, Straps, & Ties" },
    availability: "In Stock",
    condition: "New",
    brand: "ProTec",
    display: "./display/blackrope.png",
    images: ["../itemPage/blackrope_1.jpg"],
    description: ["Made in USA. This rope is made of polypropylene (not nylon). It is either yellow or black and orange in color and is refered to as truckers tie down rope. It is mandatory in most states for trucks. It will not absorb water and is resistive to acids, alkalis, and most solvents. This is 3 strand rope that is twisted, not braided."],
    sizing: "Available in 3/8” or 1/2”"
  },
  {
    _id: "3822",
    title: "Nylon Sling",
    genre: { _id: "120", name: "Tarps, Straps, & Ties" },
    availability: "In Stock",
    condition: "New",
    brand: "ProTec",
    display: "./display/nylonsling.png",
    images: ["../itemPage/nylonsling_1.jpg"],
    description: ["Made in USA. This is a very popular expendable item used with overhead hoists, cranes, and winches that handle loads requiring protection against scratching or marring. They are very flexible and work very well with bulky or irregularly shaped loads. These slings are known in the industy as “eye and eye” slings, and they are available with a standard flat eye.. Each sling comes with a red inner warning marker that becomes visible when the sling is cut or worn, which alerts user to a dangerous condition."],
    sizing: "Available in Single or Double Ply in 2” or 4” Widths"
  },
  {
    _id: "1330",
    title: "Black UV Wire Ties",
    genre: { _id: "120", name: "Tarps, Straps, & Ties" },
    availability: "In Stock",
    condition: "New",
    brand: "ProTec",
    display: "./display/ziptie.png",
    images: ["../itemPage/ziptie_1.jpg"],
    description: ["Made in USA. These are permanent, self-locking plastic cable ties used in all types of industries. They are professional quality and are rated at temperatures from -54 degress to 142 degrees f and are ultra-violet resistant. They are used heavily in the construction, automotive, and electronic industries. These ties are priced and sold by the bag only. They are all black in color, which makes them excellent for outdoor use since they won’t deteriorate in sunlight."],
    sizing: "Available in 4” to 48” Lengths"
  },
  {
    _id: "3351",
    title: "Stainless Steel Cable Tie",
    genre: { _id: "120", name: "Tarps, Straps, & Ties" },
    availability: "In Stock",
    condition: "New",
    brand: "NTI",
    display: "./display/ziptiesteel.png",
    images: ["../itemPage/ziptiesteel_1.jpg"],
    description: ["We know a lot of customers have been asking for these. Often they are used to hold hoses and hydraulic lines in place on heavy equipment, they are unaffected by heat, plus they have a multitude of other applications. Of course no worry about rust with stainless steel. They are approximately 1/4” wide and the tensile strength on these is 100 lbs."],
    sizing: "Available in 8” or 14” Lengths"
  },
  {
    _id: "1442",
    title: "Screwdriver Sets",
    genre: { _id: "121", name: "Screw & Nut Drivers" },
    availability: "In Stock",
    condition: "New",
    brand: "NTI",
    display: "./display/screwdriver.png",
    images: ["../itemPage/screwdriver_1.jpg"],
    description: ["This is a professional quality set of screwdrivers that are a copy of the Snap-On set. They feature red cellulose acetate handles that are resistant to grease, oil, and most solvents. The tips are vapor blasted and magnetized. The shanks are round with hex shaped bolsters to provide a gripping surface so that a wrench can be used to apply pressure. This set has a lifetime warranty against defects in material or workmanship.", "Includes:"],
    specs: ["3/8” x 8” Slotted Head", "5/16” x 6” Slotted Head", "1/4” x 4” Slotted Head", "3/16” x 3” Slotted Head", "1/4” x 1 1⁄2” Slotted Head Stubby", "#2 x 4” Phillips Head", "#1 x 3” Phillips Head", "#2 x 1 1⁄2” Phillips Head Stubby"],
    sizing: "Everything Mentioned Included"
  },
  {
    _id: "1448",
    title: "Nut Driver Sets",
    genre: { _id: "121", name: "Screw & Nut Drivers" },
    availability: "In Stock",
    condition: "New",
    brand: "NTI",
    display: "./display/nutdriver.png",
    images: ["../itemPage/nutdriver_1.jpg"],
    description: ["This is a professional quality set of nut drivers. They feature cellulose acetate handles with chrome vanadium steel shanks that are hardened and tempered. They are also all 6 point to reduce the chance of stripping. The set includes 7 drivers and they are all approximately 6” in length. Lifetime replacement warranty."],
    sizing: "3/16”, 1/4”, 5/16”, 11/32”, 3/8”, 7/16” & 1/2”"
  },
  {
    _id: "1606",
    title: "Magnetic Nut Setter",
    genre: { _id: "121", name: "Screw & Nut Drivers" },
    availability: "In Stock",
    condition: "New",
    brand: "ProTec",
    display: "./display/magnutsetter.png",
    images: ["../itemPage/magnutsetter_1.jpg"],
    description: ["The long magnetic nut setters are made of hardened and tempered industrial quality tool steel for long life and durability. They feature a standard 1⁄4” hex drive and are approximately 3” long. A strong magnetic tip holds nuts firmly in place. These nut setters are designed for use in drywall drivers (screw guns), but can also be used in power drills with keyed chucks.", "The extra-long magnetic nut setters are made of hardened and tempered industrial quality tool steel for long life and durability. They feature a standard 1⁄4” hex drive and are 6” in length. A strong magnetic tip holds nuts firmly in place. These nut setters are designed for use in drywall drivers (screw guns), but can also be used in power drills with keyed chucks."],
    sizing: "Available in Long or Extra Long in 1/4”, 5/16”, 3/8”, 7/16, and 1/2””"
  },
  {
    _id: "1610",
    title: "Phillips Insert Bits",
    genre: { _id: "121", name: "Screw & Nut Drivers" },
    availability: "In Stock",
    condition: "New",
    brand: "ProTec",
    display: "./display/insertbits.png",
    images: ["../itemPage/insertbits_1.jpg"],
    description: ["The bit tips themselves are extra-hard and gray in color. The #2 Phillips is the most popular but you will find you get requests for the #1 and #3 size also. Also many contractors are now switching to the #2 square drive. Drywall contractors use the reduced #2 because they do less damage to the drywall than standard bits."],
    sizing: "Available in 1” and 2” Lengths in either #1, #2, or #3"
  },
  {
    _id: "1609",
    title: "Magnetic Bit Holder",
    genre: { _id: "121", name: "Screw & Nut Drivers" },
    availability: "In Stock",
    condition: "New",
    brand: "ProTec",
    display: "./display/magbitholder.png",
    images: ["../itemPage/magbitholder_1.jpg"],
    description: ["This bit holder is the most popular sizes for construction and industrial users (1/4” hex drive with a straight 7/16” body and 3” in overall length). It is designed for use in drywall drivers (screw guns), but it can also be used in power drills with keyed chucks."],
    sizing: "6” Length Only"
  },
  {
    _id: "1160",
    title: "UL Electrical Tape",
    genre: { _id: "122", name: "Tape" },
    availability: "In Stock",
    condition: "New",
    brand: "ProTec",
    display: "./display/electape.png",
    images: ["../itemPage/electape_1.jpg"],
    description: ["These are 3⁄4” x 60’ rolls of electrical tape that is commonly used by electricians and other industrial users. They are UL approved, 7 mm thick and feature a full 100% elongation. Each roll is individually wrapped and sealed in cellophane which guarantees a full two year shelf life. Black is by far the most common color and the other colors are used for special purposes such as tagging or marking wiring. This tape is a general all purpose tape and is good -60 F. "],
    sizing: "Available In Most Colors Upon Request. Orders Placed For Only Black Recieve An Extra 20% Discount"
  },
  {
    _id: "1163",
    title: "Teflon Tape",
    genre: { _id: "122", name: "Tape" },
    availability: "In Stock",
    condition: "New",
    brand: "ProTec",
    display: "./display/teflon.png",
    images: ["../itemPage/teflon_1.jpg"],
    description: ["This is pure virgin teflon tape that comes in an enclosed plastic spool. It is 3 1⁄2 to 4 mm thick and has an indefinite shelf life. It has a tremendous number of applications for sealing the threads on pipes. It is used in plumbing and sprinkler work, in the automotive industry and on gas lines. "],
    sizing: "Available in 1/2”, 3/4”, or 1 Widths”"
  },
  {
    _id: "1175",
    title: "Pipe Wrap Tape",
    genre: { _id: "122", name: "Tape" },
    availability: "In Stock",
    condition: "New",
    brand: "NTI",
    display: "./display/pipewrap.png",
    images: ["../itemPage/pipewrap_1.jpg"],
    description: ["This is an industrial grade tape used to wrap around metal pipes to provide protection against corrosion from minerals or acids and alkalines in the soil or any other environment the pipe might come in contact with. This tape is recommended by the uniform plumbing code and IAPMO. It is used extensively in the plumbing industry, by water well drilling contractors, and in the oil field industry. "],
    sizing: "Available in 2” Width"
  },
  {
    _id: "1162",
    title: "Cloth Duct Tape",
    genre: { _id: "122", name: "Tape" },
    availability: "In Stock",
    condition: "New",
    brand: "Nashua",
    display: "./display/duct.png",
    images: ["../itemPage/duct_1.jpg"],
    description: ["Made in USA. This is some of the highest quality duct tape available on the commercial market. It is available in red, yellow, black or silver and is 2” wide. The silver is a 50 yd roll and the other colors are 60 yd. It has an extremely high adhesion strength and is a full cloth backed tape, not a plastic duct tape. It is used by heating and air conditioning companies, in glass work, and for general repair."],
    sizing: "Available in 2” Width in Silver, Black, Red, or Yellow "
  },
  {
    _id: "1185",
    title: "Nashua 324A HVAC Tape",
    genre: { _id: "122", name: "Tape" },
    availability: "In Stock",
    condition: "New",
    brand: "Nashua",
    display: "./display/hvactape.png",
    images: ["../itemPage/hvactape_1.jpg"],
    description: ["Made in USA. The Nashua Tape 324A cold weather foil tape provides a permanent, flexible seal in all HVAC applications. It is recommended for application on UL-listed fiberglass duct board and flexible duct systems. This product has the UL listings printed on the face of the tape. It is 2 1⁄2” wide by 60 yard in length."],
    specs:["Provides a permanent, flexible seal for UL-listed fiberglass duct board and flexible duct systems", "Low VOCs", "Excellent adhesion, even in extreme temperatures", "UL listings: 181A-P and 181B-FX", "Meets HUD and BOCA codes"],
    sizing: "Available in 2” Width in Silver, Black, Red, or Yellow "
  },
  {
    _id: "1172",
    title: "Tan Masking Tape",
    genre: { _id: "122", name: "Tape" },
    availability: "In Stock",
    condition: "New",
    brand: "Nashua",
    display: "./display/maskingtan.png",
    images: ["../itemPage/maskingtan_1.jpg"],
    description: ["Made in USA. This is a general purpose roll of tan masking tape that is suitable for most purposes except automobile painting. It is a full 50 yard roll and has a 2 year shelf life. General purpose masking tapes should not be used near any sort of heat or heat lamps."],
    sizing: "Available in 1”, 1 1/2”, or 2” Width"
  },
  {
    _id: "1176",
    title: "Blue Masking Tape",
    genre: { _id: "122", name: "Tape" },
    availability: "In Stock",
    condition: "New",
    brand: "Nashua",
    display: "./display/maskingblue.png",
    images: ["../itemPage/maskingblue_1.jpg"],
    description: ["Made in USA. This is a contractor quality 14 day masking tape. That means that it shouldn’t leave a sticky residue when it is removed if it is removed with 14 days of application. It is highly conformable and will resist bleed through, lifting, and slivering. It is used in all painting applications except lacquer applications or lacquered surfaces, and in automotive paint booths."],
    sizing: "Available in 1”, 1 1/2”, or 2” Width"
  },
  {
    _id: "1169",
    title: "Packing Tape",
    genre: { _id: "122", name: "Tape" },
    availability: "In Stock",
    condition: "New",
    brand: "Nashua",
    display: "./display/packingtape.png",
    images: ["../itemPage/packingtape_1.jpg"],
    description: ["This is a polypropylene tape that is used for fast, easy sealing of shipping cartons. It is usually used in a gun such as our stock #1180. It is pressure sensitive and requires no water or glue.."],
    sizing: "2” x 55 yards in length."
  },
  {
    _id: "1181",
    title: "Stretch Wrap Film",
    genre: { _id: "122", name: "Tape" },
    availability: "In Stock",
    condition: "New",
    brand: "Nashua",
    display: "./display/stretchwrap.png",
    images: ["../itemPage/stretchwrap_1.jpg"],
    description: ["Made in USA. This is a very popular size of stretch wrap film used for shipping pallets, small bundles and odd shaped products (cabinet shops are great potential customers for this product).", "Each layer of stretch wrap film sticks to the next layer for tight secure bundling that won’t damage the product or leave a layer of sticky film residue on product. These rolls are 80 guage (0.80 ml) thick which is the most common size."],
    sizing: "Available in 3” or 18” Widths"
  },
  {
    _id: "1967",
    title: "Reflective Day/Nite Caution Barrier Tape",
    genre: { _id: "122", name: "Tape" },
    availability: "In Stock",
    condition: "New",
    brand: "ProTec",
    display: "./display/cautiontape.png",
    images: ["../itemPage/cautiontape_1.jpg"],
    description: ["This is a hot new product on the safety market. It is similar to our standard caution barrier tape but has a special reflective silver ink for night time visibility on one side and black in for day time use on the other. With the litigious environment we live in everything your customers can do to protect them selves from accidents and lawsuits the better off they are."],
    sizing: "These rolls are 3” wide by 1000’ long and are 2 ml in thickness."
  },
  {
    _id: "1970",
    title: "Detectable Buried Tape",
    genre: { _id: "122", name: "Tape" },
    availability: "In Stock",
    condition: "New",
    brand: "Nashua",
    display: "./display/buriedtape.png",
    images: ["../itemPage/buriedtape_1.jpg"],
    description: ["Made In U.S.A. This tape is designed to avoid the problem of costly dig-ins to locate buried, non-metallic cables and pipelines. This tape provides the contractor with double protection, as it is easily located with an industrial grade non-ferrous metal detector before he digs, and its highly visible colors make it easy to locate after the dig has begun. Each roll is 2” in width and 1000’ in length.", "This tape conforms to the following specifications:"],
    specs:["NTSB-PSS-73-1", "American Gas Association 72-D-56", "OSHA 1926.956 (c) (1)", "DOT Office of Pipeline safety USAS B31.8", "GSA Public Buildings Service Guide", "API RP 1109", "APWA Uniform Color Code", "Federal Gas Safety Regulations S 192-321 (e)"],
    sizing: "Available for Electrical, Gas, Sewer, or Water"
  },
  {
    _id: "1934",
    title: "Flagging Tape",
    genre: { _id: "122", name: "Tape" },
    availability: "In Stock",
    condition: "New",
    brand: "Nashua",
    display: "./display/flagtape.png",
    images: ["../itemPage/flagtape_1.jpg"],
    description: ["Made in USA. This is a non-adhesive surveyors tape. Your customers will survey and mark boundaries, trees and hazardous areas with this high visibility tape. The rolls 1-3/16” wide and come in boxes of 12. The fluorescent colors are 150’ in length, and the standard colors are 300’ in length. Surveyors, excavators, concrete construction, and general construction companies will all use flagging tape. It is also used to temporarily mark off areas as an alternative to barrier tape."],
    sizing: "Available in Orange, Blue, White, Pink, or White"
  },
  {
    _id: "1957",
    title: "DOT Conspicuity Tape",
    genre: { _id: "122", name: "Tape" },
    availability: "In Stock",
    condition: "New",
    brand: "ProTec",
    display: "./display/reflectivetape.png",
    images: ["../itemPage/reflectivetape_1.jpg"],
    description: ["This is the tape that all your trucking customers have been asking for. In 1993 the D.O.T. made it mandatory on all trailers (including garbage haulers, auto transporters, container chassis, side loading beverage trailers, and boat trailers with 10,000 lb GVWR and 80” or more wide). This vehicle conspicuity tape exceeds the NHTSA requirements (49CFR 571) and every strip is stamped with the dot-c legal certification for compliance. Its modular pattern of reflective prisms allows it to be mounted in any position without diluting its effectiveness. D.O.T. regulations require that tape be 4’ above road surface and can be discontinuous (broken), but must cover 50% of side and be evenly distributed. Also any red section must be 3” from any required amber lamp."],
    sizing: "2” by 150' Rolls"
  },
  {
    _id: "1923",
    title: "Non Slip Tape",
    genre: { _id: "122", name: "Tape" },
    availability: "In Stock",
    condition: "New",
    brand: "ProTec",
    display: "./display/nonslip.png",
    images: ["../itemPage/nonslip_1.jpg"],
    description: ["Made in USA. This product is a long-lasting, adhesive backed tape designed especially for application in high traffic areas where slippery or dangerous surfaces may cause accidents. A rugged aluminum oxide grit surface is bonded to the tape and is impervious to water, grease, oil, detergents and solvents.", "It can be used on stairs and ladders, ramps and dock lifts, factories and repair facilities, truck beds and steps, restaurant kitchens, machine shops and shipping areas, service stations, etc."],
    sizing: "2” by 60' Rolls"
  },
  
];

export default items;

export function getItems() {
  return items;
}

export function getItem(id) {
  return items.find(m => m._id === id);
}

export function saveItem(item) {
  let itemInDb = items.find(m => m._id === item._id) || {};
  itemInDb.title = item.title;
  itemInDb.genre = genresAPI.genres.find(g => g._id === item.genreId);
  itemInDb.brand = item.brand;

  if (!itemInDb._id) {
    itemInDb._id = Date.now().toString();
    items.push(itemInDb);
  }

  return itemInDb;
}

export function deleteItem(id) {
  let itemInDb = items.find(m => m._id === id);
  items.splice(items.indexOf(itemInDb), 1);
  return itemInDb;
}
