import React from 'react';
import { getItem} from '../services/itemService';
import { Carousel } from 'rsuite';
import Banner from './common/banner';
import SiteFeatures from './common/siteFeatures'
import SideNav from './common/sideNav';
import CartButton from './common/cartButton';
import './style/itemPage.css';
import { uniqueId } from 'lodash';

class ItemPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            item: {}
        }
    }

    getKey = () => {
        return uniqueId();
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        const itemIn = getItem(this.props.match.params.id);
        this.setState({item: itemIn });   
    };
    
    render() { 
        const imagesIn = this.state.item.images;
        const itemDescription = this.state.item.description;
        const itemSpecs = this.state.item.specs;
        return (
            <React.Fragment>
            <div className="topContain">
                
                <SideNav />
                <div className="itemRight row" >
                    <div className="col itemSlider">
                        <Carousel autoplay navbuttonsalwaysinvisible="true" className="custom-slider">
                            {imagesIn &&
                                imagesIn.map((p) => (
                                    <img src={p} alt="notHere" key={p}/>
                                ))
                            }        
                        </Carousel>
                    </div>

                    <div className="col itemRundown">
                        <h2>{this.state.item.title}</h2>
                        <div className="greyArea">
                        <p><b>Stock Number:</b> {this.state.item._id}</p>
                        <p><b>Availability:</b> {this.state.item.availability}</p>
                        <p><b>Condition:</b> {this.state.item.condition}</p>
                        <p><b>Brand:</b> {this.state.item.brand}</p>
                        <CartButton item={this.state.item}/>
                        </div>
                    </div>

                    <div className="itemDesc">
                        <div className="descBanner">
                            <Banner title="Description"/>
                        </div>
                        {itemDescription && itemDescription.map((d) =>(
                            <p key={this.getKey()}>{d}</p>
                        ))}
                        <ul>
                        {itemSpecs && itemSpecs.map((s) =>(
                            <li key={this.getKey()}>{s}</li>
                        ))}
                        </ul>
                        <p><b>Sizing:</b> {this.state.item.sizing}</p>
                    </div>

                </div>
               
            </div>
             <SiteFeatures />
             </React.Fragment>
        )
    };
}
 
export default ItemPage;