import React from 'react';

const Banner = (props) => {
  
    return ( 
        <div className="bannerBox">
            <h2>{props.title}</h2>   
        </div>
     );
}
 
export default Banner;