import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import './App.css';
import Media from 'react-media';
import TopNav from './components/topNav';
import Catalog from './components/catalog.jsx';
import Home from './components/home.jsx';
import Cart from './components/cart.jsx';
import Accounts from './components/accounts.jsx';
import NotFound from './components/notFound';
import Contact from './components/contact';
import ItemPage from './components/itemPage';
import Footer from './components/footer';
import TopNavSmall from './components/topNavSmall';
import { AuthProvider } from "./contexts/AuthContext";




class App extends React.Component {
  render() { 
    return (   
      <AuthProvider>
      <Media queries={{
        small: "(max-width: 700px)",
      }}> 
      {matches => (
        <div className={matches.small ? "smallBox" : "" }>
          
          <div className="navBoxBig">
            <TopNav />
          </div>
          
          <div className="navBoxSmall">
            <TopNavSmall />
          </div>
          
          <main >
            <Switch>
              <Route path="/catalog/:id" component={ItemPage}></Route>
              <Route path="/catalog" component={Catalog}></Route>
              <Route path="/home" component={Home}></Route>
              <Route path="/accounts" component={Accounts}></Route>
              <Route path="/cart" component={Cart}></Route>
              <Route path="/contact" component={Contact}></Route>
              <Route path="/not-found" component={NotFound}></Route>
              <Redirect from="/" exact to="/home" />
              <Redirect to="/notFound" />
            </Switch>
          </main>
          <Footer />
        </div>
      )}
      </Media>
      </AuthProvider> 
  );
  }
}
 
export default App;
