import React from 'react';
import SideNav from './common/sideNav';
import Carouseled from './common/carousel';
import Featured from './common/featured';
import OnSale from './common/onSale';
import NewArrival from './common/newArrival';
import SiteFeatures from './common/siteFeatures';
import { Divider } from 'rsuite';

class Home extends React.Component {
    render() { 
        return (
            <React.Fragment>
                <div className="topContain">
                    <SideNav />
                    <div className="rightPanel">
                        <Carouseled />
                        <Divider className="dividerBar"/>
                        <Featured />
                        
                    </div>
                </div>

                <div className="topContain">
                   <div className="rightPanel widen">
                    <OnSale />
                    <NewArrival />
                    <SiteFeatures />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
 
export default Home;