import React, { useState, useEffect, useRef } from 'react';
import logoURL from './images/logo.jpeg';
import './style/topNav.css';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

function TopNav() {
    const prevScrollY = useRef(0);
    const [goingUp, setGoingUp] = useState(true);

    const { currentUser, logout } = useAuth()
    const history = useHistory()

    useEffect(() => {
        const handleScroll = () => {
          const currentScrollY = window.scrollY;
          if (prevScrollY.current >= 90) {
            setGoingUp(false);
          }
          if (prevScrollY.current <90) {
            setGoingUp(true);
          }
    
          prevScrollY.current = currentScrollY;
        };
    
        window.addEventListener("scroll", handleScroll, { passive: true });
    
        return () => window.removeEventListener("scroll", handleScroll);
    }, [goingUp]);

    async function handleLogout() {    
        try {
          await logout()
          history.push("/accounts")
        } catch {
        console.log("Failed to logout")
        }
      }
    
    return ( 
        <div className={goingUp ? 'navbar navbar-light fixed-top static-top topNav' : 'navbar fixed-top static-top navbar-light topNav active'}>
            <div className="container-fluid">
                
                <Link to="/home" className="d-inline-block align-text-top">
                    <img src={logoURL} 
                         width="60" height="40" to="/"  alt="not found">
                    </img>
                </Link>

                <div className="nav topNavLink">
                    {currentUser ? <NavLink className="nav-item nav-link" to="/cart">
                        Cart
                    </NavLink> : null}

                    <NavLink className="nav-item nav-link" to="/contact">
                        Contact
                    </NavLink>

                    {currentUser ? null : <NavLink className="nav-item nav-link" to="/accounts">
                        Account
                    </NavLink>}

                    <NavLink className="nav-item nav-link" to={{
                                                    pathname: `/catalog`,
                                                     state: {
                                                       genreIn: "0"
                                                    }
                    }}>
                       Catalog
                    </NavLink>

                    {currentUser ? <div className="nav-item nav-link" style={{cursor: "pointer"}} onClick={handleLogout}>
                        Logout
                    </div> : null}

                </div>

            </div>
        </div>
     );
}

export default TopNav;
