import React, { useContext, useState, useRef} from 'react';
import Joi from 'joi-browser';
import { AccountContext } from '../../utils/accountContext';
import { useAuth } from '../../contexts/AuthContext';
import { useHistory } from 'react-router';
import ForgotPassword from '../forgotPassword';

const initialFormData = Object.freeze({
    account: {email: "",
            password: ""},
    errors: {}
  });

const LoginForm = (props) => {
    
    const [formData, updateFormData] = useState(initialFormData);

    const emailRef = useRef();
    const passwordRef = useRef();
    const { login } = useAuth();
    const [errorAuth, setErrorAuth] = useState("");
    const history = useHistory();
  

    const schema = {
        email : Joi.string().email().required().label('Email'),
        password: Joi.string().required().label('Password')
    };

    const handleChange = (e) => {
        const errors = formData.errors;
        const errorMessage = validateProperty(e.target);
        if(errorMessage) errors[e.target.name] = errorMessage;
        else delete errors[e.target.name];
        updateFormData(formData => ({
            account: {
                ...formData.account,
                [e.target.name]: e.target.value.trim()
            },errors
        }))
    };

    const validate = () =>{
        const result = Joi.validate(formData.account, schema, {abortEarly: false});
        if(!result.error) return null;

        const errors = {};
        for(let item of result.error.details){
            errors[item.path[0]] = item.message;
        }

        return errors;
    };

    const validateProperty = ({name, value}) => {
        const obj = { [name]: value};
        const schemaIn = { [name]: schema[name]};
        const {error} = Joi.validate(obj, schemaIn);
        return error ? error.details[0].message : null;
    }

    async function handleSubmit(e) {
       e.preventDefault();
       e.stopPropagation();

        const errors = validate();
        updateFormData({...formData, errors : errors || {} });
        if (errors) return;
 
        try {
            setErrorAuth("")
            await login(emailRef.current.value, passwordRef.current.value)
            history.push("/")
        } catch {
            setErrorAuth("Incorrect Email or Password")
        }
    };


    const {switchToSignup} = useContext(AccountContext);




    return ( 
        <>
            <form onSubmit={handleSubmit} className="boxContainer">
                <div className="formContainer">
                    {errorAuth && <div style={{ textAlign: "center"}} className="container alert alert-danger">{errorAuth}</div> }
                    <input onChange={handleChange} ref={emailRef} name="email" type="email" placeholder="Email" />
                    {formData.errors.email && <div className="alert alert-danger">{formData.errors.email}</div>}
                    <input onChange={handleChange} ref={passwordRef} name="password" type="text" placeholder="Password" />
                    {formData.errors.password && <div className="alert alert-danger">{formData.errors.password}</div>}
                </div>

                <button disabled={validate()} type="submit"className="btn btn-primary shadow-none submitButton" > Sign In</button>
                
            </form>
            <div className="row boxContainer" style={{width: "103%", marginTop: "10px", textAlign: "center"}}>
                <span className="muteLink" onClick={switchToSignup}>No Account? <span className="boldLink">Sign Up</span></span>
                <ForgotPassword style={{marginLeft: "20px"}} />
            </div>
        </>
    );
}
 
export default LoginForm;

