import React from 'react';
import './style/catalog.css';
import { Link } from 'react-router-dom';


const ItemsTable2 = (props) => {
    const {itemsOut} = props;
    return ( 
        <div className="row catMain">
            {itemsOut.map( item => ( 
                <div key={item._id}className="col-xs-12 col-sm-4 catCard">
                    <Link to={`/catalog/${item._id}`} className="viewLine"><img src={item.display} alt=""/></Link>
                    <p className="cardTitle">{item.title}</p>
                    <p className="cardStock">{item.availability}</p>
                    <p className="cardStock">Stock # {item._id}</p>
                    <Link to={`/catalog/${item._id}`} className="viewLine"><button className="button-71">View</button></Link>
                </div>
            ))}

        </div>
     );
}
 
export default ItemsTable2;