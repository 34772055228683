import React from 'react';
import './style/footer.css';
import { Icon } from 'rsuite';
import { Link } from 'react-router-dom';

const Footer = () => {
    return ( 
        <footer>
            <div className="footer">
                <div className="row">
                    <div className="footer-left col">
                        <p className="about">
                            <span> About Us</span> 
                            Left Coast Industrial Supply is a Wholesale Supply Warehouse Operating in 
                            Riverside, California. We Specialize in Hand Tools and Shop Supplies for the
                            True Professional Working in Any Industrial Field. 
                        </p>

                        <div className="icons">
                            <Icon className="iconSpace" size="lg" icon="facebook-square" />
                            <Icon className="iconSpace" icon="twitter" />
                            <Icon className="iconSpace" icon="linkedin-square" />
                            <Icon className="iconSpace" icon="google-plus-square" />
                        </div>
                    </div>

                    <div className="footer-center container col">
                        <div>
                            <i className="fa fa-map-marker"></i>
                            <p><span> 1865 Iowa Avenue Ste B.</span> Riverside, California</p>
                        </div>
                        <div>
                            <i className="fa fa-phone"></i>
                            <p> 1 + (951) 781-3739</p>
                        </div>
                        <div>
                            <i className="fa fa-envelope"></i>
                            <p> <Link to="/contact" style={{color: 'white'}}> Email Us Today!</Link>  </p>
                        </div>
                    </div>
                    
                    <div className="footer-right col">
                        <h2> Left<span> Coast</span> Industrial</h2>
                        <p className="menu">
                            <Link to="/home"> Home </Link> 
                            <Link to="/accounts"> Account </Link> 
                            <Link to="/contact"> Contact</Link> 
                        </p>
                        <p className="name"> CCS Media &copy; 2021</p>
                    </div>
                </div>
            </div>
        </footer>
    );
}
 
export default Footer;