import React, {useState, useRef} from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { Modal, Button} from 'rsuite';
import { Link } from 'react-router-dom';

const CartButton = (props) => {
    const { currentUser, addCart } = useAuth()
    const [show, setShow] = useState(false)
    const [status, setStatus] = useState(false)

    const quantRef = useRef();
    const notesRef = useRef();

    const close = () => {
        setShow(false);
    }

    const open = () => {
        setShow(true);
    }

    async function handleSubmit(e){
        e.preventDefault();
        e.stopPropagation();

        try {
            await addCart(props.item, quantRef.current.value, notesRef.current.value)
            setStatus(true)
        } catch {
            return 
        }
    }

    return (
        <>
            {currentUser ? <button onClick={open}  className="button-71" >Get Quote</button> : <Link style={{textDecoration: "none"}} to="/accounts"><button className="button-71" >Sign In To Get Quote</button></Link> }
            {status ?   <div className="alert alert-success addSuccess" >
                            Added To Cart!
                        </div> : null }

            <Modal show={show} size='xs' onHide={close}>
                    <Modal.Header/>

                    <form onSubmit={handleSubmit}  className="addForm">
                        <p><b>Stock Number: </b>{props.item._id}</p>
                        <p><b>Item: </b>{props.item.title}</p>
                        
                        <p><b>Quantity:</b></p>
                        <input ref={quantRef} type="text" />
                        
                        <p><b>Notes or Description:</b></p>
                        <textarea ref={notesRef} id="" cols="30" rows="5"></textarea>

    
                        
                        <Button onClick={close} className="w-100"  color="red" type="submit">
                            Add To Cart
                        </Button>

                    </form>
           
            </Modal>
        </>
    );
}
 
export default CartButton;


